import api from "../../helpers/api";

const publicationsServices = {
  fetchAll: (type: String) => {
    return api.get("/publications/get/" + type);
  },

  newPublication: (formData: FormData) => {
    return api.post("/publications/create", formData);
  },

  fetchById: (id: String) => {
    return api.get("/publications/getbyid/" + id);
  },

  updatePublication: (id: String, data: FormData) => {
    return api.put("/publications/update/" + id, data);
  },
  delete: (id: String) => {
    return api.delete("/publications/delete/" + id);
  },
  changeVisibility: (id: String, is_shown: boolean) => {
    return api.patch(`/publications/visibility/${id}`, { is_shown });
  },
  sortPublications: (sortData: Object[]) => {
    return api.put("/publications/sort", sortData);
  },
};

export default publicationsServices;
