import publicationsConstants from "./constants";
import publicationsServices from "./services";

const {
  SET_NEW_PUBLICATION,
  SET_ADD_PUBLICATION_ERROR,
  SET_LOADING_PUBLICATIONS,
  SET_PUBLICATIONS,
  SET_SELECTED_PUBLICATIONS,
  SET_EDIT_PUBLICATION_ERROR,
  SET_EDITED_PUBLICATION,
  SET_DELETING_PUBLICATION,
  SET_CHANGING_PUBLICATION_VISIBILITY,
  SET_CHANGE_PUBLICATION_VISIBILITY_ERROR,
  REMOVE_PUBLICATION,
  SET_SORTING_PUBLICATIONS,
  SET_SORT_PUBLICATIONS_ERROR,
} = publicationsConstants;

const setLoadingPublications = (isLoadingPublications: boolean) => ({
  type: SET_LOADING_PUBLICATIONS,
  isLoadingPublications,
});
const setPublications = (publications: any) => ({
  type: SET_PUBLICATIONS,
  publications,
});

export const fetchAllPublications = (type: String) => {
  return (dispatch: any) => {
    dispatch(setLoadingPublications(true));
    publicationsServices
      .fetchAll(type)
      .then((res) => {
        dispatch(setLoadingPublications(false));
        return dispatch(setPublications(res.data));
      })
      .catch(() => {
        dispatch(setPublications([]));
        dispatch(setLoadingPublications(false));
      });
  };
};

const setAddPublicationError = (addPublicationError: String | null) => ({
  type: SET_ADD_PUBLICATION_ERROR,
  addPublicationError,
});

const setNewPublication = (isNewPublication: boolean) => ({
  type: SET_NEW_PUBLICATION,
  isNewPublication,
});

export const newPublication = (
  formData: FormData,
  type: String,
  callback?: any
) => {
  return (dispatch: any) => {
    dispatch(setNewPublication(true));
    dispatch(setAddPublicationError(null));
    publicationsServices
      .newPublication(formData)
      .then((res: any) => {
        if (callback) callback();
        dispatch(setNewPublication(false));
        dispatch(fetchAllPublications(type));
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setAddPublicationError(err.response.data.errors.message));
        } else {
          dispatch(setAddPublicationError("Ha ocurrido un error"));
        }
        dispatch(setNewPublication(false));
      });
  };
};

const setSelectedPublication = (selectedPublication: any) => ({
  type: SET_SELECTED_PUBLICATIONS,
  selectedPublication,
});

export const fetchById = (id: String) => {
  return (dispatch: any) => {
    publicationsServices.fetchById(id).then((res) => {
      return dispatch(setSelectedPublication(res.data.response));
    });
  };
};

const setEditPublicationError = (editPublicationError: String | null) => ({
  type: SET_EDIT_PUBLICATION_ERROR,
  editPublicationError,
});

const setEditPublication = (isEditedPublication: boolean) => ({
  type: SET_EDITED_PUBLICATION,
  isEditedPublication,
});

export const editPublication = (
  id: String,
  formData: FormData,
  type: String,
  callback?: any
) => {
  return (dispatch: any) => {
    dispatch(setEditPublication(true));
    dispatch(setEditPublicationError(null));
    publicationsServices
      .updatePublication(id, formData)
      .then((res: any) => {
        if (callback) callback();
        dispatch(setEditPublication(false));
        dispatch(fetchAllPublications(type));
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setEditPublicationError(err.response.data.errors.message));
        } else {
          dispatch(setEditPublicationError("Ha ocurrido un error"));
        }
        dispatch(setEditPublication(false));
      });
  };
};

const setDeletingPublication = (isDeletingPublication: boolean) => ({
  type: SET_DELETING_PUBLICATION,
  isDeletingPublication,
});
const removePublication = (deletedPublicationId: String) => ({
  type: REMOVE_PUBLICATION,
  deletedPublicationId,
});

export const deletePublication = (id: String, callback: any) => {
  return (dispatch: any) => {
    dispatch(setDeletingPublication(true));
    publicationsServices.delete(id).then((res) => {
      dispatch(setDeletingPublication(false));
      if (callback) callback();
      return dispatch(removePublication(id));
    });
  };
};

const setChangingPublicationVisibility = (
  isChangingPublicationVisibility: boolean
) => ({
  type: SET_CHANGING_PUBLICATION_VISIBILITY,
  isChangingPublicationVisibility,
});

const setChangePublicationVisibilityError = (
  changePublicationVisibilityError: any
) => ({
  type: SET_CHANGE_PUBLICATION_VISIBILITY_ERROR,
  changePublicationVisibilityError,
});

export const changePublicationVisibility = (
  id: String,
  newVisibility: boolean
) => {
  return (dispatch: any) => {
    dispatch(setChangingPublicationVisibility(true));
    dispatch(setChangePublicationVisibilityError(null));
    publicationsServices
      .changeVisibility(id, newVisibility)
      .then((res) => {
        dispatch(setChangingPublicationVisibility(false));
      })
      .catch((err) => {
        dispatch(
          setChangePublicationVisibilityError(err.response.data.errors.message)
        );
        dispatch(setChangingPublicationVisibility(false));
      });
  };
};

const setSortingPublications = (isSortingPublications: boolean) => ({
  type: SET_SORTING_PUBLICATIONS,
  isSortingPublications,
});

const setSortPublicationsError = (sortPublicationsError: String | null) => ({
  type: SET_SORT_PUBLICATIONS_ERROR,
  sortPublicationsError,
});

export const sortPublications = (data: Object[]) => {
  return (dispatch: any) => {
    dispatch(setSortingPublications(true));
    dispatch(setSortPublicationsError(null));
    publicationsServices
      .sortPublications(data)
      .then((res) => {
        dispatch(setSortingPublications(false));
      })
      .catch((err) => {
        dispatch(setSortPublicationsError(err.response.data.errors.message));
        dispatch(setSortingPublications(false));
      });
  };
};
