import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Dropdown,
  Button,
  Input,
  Menu,
  Table,
  Modal,
  Typography,
  Form,
  Switch,
  Popconfirm,
  message,
  Select,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  addSubscriptionCode,
  deleteSubscriptionCode,
  editSubscriptionCode,
  fetchAllSubscriptionCodes,
} from "redux/subscriptions/actions";
import moment from "moment";

import styles from "./styles.module.scss";
import { fetchAllClients } from "redux/clients";
import form from "antd/lib/form";

const { Search } = Input;
const { Text } = Typography;

const CodeTexts = () => {
  const dispatch = useDispatch();

  const {
    loadingSubscriptionCodes: loading,
    subscriptionCodes: data,
    addingSubscriptionCode,
    addSubscriptionCodeError,
    editingSubscriptionCode,
    editSubscriptionCodeError,
  } = useSelector((state: any) => state.subscriptions);

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  useEffect(() => {
    dispatch(fetchAllClients(dispatch));
  }, [dispatch]);

  const [pageSize, setPageSize] = useState(10);
  const [displayData, setDisplayData] = useState([]);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalAddVisible, setIsModalAddVisible] = useState(false);
  const [code, setCode] = useState({
    id: "",
    code: "",
    company: "",
    type: "",
    duration: 0,
    validCodeText: "",
    youHaveText: "",
    tariffRecommender: true,
    isActive: false,
    dangerousFieldEdit: [] as any[],
    validUntil: moment().add(1, "year").format("YYYY-MM-DD"),
    premiumUsers: 0,
    traffic_light_enabled: false,
    urlBanner: "",
    textBanner: "",
    buttonTextBanner: "",
  });
  const [isConfirmEditVisible, setIsConfirmEditVisible] = useState(false);
  const [usedCodes, setUsedCodes] = useState(0);

  const dataSorter = (a: { updated_at: string }, b: { updated_at: string }) =>
    moment(a.updated_at).isAfter(b.updated_at) ? -1 : 1;

  const showModalEdit = (id: string) => {
    const editingCode = data.find((code: { id: string }) => code.id === id);
    setCode({
      id: editingCode.id,
      code: editingCode.code,
      company: editingCode.company,
      type: editingCode.type,
      duration: editingCode.duration,
      validCodeText: editingCode.valid_code_text,
      youHaveText: editingCode.you_have_text,
      tariffRecommender: editingCode.tariff_recommender,
      isActive: editingCode.isActive,
      validUntil: editingCode.valid_until || moment().add(1, "year"),
      premiumUsers: editingCode.premium_users,
      dangerousFieldEdit: [],
      traffic_light_enabled: editingCode.traffic_light_enabled,
      urlBanner: editingCode.url_banner,
      textBanner: editingCode.text_banner,
      buttonTextBanner: editingCode.button_text_banner,
    });
    setUsedCodes(editingCode.currentUsers);
    setIsModalEditVisible(true);
  };

  const deleteCode = (id: string) => {
    dispatch(
      deleteSubscriptionCode(id, () => {
        message.success("Código borrado con éxito");
        dispatch(fetchAllSubscriptionCodes());
      })
    );
  };

  const columns = [
    {
      title: "Código de suscripción",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Empresa",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (val: string) => (val === "License" ? "Licencia" : val),
    },
    {
      title: "Duración",
      dataIndex: "duration",
      key: "duration",
      render: (text: any) => text && `${text} días`,
    },
    {
      title: "Código válido",
      dataIndex: "valid_code_text",
      key: "valid_code_text",
    },
    {
      title: "Dispones de",
      dataIndex: "you_have_text",
      key: "you_have_text",
    },
    {
      title: "Banner",
      dataIndex: "tariff_recommender",
      key: "tariff_recommender",
      render: (val: boolean) => (val ? "Sí" : "No"),
    },
    {
      title: "Válido hasta",
      dataIndex: "valid_until",
      key: "valid_until",
      render: (val: string) => val && moment(val).format("YYYY-MM-DD"),
    },
    {
      title: "Nº códigos disponibles",
      dataIndex: "premium_users",
      key: "premium_users",
      render: (val: string, record: any) => (
        <Text
          type={
            parseInt(val) === parseInt(record.currentUsers)
              ? "danger"
              : undefined
          }
        >
          {val !== null
            ? `${parseInt(val) - record.currentUsers}/${val}`
            : null}
        </Text>
      ),
    },
    {
      title: "Editar",
      dataIndex: "id",
      key: "editar",
      render: (text: any, record: any) => (
        <>
          <EditOutlined
            style={{ fontSize: "20px", color: "#199479" }}
            onClick={() => showModalEdit(text)}
          />
        </>
      ),
    },
    {
      title: "Eliminar",
      dataIndex: "id",
      key: "eliminar",

      render: (text: any, record: any) => {
        return (
          <Popconfirm
            title={
              record.isActive
                ? "Este código está activo, al eliminarlo se eliminarán también todas las suscripciones asociadas a él. ¿Continuar?"
                : "Confirmar"
            }
            okText="Si"
            cancelText="No"
            onConfirm={() => deleteCode(text)}
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "#47464E" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const handleChange = (e: any, e2?: any, name?: any) => {
    let newCode = { ...code };

    if (typeof e === "boolean" && e2 && name) {
      if (name === "traffic_light_enabled") {
        newCode = { ...newCode, traffic_light_enabled: e };
      } else if (name === "tariffRecommender") {
        newCode = { ...newCode, tariffRecommender: e };
      }
    } else if (moment.isMoment(e)) {
      newCode = { ...newCode, validUntil: moment(e).format("YYYY-MM-DD") };
    } else if (
      e.target.name === "premiumUsers" &&
      parseInt(e.target.value) < usedCodes
    ) {
      newCode = { ...newCode, premiumUsers: usedCodes };
    } else {
      newCode = { ...newCode, [e.target.name]: e.target.value };

      const dangerousFields = ["code", "duration", "company", "type"];
      if (dangerousFields.some((val) => val === e.target.name)) {
        newCode = {
          ...newCode,
          dangerousFieldEdit: [...code.dangerousFieldEdit, e.target.name],
        };
      }
    }

    setCode(newCode);
  };

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      data.forEach((c: any) => {
        const { code, valid_code_text, you_have_text } = c;
        const superString = `${code},${valid_code_text},${you_have_text}`;
        if (superString.toLowerCase().includes(value.toLowerCase())) {
          results.push(c);
        }
      });
      return setDisplayData(results.sort(dataSorter));
    }
    if (value.trim() === "") {
      return setDisplayData(data.sort(dataSorter));
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "index",
      breadcrumbName: "Códigos de suscripción",
    },
  ];

  const handleEditCancel = () => {
    setIsModalEditVisible(false);
    setCode({
      ...code,
      code: "",
      company: "",
      type: "",
      duration: 0,
      validCodeText: "",
      youHaveText: "",
      tariffRecommender: true,
      validUntil: moment().add(1, "year").format("YYYY-MM-DD"),
      premiumUsers: 0,
      traffic_light_enabled: false,
      urlBanner: "",
      textBanner: "",
      buttonTextBanner: "",
    });
    setUsedCodes(0);
  };

  const handleEditOk = () => {
    dispatch(
      editSubscriptionCode(code, () => {
        dispatch(fetchAllSubscriptionCodes());
        message.success("Código actualizado con éxito");
        setIsModalEditVisible(false);
      })
    );
  };

  const handleAddCancel = () => {
    setIsModalAddVisible(false);
    setCode({
      ...code,
      code: "",
      company: "",
      type: "",
      duration: 0,
      validCodeText: "",
      youHaveText: "",
      tariffRecommender: true,
      traffic_light_enabled: false,
      urlBanner: "",
      textBanner: "",
      buttonTextBanner: "",
    });
  };

  const handleAddOk = () => {
    dispatch(
      addSubscriptionCode(code, () => {
        message.success("Código creado con éxito");
        dispatch(fetchAllSubscriptionCodes());
        setIsModalAddVisible(false);
        setCode({
          ...code,
          code: "",
          company: "",
          type: "",
          duration: 0,
          validCodeText: "",
          youHaveText: "",
          tariffRecommender: true,
          traffic_light_enabled: false,
          urlBanner: "",
          textBanner: "",
          buttonTextBanner: "",
        });
      })
    );
  };

  useEffect(() => {
    dispatch(fetchAllSubscriptionCodes());
  }, [dispatch]);

  useEffect(() => {
    setDisplayData(data.sort(dataSorter));
  }, [data]);
  
  return (
    <div>
      <PageHeader
        title="Códigos de suscripción"
        ghost={false}
        breadcrumb={{ routes }}
      />

      <div className="top_content_container">
        <Dropdown overlay={menu}>
          <Button>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Button
          style={{
            color: "#199479",
            backgroundColor: "transparent",
            textAlign: "center",
            border: "1px solid #199479",
            borderRadius: "4px",
          }}
          onClick={() => setIsModalAddVisible(true)}
        >
          <PlusOutlined />
          Añadir
        </Button>
      </div>

      <div className={styles.content_container} style={{padding: '2em'}}>
        <Table
          columns={columns}
          dataSource={displayData}
          loading={loading}
          pagination={{ pageSize }}
          scroll={{ x: true }}
        />

        <Modal
          title="Añadir código"
          visible={isModalAddVisible}
          onCancel={handleAddCancel}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleAddCancel}>
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={addingSubscriptionCode}
              onClick={handleAddOk}
            >
              Añadir
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true }]}
              validateStatus={addSubscriptionCodeError && "error"}
            >
              <Input
                name="code"
                value={code.code}
                onChange={handleChange}
                maxLength={8}
              />
            </Form.Item>

            <Form.Item
              label="Tipo"
              name="type"
              rules={[{ required: true }]}
              validateStatus={addSubscriptionCodeError && "error"}
            >
              <Select
                onChange={(value) =>
                  handleChange({ target: { name: "type", value } })
                }
                placeholder="Selecciona una opción"
              >
                <Select.Option value="Free">Free</Select.Option>
                <Select.Option value="Premium">Premium</Select.Option>
                <Select.Option value="License">Licencia</Select.Option>
              </Select>
            </Form.Item>

            {(code.type === "Free" || code.type === "Premium") && (
              <Form.Item
                label="Duración"
                name="duration"
                rules={[{ required: true }]}
                validateStatus={addSubscriptionCodeError && "error"}
              >
                <Input
                  type="number"
                  name="duration"
                  value={code.duration}
                  onChange={handleChange}
                />
              </Form.Item>
            )}

            {code.type === "License" && (
              <Form.Item
                label="Válido hasta"
                name="validUntil"
                rules={[{ required: true }]}
              >
                <DatePicker
                  defaultValue={moment().add(1, "year")}
                  onChange={handleChange}
                  value={moment(code.validUntil)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}

            <Form.Item
              label="Empresa"
              name="company"
              validateStatus={addSubscriptionCodeError && "error"}
              initialValue="0"
            >
              <Select
                value={code.company}
                onChange={(value) =>
                  handleChange({ target: { name: "company", value } })
                }
              >
                <Select.Option value="0">Selecciona una opción</Select.Option>
                {dataAllClients.map((company_map: any) => (
                  <Select.Option key={company_map.id} value={company_map.id}>
                    {company_map.company_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Código válido"
              name="validCodeText"
              validateStatus={addSubscriptionCodeError && "error"}
            >
              <Input
                name="validCodeText"
                value={code.validCodeText}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Dispones de"
              name="youHaveText"
              validateStatus={addSubscriptionCodeError && "error"}
            >
              <Input
                name="youHaveText"
                value={code.youHaveText}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Semáforo energético"
              name="traffic_light_enabled"
              initialValue={code.traffic_light_enabled}
            >
              <Switch
                checked={code.traffic_light_enabled}
                onChange={(e, e2) =>
                  handleChange(e, e2, "traffic_light_enabled")
                }
              />
            </Form.Item>

            <Form.Item
              label="Banner"
              name="tariffRecommender"
              validateStatus={addSubscriptionCodeError && "error"}
            >
              <Switch
                checked={code.tariffRecommender}
                onChange={(e, e2) => handleChange(e, e2, "tariffRecommender")}
              />
            </Form.Item>

            {code.tariffRecommender && (
              <div>
                <Form.Item
                  label="Enlace (URL)"
                  name="urlBanner"
                  initialValue={code.urlBanner}
                  rules={[
                    {
                      required: true,
                      message: "El enlace es requerido",
                    },
                  ]}
                  validateStatus={addSubscriptionCodeError && "error"}
                >
                  <Input
                    name="urlBanner"
                    value={code.urlBanner}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Texto del banner"
                  name="textBanner"
                  initialValue={code.textBanner}
                  rules={[
                    {
                      required: true,
                      message: "El texto del banner es requerido",
                    },
                  ]}
                  validateStatus={addSubscriptionCodeError && "error"}
                >
                  <Input
                    name="textBanner"
                    value={code.textBanner}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Bóton del banner"
                  name="buttonTextBanner"
                  initialValue={code.buttonTextBanner}
                  rules={[
                    {
                      required: true,
                      message: "El texto del botón del banner es requerido",
                    },
                  ]}
                  validateStatus={addSubscriptionCodeError && "error"}
                >
                  <Input
                    name="buttonTextBanner"
                    value={code.buttonTextBanner}
                    onChange={handleChange}
                  />
                </Form.Item>
              </div>
            )}

            {code.type === "License" && (
              <Form.Item label="Nº códigos disponibles" name="premiumUsers">
                <Input
                  type="number"
                  onChange={handleChange}
                  name="premiumUsers"
                  value={code.premiumUsers}
                />
              </Form.Item>
            )}
          </Form>
          {addSubscriptionCodeError && (
            <Text type="danger">Ha ocurrido un error.</Text>
          )}
        </Modal>

        <Modal
          title="Editar código"
          visible={isModalEditVisible}
          onCancel={handleEditCancel}
          onOk={handleEditOk}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleEditCancel}>
              Cancelar
            </Button>,
            <Popconfirm
              placement="topLeft"
              title={(() => {
                const warnings: any = {
                  code: "Cambiará el nombre del código en todas las suscripciones activas con este código. ",
                  duration:
                    "Las suscripciones ya activas con este código mantendrán su duración; si lo desea, debe modificarla manualmente. ",
                  company:
                    "Cambiará el nombre de la empresa en todas las suscripciones que utilizan este código. ",
                  type: "Cambiará el tipo de todas las suscripciones que utilizan este código. ",
                };

                return code.dangerousFieldEdit
                  .reduce(
                    (prev, field: string) =>
                      prev.includes(warnings[field])
                        ? prev
                        : prev + warnings[field],
                    ""
                  )
                  .concat("¿Continuar?");
              })()}
              overlayStyle={{ maxWidth: "25vw" }}
              okText="Si"
              okButtonProps={{ loading: editingSubscriptionCode }}
              cancelText="No"
              onConfirm={handleEditOk}
              key="submit"
              visible={isConfirmEditVisible}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setIsConfirmEditVisible(visible);
                  return;
                }

                if (code.isActive && code.dangerousFieldEdit.length > 0) {
                  setIsConfirmEditVisible(visible);
                } else {
                  handleEditOk();
                }
              }}
            >
              <Button type="primary">Guardar</Button>
            </Popconfirm>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item
              label="Código"
              name="code"
              initialValue={code.code}
              rules={[
                {
                  required: true,
                  message: "Codigo es requerido",
                },
              ]}
            >
              <Input
                name="code"
                value={code.code}
                onChange={handleChange}
                maxLength={8}
              />
            </Form.Item>

            <Form.Item
              label="Tipo"
              name="type"
              initialValue={code.type}
              rules={[
                {
                  required: true,
                  message: "Tipo es requerido",
                },
              ]}
            >
              <Select
                onChange={(value) =>
                  handleChange({ target: { name: "type", value } })
                }
                placeholder="Selecciona una opción"
                value={code.type}
              >
                <Select.Option value="Free">Free</Select.Option>
                <Select.Option value="Premium">Premium</Select.Option>
                <Select.Option value="License">Licencia</Select.Option>
              </Select>
            </Form.Item>

            {code.type !== "License" ? (
              <Form.Item
                label="Duración"
                name="duration"
                initialValue={code.duration}
                rules={[
                  {
                    required: true,
                    message: "Duración es requerido",
                  },
                ]}
              >
                <Input
                  type="number"
                  name="duration"
                  value={code.duration}
                  onChange={handleChange}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Válido hasta"
                name="validUntil"
                initialValue={
                  code.validUntil
                    ? moment(code.validUntil)
                    : moment().add(1, "year")
                }
                rules={[{ required: true }]}
              >
                <DatePicker
                  defaultValue={moment().add(1, "year")}
                  onChange={handleChange}
                  value={
                    code.validUntil
                      ? moment(code.validUntil)
                      : moment().add(1, "year")
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}

            <Form.Item
              label="Empresa"
              name="company"
              initialValue={code.company || "0"}
              validateStatus={editSubscriptionCodeError && "error"}
            >
              <Select
                value={code.company}
                onChange={(value) =>
                  handleChange({ target: { name: "company", value } })
                }
              >
                <Select.Option value="0">Selecciona una opción</Select.Option>
                {dataAllClients.map((company_map: any) => (
                  <Select.Option key={company_map.id} value={company_map.id}>
                    {company_map.company_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Código válido"
              name="validCodeText"
              initialValue={code.validCodeText}
            >
              <Input
                name="validCodeText"
                value={code.validCodeText}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Dispones de"
              name="youHaveText"
              initialValue={code.youHaveText}
            >
              <Input
                name="youHaveText"
                value={code.youHaveText}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Semáforo energético"
              name="traffic_light_enabled"
              initialValue={code.traffic_light_enabled}
            >
              <Switch
                checked={code.traffic_light_enabled}
                onChange={(e, e2) =>
                  handleChange(e, e2, "traffic_light_enabled")
                }
              />
            </Form.Item>

            <Form.Item
              label="Banner"
              name="tariffRecommender"
              validateStatus={addSubscriptionCodeError && "error"}
            >
              <Switch
                checked={code.tariffRecommender}
                onChange={(e, e2) => handleChange(e, e2, "tariffRecommender")}
              />
            </Form.Item>

            {code.tariffRecommender && (
              <div>
                <Form.Item
                  label="Enlace (URL)"
                  name="urlBanner"
                  initialValue={code.urlBanner}
                  rules={[
                    {
                      required: code.tariffRecommender ? true : false,
                      message: "El enlace es requerido",
                    },
                  ]}
                >
                  <Input
                    name="urlBanner"
                    value={code.urlBanner}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Texto del banner"
                  name="textBanner"
                  initialValue={code.textBanner}
                  rules={[
                    {
                      required: code.tariffRecommender ? true : false,
                      message: "El texto del banner es requerido",
                    },
                  ]}
                >
                  <Input
                    name="textBanner"
                    value={code.textBanner}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Bóton del banner"
                  name="buttonTextBanner"
                  initialValue={code.buttonTextBanner}
                  rules={[
                    {
                      required: code.tariffRecommender ? true : false,
                      message: "El texto del botón del banner es requerido",
                    },
                  ]}
                >
                  <Input
                    name="buttonTextBanner"
                    value={code.buttonTextBanner}
                    onChange={handleChange}
                  />
                </Form.Item>
              </div>
            )}

            {code.type === "License" && (
              <Form.Item
                label="Nº códigos disponibles"
                name="premiumUsers"
                initialValue={code.premiumUsers}
                rules={[{ required: true }]}
              >
                <Input
                  type="number"
                  onChange={handleChange}
                  name="premiumUsers"
                  value={code.premiumUsers}
                  min={usedCodes}
                />
              </Form.Item>
            )}
          </Form>
          {editSubscriptionCodeError && (
            <Text type="danger">Ha ocurrido un error.</Text>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default CodeTexts;
