import api from "../../helpers/api";

const clientService = {
  fetchAll: async () => {
    return await api.get("/client/findAll");
  },
  createClient: async (formData: FormData) => {
    return await api.post("/client/create", formData);
  },
  updateClient: async (id: string, formData: FormData) => {
    return await api.put("/client/update/" + id, formData);
  },
  deleteClient: async (id: string) => {
    return await api.delete("/client/delete/" + id);
  },
};

export default clientService;
