import challengeConstants from "./constants";
import challengeServices from "./services";

const {
  SET_NEW_CHALLENGE,
  SET_ADD_CHALLENGE_ERROR,
  SET_LOADING_CHALLENGES,
  SET_CHALLENGES,
  SET_SELECTED_CHALLENGES,
  SET_EDIT_CHALLENGE_ERROR,
  SET_EDITED_CHALLENGE,
  SET_DELETING_CHALLENGE,
  SET_SORTING_CHALLENGES,
  SET_SORT_CHALLENGES_ERROR,
  REMOVE_CHALLENGE,
  SET_LOADING_SPECIAL_CHALLENGES,
  SET_SPECIAL_CHALLENGES,
  SET_SPECIAL_CHALLENGES_ERROR,
} = challengeConstants;

const setLoadingChallenges = (isLoadingChallenges: boolean) => ({
  type: SET_LOADING_CHALLENGES,
  isLoadingChallenges,
});
const setChallenges = (challenges: any) => ({
  type: SET_CHALLENGES,
  challenges,
});

export const fetchAllChallenges = (type: String) => {
  return (dispatch: any) => {
    dispatch(setLoadingChallenges(true));
    challengeServices
      .fetchAll(type)
      .then((res) => {
        dispatch(setLoadingChallenges(false));
        return dispatch(setChallenges(res.data.response));
      })
      .catch(() => {
        dispatch(setChallenges([]));
        dispatch(setLoadingChallenges(false));
      });
  };
};

const setAddChallengeError = (addChallengeError: String | null) => ({
  type: SET_ADD_CHALLENGE_ERROR,
  addChallengeError,
});

const setNewChallenge = (isNewChallenge: boolean) => ({
  type: SET_NEW_CHALLENGE,
  isNewChallenge,
});

export const newChallenge = (formData: any, callback?: any) => {
  return (dispatch: any) => {
    dispatch(setNewChallenge(true));
    dispatch(setAddChallengeError(null));
    challengeServices
      .newChallenge(formData)
      .then((res: any) => {
        if (callback) callback();
        dispatch(setNewChallenge(false));
        dispatch(fetchAllChallenges(formData.get("type")));
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setAddChallengeError(err.response.data.errors.message));
        } else {
          dispatch(setAddChallengeError("Ha ocurrido un error"));
        }
        dispatch(setNewChallenge(false));
      });
  };
};

const setSelectedChallenge = (selectedChallenge: any) => ({
  type: SET_SELECTED_CHALLENGES,
  selectedChallenge,
});

export const fetchById = (id: String) => {
  return (dispatch: any) => {
    challengeServices.fetchById(id).then((res) => {
      return dispatch(setSelectedChallenge(res.data.response));
    });
  };
};

const setEditChallengeError = (editChallengeError: String | null) => ({
  type: SET_EDIT_CHALLENGE_ERROR,
  editChallengeError,
});

const setEditChallenge = (isEditedChallenge: boolean) => ({
  type: SET_EDITED_CHALLENGE,
  isEditedChallenge,
});

export const editChallenge = (id: String, formData: any, callback?: any) => {
  return (dispatch: any) => {
    dispatch(setEditChallenge(true));
    dispatch(setEditChallengeError(null));
    challengeServices
      .updateChallenge(id, formData)
      .then((res: any) => {
        if (callback) callback();
        dispatch(setEditChallenge(false));
        dispatch(fetchAllChallenges(formData.get("type")));
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setEditChallengeError(err.response.data.errors.message));
        } else {
          dispatch(setEditChallengeError("Ha ocurrido un error"));
        }
        dispatch(setEditChallenge(false));
      });
  };
};

const setDeletingChallenge = (isDeletingChallenge: boolean) => ({
  type: SET_DELETING_CHALLENGE,
  isDeletingChallenge,
});
const removeChallenge = (deletedChallengeId: String) => ({
  type: REMOVE_CHALLENGE,
  deletedChallengeId,
});

export const deleteChallenge = (id: String, callback: any) => {
  return (dispatch: any) => {
    dispatch(setDeletingChallenge(true));
    challengeServices.delete(id).then((res) => {
      dispatch(setDeletingChallenge(false));
      if (callback) callback();
      return dispatch(removeChallenge(id));
    });
  };
};

const setSortingChallenges = (isSortingChallenges: boolean) => ({
  type: SET_SORTING_CHALLENGES,
  isSortingChallenges,
});

const setSortChallengesError = (sortChallengesError: String | null) => ({
  type: SET_SORT_CHALLENGES_ERROR,
  sortChallengesError,
});

export const sortChallenges = (data: Object[]) => {
  return (dispatch: any) => {
    dispatch(setSortingChallenges(true));
    dispatch(setSortChallengesError(null));
    challengeServices
      .sortChallenges(data)
      .then((res) => {
        dispatch(setSortingChallenges(false));
      })
      .catch((err) => {
        dispatch(setSortChallengesError(err.response.data.errors.message));
        dispatch(setSortingChallenges(false));
      });
  };
};

const setLoadingSpecialChallenges = (isLoadingSpecialChallenges: boolean) => ({
  type: SET_LOADING_SPECIAL_CHALLENGES,
  isLoadingSpecialChallenges,
});

const setSpecialChallengesError = (specialChallengesError: any) => ({
  type: SET_SPECIAL_CHALLENGES_ERROR,
  specialChallengesError,
});

const setSpecialChallenges = (specialChallenges: string[]) => ({
  type: SET_SPECIAL_CHALLENGES,
  specialChallenges,
});

export const fetchSpecialChallenges = () => (dispatch: any) => {
  dispatch(setLoadingSpecialChallenges(true));
  dispatch(setSpecialChallengesError(null));
  challengeServices
    .fetchSpecial()
    .then((res: any) => {
      dispatch(setLoadingSpecialChallenges(false));
      dispatch(setSpecialChallenges(res.data.response));
    })
    .catch((e) => {
      dispatch(setLoadingSpecialChallenges(false));
      dispatch(setSpecialChallengesError(e));
    });
};
