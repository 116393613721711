const authConstants = {
  SET_LOADING_ADMINS: "SET_LOADING_ADMINS",
  SET_ADMINS: "SET_ADMINS",
  SET_EDITING_ADMIN: "SET_EDITING_ADMIN",
  SET_NEW_ADMIN: "SET_NEW_ADMIN",
  SET_SIGNUP_ERROR: "SET_SIGNUP_ERROR",
  SET_DELETING_ADMIN: "SET_DELETING_ADMIN",
  SET_DELETE_ADMIN_ERROR: "SET_DELETE_ADMIN_ERROR",
};

export default authConstants;
