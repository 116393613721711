import subscriptionsService from "./services";

import subscriptionsConstants from "./constants";

const {
  SET_LOADING_SUBSCRIPTION_CODES,
  SET_SUBSCRIPTION_CODES,
  SET_SUBSCRIPTION_CODES_ERROR,
  SET_LOADING_SUBSCRIPTION_CODE_USERS,
  SET_SUBSCRIPTION_CODE_USERS,
  SET_SUBSCRIPTION_CODE_USERS_ERROR,
  SET_ADDING_SUBSCRIPTION_CODE,
  SET_ADD_SUBSCRIPTION_CODE_ERROR,
  SET_EDITING_SUBSCRIPTION_CODE,
  SET_EDIT_SUBSCRIPTION_CODE_ERROR,
  SET_DELETING_SUBSCRIPTION_CODE,
  SET_DELETE_SUBSCRIPTION_CODE_ERROR,
  SET_EDITING_SUBSCRIPTION,
  SET_EDIT_SUBSCRIPTION_ERROR,
  SET_LOADING_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_ERROR,
  SET_USERS_DEVICES,
  SET_LOADING_USERS_DEVICES
} = subscriptionsConstants;

const setLoadingSubscriptionCodes = (isLoadingSubscriptionCodes: boolean) => ({
  type: SET_LOADING_SUBSCRIPTION_CODES,
  isLoadingSubscriptionCodes,
});

const setSubscriptionCodes = (subscriptionCodes: any) => ({
  type: SET_SUBSCRIPTION_CODES,
  subscriptionCodes,
});

const setSubscriptionCodesError = (subscriptionCodesError: any) => ({
  type: SET_SUBSCRIPTION_CODES_ERROR,
  subscriptionCodesError,
});

export const fetchAllSubscriptionCodes = () => {
  return (dispatch: any) => {
    dispatch(setLoadingSubscriptionCodes(true));
    dispatch(setSubscriptionCodesError(null));
    subscriptionsService
      .fetchAllCodes()
      .then((res) => {
        dispatch(setLoadingSubscriptionCodes(false));
        return dispatch(setSubscriptionCodes(res.data.response));
      })
      .catch((e) => {
        dispatch(setLoadingSubscriptionCodes(false));
        return dispatch(setSubscriptionCodesError(e));
      });
  };
};

const setLoadingSubscriptionCodeUsers = (
  isLoadingSubscriptionCodeUsers: boolean
) => ({
  type: SET_LOADING_SUBSCRIPTION_CODE_USERS,
  isLoadingSubscriptionCodeUsers,
});

const setSubscriptionCodeUsers = (subscriptionCodeUsers: any) => ({
  type: SET_SUBSCRIPTION_CODE_USERS,
  subscriptionCodeUsers,
});

const setSubscriptionCodeUsersError = (subscriptionCodeUsersError: any) => ({
  type: SET_SUBSCRIPTION_CODE_USERS_ERROR,
  subscriptionCodeUsersError,
});

export const fetchAllSubscriptionCodeUsers = () => {
  return (dispatch: any) => {
    dispatch(setLoadingSubscriptionCodeUsers(true));
    subscriptionsService
      .fetchAllCodeUsers()
      .then((res) => {
        dispatch(setLoadingSubscriptionCodeUsers(false));
        return dispatch(setSubscriptionCodeUsers(res.data.response));
      })
      .catch((e) => {
        dispatch(setLoadingSubscriptionCodeUsers(false));
        return dispatch(setSubscriptionCodeUsersError(e));
      });
  };
};

const setAddingSubscriptionCode = (isAddingSubscriptionCode: boolean) => ({
  type: SET_ADDING_SUBSCRIPTION_CODE,
  isAddingSubscriptionCode,
});

const setAddSubscriptionCodeError = (addSubscriptionCodeError: any) => ({
  type: SET_ADD_SUBSCRIPTION_CODE_ERROR,
  addSubscriptionCodeError,
});

export const addSubscriptionCode = (
  {
    code,
    company,
    type,
    duration,
    validCodeText,
    youHaveText,
    tariffRecommender,
    validUntil,
    premiumUsers,
    traffic_light_enabled,
    urlBanner,
    textBanner,
    buttonTextBanner,
  }: any,
  callback?: () => void
) => {
  return (dispatch: any) => {
    dispatch(setAddingSubscriptionCode(true));
    dispatch(setAddSubscriptionCodeError(null));
    subscriptionsService
      .addCode(
        code,
        company,
        type,
        duration,
        validCodeText,
        youHaveText,
        tariffRecommender,
        validUntil,
        premiumUsers,
        traffic_light_enabled,
        urlBanner,
        textBanner,
        buttonTextBanner
      )
      .then(() => {
        dispatch(setAddingSubscriptionCode(false));
        callback?.();
      })
      .catch((e) => {
        dispatch(setAddSubscriptionCodeError(e));
        dispatch(setAddingSubscriptionCode(false));
      });
  };
};

const setEditingSubscriptionCode = (isEditingSubscriptionCode: boolean) => ({
  type: SET_EDITING_SUBSCRIPTION_CODE,
  isEditingSubscriptionCode,
});

const setEditSubscriptionCodeError = (editSubscriptionCodeError: any) => ({
  type: SET_EDIT_SUBSCRIPTION_CODE_ERROR,
  editSubscriptionCodeError,
});

export const editSubscriptionCode = (
  {
    id,
    code,
    duration,
    company,
    type,
    validCodeText,
    youHaveText,
    tariffRecommender,
    validUntil,
    premiumUsers,
    traffic_light_enabled,
    urlBanner,
    textBanner,
    buttonTextBanner,
  }: any,
  callback?: () => void
) => {
  return (dispatch: any) => {
    dispatch(setEditingSubscriptionCode(true));
    dispatch(setEditSubscriptionCodeError(null));
    subscriptionsService
      .editCode(
        id,
        code,
        duration,
        company,
        type,
        validCodeText,
        youHaveText,
        tariffRecommender,
        validUntil,
        premiumUsers,
        traffic_light_enabled,
        urlBanner,
        textBanner,
        buttonTextBanner
      )
      .then((res) => {
        callback?.();
        return dispatch(setEditingSubscriptionCode(false));
      })
      .catch((e) => {
        dispatch(setEditingSubscriptionCode(false));
        return dispatch(setEditSubscriptionCodeError(e));
      });
  };
};

const setDeletingSubscriptionCode = (isDeletingSubscriptionCode: boolean) => ({
  type: SET_DELETING_SUBSCRIPTION_CODE,
  isDeletingSubscriptionCode,
});

const setDeleteSubscriptionCodeError = (deleteSubscriptionCodeError: any) => ({
  type: SET_DELETE_SUBSCRIPTION_CODE_ERROR,
  deleteSubscriptionCodeError,
});

export const deleteSubscriptionCode =
  (id: string, callback: any) => (dispatch: any) => {
    dispatch(setDeletingSubscriptionCode(true));
    dispatch(setDeleteSubscriptionCodeError(null));
    subscriptionsService
      .deleteCode(id)
      .then(() => {
        dispatch(setDeletingSubscriptionCode(false));
        if (callback) callback();
      })
      .catch((e) => {
        dispatch(setDeletingSubscriptionCode(false));
        dispatch(setDeleteSubscriptionCodeError(e));
      });
  };

const setEditingSubscription = (isEditingSubscription: boolean) => ({
  type: SET_EDITING_SUBSCRIPTION,
  isEditingSubscription,
});

const setEditSubscriptionError = (editSubscriptionError: any) => ({
  type: SET_EDIT_SUBSCRIPTION_ERROR,
  editSubscriptionError,
});

export const editSubscription =
  ({ userId, userType, userModelType, codeId, trialEndDate }: any) =>
  (dispatch: any) => {
    dispatch(setEditingSubscription(true));
    dispatch(setEditSubscriptionError(null));
    subscriptionsService
      .editSubscription({
        userId,
        userType,
        userModelType,
        codeId,
        trialEndDate,
      })
      .then(() => {
        dispatch(setEditingSubscription(false));
      })
      .catch((e) => {
        dispatch(setEditingSubscription(false));
        dispatch(setEditSubscriptionError(e));
      });
  };

const setLoadingSubscriptions = (isLoadingSubscriptions: boolean) => ({
  type: SET_LOADING_SUBSCRIPTIONS,
  isLoadingSubscriptions,
});

const setSubscriptions = (subscriptions: any[]) => ({
  type: SET_SUBSCRIPTIONS,
  subscriptions,
});

const setSubscriptionsError = (subscriptionsError: any) => ({
  type: SET_SUBSCRIPTIONS_ERROR,
  subscriptionsError,
});

export const fetchAllSubscriptions = () => (dispatch: any) => {
  dispatch(setLoadingSubscriptions(true));
  dispatch(setSubscriptionsError(null));
  subscriptionsService
    .fetchAll()
    .then((res) => {
      dispatch(setLoadingSubscriptions(false));
      dispatch(setSubscriptions(res?.data?.response));
    })
    .catch((e) => {
      dispatch(setLoadingSubscriptions(false));
      dispatch(setSubscriptionsError(e));
    });
};

