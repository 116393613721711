import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Dropdown,
  Button,
  Input,
  Menu,
  Table,
  Modal,
  Form,
  Select,
  Row,
  Col,
  message,
  DatePicker,
  Tooltip,
  Popconfirm,
} from "antd";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { fetchAllUsers } from "../../../../redux/users/actions";
import {
  fetchAllSubscriptionCodes,
  fetchAllSubscriptionCodeUsers,
  fetchAllSubscriptions,
  editSubscription,
} from "../../../../redux/subscriptions/actions";

import { DownOutlined, EditOutlined } from "@ant-design/icons";
import exportIcon from "../../../../assets/icon/export.png";
import XLSXLink from "../../../../components/XLSXLink";
import { fetchAllClients } from "redux/clients";

const { Search } = Input;

const Subscriptions = () => {
  const dispatch = useDispatch();

  const { users, loading: loadingUsers } = useSelector(
    (state: any) => state.users
  );
  const {
    subscriptionCodeUsers,
    loadingSubscriptionCodeUsers,
    subscriptionCodes,
    loadingSubscriptionCodes,
    subscriptions,
    loadingSubscriptions,
    editingSubscription,
    editSubscriptionError,
  } = useSelector((state: any) => state.subscriptions);

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  useEffect(() => {
    dispatch(fetchAllClients(dispatch));
  }, [dispatch]);

  const [pageSize, setPageSize] = useState(10);
  const [selectedToExport, setSelectedToExport] = useState([] as any[]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [codeFilters, setCodeFilters] = useState([] as any[]);
  const [typeCodeFilters, setTypeCodeFilters] = useState([] as any[]);
  const [typeUserFilters, setTypeUserFilters] = useState([] as any[]);
  const [trialEndFilter, setTrialEndFilter] = useState([] as any[]);
  const [companyFilters, setCompanyFilters] = useState([] as any[]);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [subscription, setSubscription] = useState(null as any);
  const [userModelType, setUserModelType] = useState("");
  const [freeTrialDate, setFreeTrialDate] = useState(
    null as moment.Moment | null
  );
  const [isTooltipLicenseVisible, setIsTooltipLicenseVisible] = useState(false);
  const [isTooltipUsedPremiumCodeVisible, setIsTooltipUsedPremiumCodeVisible] =
    useState(false);
  const [selectedCode, setSelectedCode] = useState(null as any);
  const [isConfirmEditVisible, setIsConfirmEditVisible] = useState(false);

  const headers = [
    { label: "Nombre y Apellido", key: "full_name" },
    { label: "Email", key: "email" },
    { label: "Teléfono", key: "phone" },
    { label: "Código de suscripción", key: "code" },
    { label: "Tipo de código", key: "type" },
    { label: "Empresa", key: "company_name" },
    { label: "Tipo de usuario", key: "user_type" },
    { label: "Finaliza", key: "trial_end" },
  ];

  const columns = [
    {
      title: "Nombre y Apellido",
      dataIndex: "full_name",
      key: "full_name",
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : "-",
        };
        return obj;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : "-",
        };
        return obj;
      },
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : "-",
        };
        return obj;
      },
    },
    {
      title: "Código de suscripción",
      dataIndex: "code",
      key: "code",
      filters: codeFilters,
      onFilter: (value: any, record: any) => {
        if (value === null && record.code === undefined) return true;
        return record.code === value;
      },
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : "-",
        };
        return obj;
      },
    },
    {
      title: "Tipo de código",
      dataIndex: "type",
      key: "type",
      render: (val: string) => (val === "License" ? "Licencia" : val),
      filters: typeCodeFilters,
      onFilter: (value: any, record: any) => {
        if (value === null && record.type === undefined) return true;
        return record.type === value;
      },
    },
    {
      title: "Empresa",
      dataIndex: "company_name",
      key: "company_name",
      filters: companyFilters,
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : "-",
        };
        return obj;
      },
      onFilter: (value: any, record: any) => {
        if (value === null && record.company_name === undefined) return true;
        return record.company === value;
      },
    },
    {
      title: "Tipo de usuario",
      dataIndex: "user_type",
      key: "user_type",
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : "-",
        };
        return obj;
      },
      filters: typeUserFilters,
      onFilter: (value: any, record: any) => {
        if (value === null && record.user_type === undefined) return true;
        return record.user_type === value;
      },
    },
    {
      title: "Finaliza",
      dataIndex: "trial_end",
      key: "trial_end",
      render: (text: any, record: any) => {
        const obj = {
          children: text ? reformatDate(text) : "-",
        };
        return obj;
      },
      filters: trialEndFilter,
      onFilter: (value: any, record: any) => {
        if (value === null && record.trial_end === undefined) return true;
        return record.trial_end === value;
      },
    },
    {
      title: "Editar",
      dataIndex: "id",
      key: "editar",
      render: (id: any, user: any) => (
        <>
          <EditOutlined
            style={{ fontSize: "20px", color: "#199479" }}
            onClick={() => showModalEdit(user)}
          />
        </>
      ),
    },
  ];

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      data.forEach((user: any) => {
        const { full_name, email, phone, code } = user;
        const superString = `${full_name},${email},${phone},${code}`;
        if (superString.toLowerCase().includes(value.toLowerCase())) {
          results.push(user);
        }
      });
      return setDisplayData(results);
    }
    if (value.trim() === "") {
      return setDisplayData(data);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const getFilters = (field: string, data: any[]) => {
    const filters: any[] = [];

    data.forEach((entry) => {
      const existingFilterIndex = filters.findIndex((x) => {
        if (!entry[field] && x.value === null) return true;
        else return x.value === entry[field];
      });

      if (existingFilterIndex >= 0) {
        const quantity =
          parseInt(
            filters[existingFilterIndex].text
              .split(" ")
              [
                filters[existingFilterIndex].text.split(" ").length - 1
              ].replaceAll(/\(*\)*/g, "")
          ) + 1;
        filters[existingFilterIndex].text = `${
          filters[existingFilterIndex].text.split(" ")[0]
        } (${quantity})`;
      } else {
        if (field === "company") {
          console.log("sus", { text: `Todas (1)`, value: entry[field] });
        }
        if (field === "company" && entry[field] === "0") {
          filters.push({ text: `Todas (1)`, value: entry[field] });
        } else if (field === "company" && !entry[field]) {
          filters.push({ text: `Ninguno (1)`, value: null });
        } else if (field === "type" && entry[field] === "License") {
          filters.push({ text: `Licencia (1)`, value: entry[field] });
        } else if (!entry[field]) {
          filters.push({ text: `Ninguno (1)`, value: null });
        } else {
          filters.push({ text: `${entry[field]} (1)`, value: entry[field] });
        }
      }
    });

    return filters;
  };

  const onSelectChange = (selection: any) => {
    setSelectedRowKeys(selection);

    const results: any = [];
    if (selection) {
      selection.forEach((k: any) => {
        data.forEach((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selection.length === 0) {
      return setSelectedToExport(data);
    }
  };

  const onFilter = (p: any, filters: any, s: any, { action }: any) => {
    if (action === "filter" && selectedRowKeys?.length === 0) {
      let exportData = [...data];

      for (let i in filters) {
        exportData = exportData.filter(
          (entry) =>
            filters?.[i]?.reduce(
              (prev: boolean, filter: string) =>
                prev ||
                (filter !== "null"
                  ? entry[i] === filter
                  : entry[i] === undefined),
              false
            ) ?? true
        );
      }
      setCodeFilters(getFilters("code", exportData));
      setCompanyFilters(getFilters("company", exportData));
      setTypeCodeFilters(getFilters("type", exportData));
      setTypeUserFilters(getFilters("user_type", exportData));
      setTrialEndFilter(getFilters("trial_end", exportData));
      // Translate License code type
      setSelectedToExport(
        exportData.map((entry: any) =>
          entry.type === "License" ? { ...entry, type: "Licencia" } : entry
        )
      );
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const exportLinks = (
    <Menu>
      <Menu.Item key="csv">
        <CSVLink
          style={{ textAlign: "center" }}
          data={selectedToExport}
          headers={headers}
          filename={"subscriptions.csv"}
          separator={","}
        >
          Exportar como .csv
        </CSVLink>
      </Menu.Item>
      <Menu.Item key="xlsx">
        <XLSXLink
          data={selectedToExport}
          headers={headers}
          filename="subscriptions"
          sheet="Suscripciones"
        >
          Exportar como .xlsx (Excel)
        </XLSXLink>
      </Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "index",
      breadcrumbName: "Suscripciones",
    },
  ];

  const showModalEdit = (subscription: any) => {
    setIsModalEditVisible(true);
    setSubscription(subscription);
    setSelectedCode(
      subscriptionCodes.find((x: any) => x.code === subscription.code)
    );
  };

  const handleEditCancel = () => {
    setIsModalEditVisible(false);
    setSubscription(null);
    setSelectedCode(null);
  };

  const handleEditOk = () => {
    dispatch(
      editSubscription({
        userId: subscription.user_id,
        userType: subscription.user_type,
        userModelType,
        codeId: selectedCode?.id,
        trialEndDate: freeTrialDate,
      })
    );
    const interval = setInterval(() => {
      if (!editingSubscription && !editSubscriptionError) {
        clearInterval(interval);
        setIsModalEditVisible(false);
        message.success("Suscripción actualizada con éxito");
        dispatch(fetchAllUsers());
        dispatch(fetchAllSubscriptions());
        dispatch(fetchAllSubscriptionCodeUsers());
        dispatch(fetchAllSubscriptionCodes());
      }
    }, 500);
  };

  useEffect(() => {
    dispatch(fetchAllSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    for (let subscription of subscriptions) {
      var newCompany = "0";
      for (let client of dataAllClients) {
        if (client.id == subscription.company) {
          newCompany = client.company_name;
          break;
        }
      }
      subscription.company = newCompany;
    }
    setData(subscriptions);
  }, [subscriptions]);

  useEffect(() => {
    setDisplayData(data);
    setSelectedToExport(data);
  }, [data]);
  useEffect(() => {
    setCodeFilters(getFilters("code", displayData));
    setCompanyFilters(getFilters("company", displayData));
    setTypeCodeFilters(getFilters("type", displayData));
    setTypeUserFilters(getFilters("user_type", displayData));
    setTrialEndFilter(getFilters("trial_end", displayData));
  }, [displayData]);

  return (
    <div>
      <PageHeader title="Suscripciones" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <Dropdown overlay={menu}>
          <Button>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Dropdown overlay={exportLinks}>
          <Button
            style={{
              color: "#199479",
              backgroundColor: "transparent",
              textAlign: "center",
              border: "1px solid #199479",
              borderRadius: "4px",
            }}
          >
            <img src={exportIcon} alt="" style={{ margin: "5px" }} />
            Exportar
          </Button>
        </Dropdown>
      </div>

      <div className="content_container">
        <Table
          columns={columns}
          dataSource={displayData}
          rowClassName={(record, index) =>
            record.newestCode === true ? "table-row-light" : "table-row-dark"
          }
          loading={
            loadingUsers ||
            loadingSubscriptionCodeUsers ||
            loadingSubscriptionCodes ||
            loadingSubscriptions
          }
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          pagination={{ pageSize }}
          onChange={onFilter}
          scroll={{ x: true }}
        />

        <Modal
          title="Editar suscripción"
          visible={isModalEditVisible}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
          footer={[
            <Button onClick={handleEditCancel} key="back">
              Cancelar
            </Button>,
            <Popconfirm
              title="Este usuario tiene una suscripción paga activa (a través de Stripe). Esta acción eliminará esa suscripción. ¿Continuar?"
              onConfirm={handleEditOk}
              okText="Sí"
              cancelText="No"
              key="submit"
              okButtonProps={{ loading: editingSubscription }}
              visible={isConfirmEditVisible}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setIsConfirmEditVisible(visible);
                  return;
                }

                if (
                  subscription.stripe_subscription_id ||
                  subscription.stripe_schedule_id
                ) {
                  setIsConfirmEditVisible(visible);
                } else {
                  handleEditOk();
                }
              }}
            >
              <Button type="primary" loading={editingSubscription}>
                Guardar
              </Button>
            </Popconfirm>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item label="Nuevo plan">
              <Select
                placeholder="Selecciona una opción"
                value={subscription?.user_type}
                onSelect={(val) => {
                  setSubscription({ ...subscription, user_type: val });
                  setUserModelType("");
                  setSelectedCode(null);
                }}
              >
                <Select.Option value="Free">Free</Select.Option>
                <Select.Option value="Premium">Premium</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Modelo de usuario">
              <Select
                placeholder="Selecciona una opción"
                value={userModelType}
                onSelect={(val) => {
                  setUserModelType(val as string);
                  setSelectedCode(null);
                }}
              >
                <Select.Option value="freeModel">Usuario Libre</Select.Option>
                <Select.OptGroup label="Usuario Empresa">
                  <Select.Option
                    value="licenseModel"
                    disabled={
                      (subscription?.has_devices &&
                        subscription?.user_type === "Free") ||
                      (!subscription?.has_devices &&
                        subscription?.user_type === "Premium")
                    }
                  >
                    <Tooltip
                      title={`El usuario ${
                        subscription?.has_devices ? "tiene" : "no tiene"
                      } dispositivos conectados. Cambia a plan ${
                        subscription?.user_type === "Free" ? "Premium" : "Free"
                      } para habilitar esta opción.`}
                      visible={isTooltipLicenseVisible}
                    >
                      <div
                        onMouseEnter={() =>
                          setIsTooltipLicenseVisible(
                            (subscription?.has_devices &&
                              subscription?.user_type === "Free") ||
                              (!subscription?.has_devices &&
                                subscription?.user_type === "Premium")
                          )
                        }
                        onMouseLeave={() => setIsTooltipLicenseVisible(false)}
                      >
                        Modelo de Licencia
                      </div>
                    </Tooltip>
                  </Select.Option>
                  <Select.Option value="cardModel">
                    Modelo de Tarjetas
                  </Select.Option>
                </Select.OptGroup>
              </Select>
            </Form.Item>

            {userModelType && userModelType !== "freeModel" ? (
              <Form.Item label="Código de suscripción">
                <Select
                  value={selectedCode?.id}
                  onSelect={(id) =>
                    setSelectedCode(
                      subscriptionCodes.find((x: { id: string }) => x.id === id)
                    )
                  }
                >
                  {subscriptionCodes
                    .filter((code: any) => {
                      if (userModelType === "licenseModel")
                        return code.type === "License";
                      else if (userModelType === "cardModel")
                        return code.type === subscription?.user_type;
                      else return false;
                    })
                    .map((code: any) => (
                      <Select.Option
                        value={code.id}
                        key={code.id}
                        disabled={code.type === "Premium" && code.isActive}
                      >
                        <Tooltip
                          title="Este código ya está en uso"
                          visible={isTooltipUsedPremiumCodeVisible}
                        >
                          <div
                            onMouseEnter={() =>
                              code.type === "Premium" &&
                              code.isActive &&
                              setIsTooltipUsedPremiumCodeVisible(true)
                            }
                            onMouseLeave={() =>
                              code.type === "Premium" &&
                              code.isActive &&
                              setIsTooltipUsedPremiumCodeVisible(false)
                            }
                          >
                            {code.code}
                          </div>
                        </Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            ) : (
              userModelType &&
              subscription?.user_type === "Premium" && (
                <Form.Item label="Fecha de fin de la prueba">
                  <DatePicker
                    style={{ width: "100%" }}
                    onSelect={(val) => setFreeTrialDate(val)}
                  />
                </Form.Item>
              )
            )}

            {selectedCode && (
              <>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="Duración">
                      <Input
                        value={selectedCode?.duration}
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Tipo">
                      <Input
                        value={
                          selectedCode?.type === "License"
                            ? "Licencia"
                            : selectedCode?.type
                        }
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="Empresa">
                      <Input
                        value={selectedCode?.company}
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Recomendador tarifas">
                      <Input
                        value={selectedCode?.tariff_recommender ? "Sí" : "No"}
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Subscriptions;



function reformatDate(text: string){
  if(text === '') return '-'

  let today = moment().format('YYYY-MM-DD')
  let sentDate = moment(text).format('YYYY-MM-DD')
  
  let whosBigger = moment(sentDate).unix() - moment(today).unix()

  if(whosBigger >= 0) return <p>{sentDate}</p>
  if(whosBigger < 0) return <p style={{color: 'red'}}>{sentDate}</p>
}