const usersConstants = {
  SET_LOADING_USERS: "SET_LOADING_USERS",
  SET_USERS: "SET_USERS",
  CHECKING_HOME: "CHECKING_HOME",
  SET_HOME: "SET_HOME",
  SET_EDITING_USER: "SET_EDITING_USER",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
  SET_EDIT_OK: "SET_EDIT_OK",
  PAGE_SIZE: 10,
  SET_SELECTED_ROW_KEYS: "SET_SELECTED_ROW_KEYS",
  SET_DELETING_USER: "SET_DELETING_USER",
  SET_DELETE_USER_ERROR: "SET_DELETING_USER",
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_LAST_QUERY: 'SET_LAST_QUERY'
};

export default usersConstants;
