import oprimizationConstants from './constants';

const {
  SET_LOADING_OPTIMIZACION,
  SET_OPTIMIZACION,
  SET_SELECTED_ROW_KEYS,
  SET_LOADING_EDIT,
  SET_ERROR_EDIT,
} = oprimizationConstants;

const initialState = {
  selectedRowKeys: [],
};

const optimizationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_OPTIMIZACION: {
      return {
        ...state,
        loadingOptimization: action.isLoadingOptimization,
      };
    }
    case SET_OPTIMIZACION: {
      return {
        ...state,
        optimizations: action.optimizations,
      };
    }

    case SET_SELECTED_ROW_KEYS: {
      return {
        ...state,
        selectedRowKeys: action.selectedRowKeys,
      };
    }
    case SET_LOADING_EDIT: {
      return {
        ...state,
        isLoadingEdit: action.isLoadingEdit,
      };
    }
    case SET_ERROR_EDIT: {
      return {
        ...state,
        editError: action.editError,
      };
    }

    default: {
      return state;
    }
  }
};

export default optimizationReducer;
