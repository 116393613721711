import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Upload,
  Avatar,
  Divider,
  Select,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { EditOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { fetchById } from "../../redux/publications/actions";
import styles from "./styles.module.scss";

function PublicationsEditModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  selectedId: String;
  successMessage?: string;
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
    selectedId,
  } = props;

  const dispatch = useDispatch();

  const [fields, setFields]: any = useState([
    {
      name: ["title"],
      value: "",
    },
    {
      name: ["semillas"],
      value: "",
    },

    {
      name: ["url"],
      value: "",
    },
  ]);

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedImage, setSelectedImage] = useState();
  const [defaultImage, setDefaultImage] = useState("");
  const { selectedPublication, editPublicationError } = useSelector(
    (state: any) => state.publications
  );

  useEffect(() => {
    if (visible) {
      dispatch(fetchById(selectedId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible && selectedPublication) {
      setFields([
        {
          name: ["title"],
          value: selectedPublication.title,
        },
        {
          name: ["semillas"],
          value: selectedPublication.semillas,
        },
        {
          name: ["url"],
          value: selectedPublication.url,
        },
        {
          name: ["company"],
          value: selectedPublication.company,
        },
      ]);
      setDefaultImage(selectedPublication.image);
    }
  }, [visible, selectedPublication]);

  const openModal = () => setVisible(true);
  const onCancel = () => {
    setVisible(false);
    setSelectedImage(undefined);
  };
  const onSuccess = () => {
    setVisible(false);
    setSelectedImage(undefined);
    form.resetFields();
    message.success(successMessage || "Exito");
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const { title, semillas, url, company } = values;
        const id = selectedPublication.id;
        const form = new FormData();

        form.append("title", title);
        form.append("semillas", semillas);
        form.append("url", url);
        form.append("company", company);
        if (selectedImage) form.append("image", selectedImage as any);

        onConfirm(id, form, onSuccess);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <EditOutlined
        style={{ fontSize: "20px", color: "#199479" }}
        onClick={openModal}
      >
        {openButtonText}
      </EditOutlined>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Crear
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} fields={fields}>
          <Form.Item
            label="Titulo"
            name="title"
            rules={[{ required: true }]}
            validateStatus={editPublicationError && "error"}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Semillas"
            name="semillas"
            rules={[{ required: true }]}
            validateStatus={editPublicationError && "error"}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Enlace (URL)"
            name="url"
            rules={[{ required: true }]}
            validateStatus={editPublicationError && "error"}
            help={editPublicationError || null}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Empresa"
            name="company"
            validateStatus={editPublicationError && "error"}
            initialValue="0"
          >
            <Select>
              <Select.Option value="0">Todas</Select.Option>
              {dataAllClients.map((company_map: any) => (
                <Select.Option key={company_map.id} value={company_map.id}>
                  {company_map.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <>
                <span>Imagen</span>
                <Divider type="vertical" />
                {defaultImage && (
                  <Avatar
                    src={defaultImage}
                    alt="icon"
                    shape="square"
                    size="large"
                  />
                )}
              </>
            }
            name="image"
            validateStatus={editPublicationError && "error"}
          >
            <Upload.Dragger
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              beforeUpload={() => false}
              multiple={false}
              onChange={({ fileList }) => {
                if (fileList.length > 0)
                  setSelectedImage(fileList[0].originFileObj as any);
                else setSelectedImage(undefined);
              }}
              listType="picture"
            >
              <p className={styles.cloudIcon}>
                <CloudUploadOutlined />
              </p>
              <p className={styles.hintText}>
                Clica o arrastra aquí para subir la foto de portada
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default PublicationsEditModalForm;
