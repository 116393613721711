import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Popconfirm,
  message,
  Card,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllAdmins,
  editAdmin,
  newAdmin,
  deleteAdmin,
} from "../../../../redux/admins/actions";
import AdminModalForm from "../../../../components/AdminModalForm";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ClientModalForm from "components/ClientModalForm";
import { fetchAllClients } from "redux/clients";
import style from "./styles.module.scss";
import defaultIcon from "./../../../../assets/defaults_icons/person_default.png";
import { fetchAllLanguage } from "redux/languages";

function Clients() {
  const dispatch = useDispatch();

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllClients(dispatch));
    dispatch(fetchAllLanguage(dispatch));
  }, [dispatch]);

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  const { user } = useSelector((state: any) => state.auth);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [orderDataAllClients, setOrderDataAllClients] = useState([]);

  useEffect(() => {
    if (user) {
      if (user.super_admin === true) {
        setSuperAdmin(true);
      }
    }
  }, [user]);

  //order useEffect with company_name
  useEffect(() => {
    if (dataAllClients && dataAllClients.length > 0) {
      setOrderDataAllClients(
        dataAllClients.slice().sort((a: any, b: any) => {
          if (a.company_name < b.company_name) {
            return -1;
          }
          if (a.company_name > b.company_name) {
            return 1;
          }
          return 0;
        })
      );
    }
  }, [dataAllClients]);

  const [visible, setVisible] = useState(false);
  const [client, setClient]: any = useState();

  const { Text } = Typography;

  const routes = [
    {
      path: "",
      breadcrumbName: "Administradores / Clientes",
    },
  ];

  return (
    <div>
      {superAdmin && (
        <PageHeader
          title="Clientes"
          ghost={false}
          breadcrumb={{ routes }}
          extra={[
            <ClientModalForm
              modalTitle={"Alta clientes"}
              openButtonText={"Añadir"}
              visible={visible}
              setVisible={setVisible}
              client={client}
              setClient={setClient}
            />,
          ]}
        />
      )}

      <div className="content_container">
        <div className="card-group">
          {orderDataAllClients &&
            orderDataAllClients.length > 0 &&
            orderDataAllClients.map((item: any) => {
              return (
                <Card
                  key={item.id}
                  className={style.card}
                  onClick={() => {
                    setClient(item);
                    setVisible(true);
                  }}
                >
                  <img
                    className={style.card_image}
                    src={item.icon != null ? item.icon : defaultIcon}
                    alt="Avatar"
                  />
                  <div className={style.card_content}>
                    <Text className="title">{item.company_name}</Text>
                  </div>
                </Card>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Clients;
