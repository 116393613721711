import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Layout, Menu, Row, message, Button } from "antd";

import styles from "./styles.module.scss";
import logo from "../../assets/hobeen-horizontal-logo.png";
import logoutIcon from "../../assets/icon/logout.png";
import estadisticasIcon from "../../assets/icon/estadisticas.png";

import administradoresIcon from "../../assets/icon/administradores.png";
import suscripcionesIcon from "../../assets/icon/suscripciones.png";
import publicacionesIcon from "../../assets/icon/publicaciones.png";
import retosIcon from "../../assets/icon/retos.png";
import usuariosIcon from "../../assets/icon/usuarios.png";
import semillasIcon from '../../assets/icon/icon.svg'

import Icono from "../../components/MenuItemImg";

import Users from "./users/";
import Admins from "./management/profiles";

import PublicationsWater from "./publications/water";
import PublicationsLight from "./publications/light";
import PublicationsGas from "./publications/gas";

import ChallengeWater from "./challenge/water";
import ChallengeGas from "./challenge/gas";
import ChallengeLight from "./challenge/light";
import ChallengeImprovement from "./challenge/improvement";


import Subscriptions from "./subscriptions/subscriptions";
import CodeTexts from "./subscriptions/codeTexts";

import Exchange from './plans-seeds/exchange'
import Plans from './plans-seeds/plans' 

import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth/actions";
import Clients from "./management/clients";
import Profiles from "./management/profiles";

const { Header, Sider, Content } = Layout;

function Dashboard() {
  const { SubMenu } = Menu;

  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    history.push("/login");
    message.success("Has cerrado sesión");
  };

  const { user, checkingSession } = useSelector((state: any) => state.auth);
  const [email, setEmail] = useState();

  useEffect(() => {
    if (checkingSession === false) {
      if (user) {
        setEmail(user.email);
      }
    }
  }, [checkingSession, user]);

  const logout = () => {
    dispatch(logoutUser(onLogout));
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className={styles.header}>
        <Row justify="end">
          <img src={logo} alt="Logo" className={styles.logo} />

          <div className={styles.grid_container}>
            <div className={styles.grid_item}>{email}</div>

            <div className={styles.grid_item}>
              <Button
                style={{
                  color: "#199479",
                  border: "1px solid #199479",
                  borderRadius: "4px",
                  backgroundColor: "transparent",
                }}
                onClick={logout}
              >
                <span className={styles.icon}>
                  <img src={logoutIcon} alt="" />
                </span>
                Desconexión
              </Button>
            </div>
          </div>
        </Row>
      </Header>

      <Layout>
        <Sider breakpoint="lg" className={styles.sider}>
          <Menu
            className={styles.menu}
            mode="inline"
            defaultSelectedKeys={[window.location.pathname.replace("/", "")]}
          >
            <SubMenu
              className={styles.submenu}
              key="estadisticas"
              title="Estadísticas"
              icon={<Icono img={estadisticasIcon} />}
            >
              <Menu.Item key="1">
                <Link className={styles.link} to="/estadisticas/usuarios">
                  Usuarios
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link className={styles.link} to="/estadisticas/ventas">
                  Ventas
                </Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="usuarios"
              className={styles.item}
              icon={<Icono img={usuariosIcon} />}
            >
              <Link to="/usuarios" className={styles.link}>
                Usuarios
              </Link>
            </Menu.Item>

            <SubMenu
              key="suscripciones"
              icon={<Icono img={suscripcionesIcon} />}
              title="Suscripciones"
              className={styles.submenu}
            >
              <Menu.Item key="3">
                <Link to="/suscripciones" className={styles.link}>
                  Suscripciones
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/suscripciones/codigos" className={styles.link}>
                  Códigos
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="retos"
              icon={<Icono img={retosIcon} />}
              title="Retos"
              className={styles.submenu}
            >
              <Menu.Item key="6">
                <Link className={styles.link} to="/retos/agua">
                  Agua
                </Link>
              </Menu.Item>
              <Menu.Item key="7">
                <Link className={styles.link} to="/retos/luz">
                  Luz
                </Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link className={styles.link} to="/retos/gas">
                  Gas
                </Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link className={styles.link} to="/retos/mejora">
                  Mejora
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="publicaciones"
              icon={<Icono img={publicacionesIcon} />}
              title="Publicaciones"
              className={styles.submenu}
            >
              <Menu.Item key="10">
                <Link className={styles.link} to="/publicaciones/agua">
                  Agua
                </Link>
              </Menu.Item>
              <Menu.Item key="11">
                <Link className={styles.link} to="/publicaciones/luz">
                  Luz
                </Link>
              </Menu.Item>
              <Menu.Item key="12">
                <Link className={styles.link} to="/publicaciones/gas">
                  Gas
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="seeds"
              icon={<Icono img={semillasIcon} />}
              title="Planes de Semillas"
              className={styles.submenu}
            >
              <Menu.Item key="13">
                <Link to="/semillas/planes" className={styles.link}>
                  Planes
                </Link>
              </Menu.Item>
              <Menu.Item key="14">
                <Link to="/semillas/canjes" className={styles.link}>
                  Canjes
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="management"
              icon={<Icono img={administradoresIcon} />}
              title="Administración"
              className={styles.submenu}
            >
              <Menu.Item key="15">
                <Link to="/administracion/clientes" className={styles.link}>
                  Clientes
                </Link>
              </Menu.Item>
              <Menu.Item key="16">
                <Link to="/administracion/profiles" className={styles.link}>
                  Perfiles
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Content className={styles.content}>
          <Switch>
            <Route path="/estadisticas/usuarios" component={Admins} />
            <Route path="/estadisticas/ventas" component={Admins} />
            <Route path="/usuarios" component={Users} exact />
            <Route path="/suscripciones/codigos" component={CodeTexts} />
            <Route path="/suscripciones" component={Subscriptions} />
            <Route path="/retos/agua" component={ChallengeWater} />
            <Route path="/retos/luz" component={ChallengeLight} />
            <Route path="/retos/gas" component={ChallengeGas} />
            <Route path="/retos/mejora" component={ChallengeImprovement} />
            <Route path="/publicaciones/agua" component={PublicationsWater} />
            <Route path="/publicaciones/luz" component={PublicationsLight} />
            <Route path="/publicaciones/gas" component={PublicationsGas} />
            <Route path="/administracion/clientes" component={Clients} />
            <Route path="/administracion/profiles" component={Profiles} />
            <Route path='/semillas/canjes' component={Exchange} />
            <Route path='/semillas/planes' component={Plans} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
