import servicesConstants from "./constant";

const {
    CREATE_PLAN,
    SET_CREATING_PLAN,
    SET_UPDATING_PLAN,
    SET_LOADING_PLANS,
    SET_PLANS,
    SET_SELECTED_ROW_KEYS,  

    SET_LOADING_HISTORY,
    SET_PLANS_HISTORY
} = servicesConstants;

const initialState = {
  loading: false,
  data: [],
  plansHistory: [],
  selectedRowKeys: []
};

const servicesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_PLANS: 
      return {
        ...state,
        loading: action.isLoadingPlans
      }
    
    case SET_PLANS: 
      return {
        ...state,
        data: action.plans
      }

    case SET_CREATING_PLAN: 
      return {
        ...state,
        isCreating: action.isLoading
      }

    case SET_UPDATING_PLAN: 
      return {
        ...state,
        isUpdating: action.isLoading
      }


    case SET_LOADING_HISTORY: 
      return {
        ...state,
        isLoadingHistory: action.isLoading,
      }

    case SET_PLANS_HISTORY:
      return {
        ...state,
        plansHistory: action.data
      }

    case SET_SELECTED_ROW_KEYS: {
      return {
        ...state,
        selectedRowKeys: action.selectedRowKeys,
      };
    }

    default: {
      return state;
    }
  }
};

export default servicesReducer;
