import React, { useState } from 'react';
import { Form, Input, Button, Result } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import logo from '../../assets/logo-hobeen.png';
import authServices from '../../redux/auth/services';

function RecoverToken() {
  const { token } = useParams<{ token: string }>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onFinish = async ({ password }: { password: string }) => {
    try {
      setError(undefined);
      setLoading(true);
      await authServices.postRecovery(token, password);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(err.response.data.errors.message);
      setLoading(false);
    }
  };

  if (success) {
    return (
      <Result
        status="success"
        title="Contraseña cambiada correctamente"
        style={{ paddingTop: 200 }}
        extra={[
          <Link to="/login">
            <Button type="primary" key="login">
              Volver a la página principal
            </Button>
          </Link>,
        ]}
      />
    );
  }

  return (
    <div className={styles.login_form_container}>
      <img src={logo} alt="Logo" className={styles.login_form_logo} />
      <Form name="post_token" onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor introduce tu contraseña nueva',
            },
          ]}
          validateStatus={error && 'error'}
          help={error || null}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Nueva contraseña"
            disabled={loading}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login_form-submit"
            disabled={loading}
            block
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default RecoverToken;
