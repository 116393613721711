import React from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import logo from '../../assets/logo-hobeen.png';
import { loginUser } from '../../redux/auth/actions';

function Login() {
  const { loggingIn, loginError } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const onLoginSuccess = () => {
    history.push('/admins');
  };

  const onFinish = (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    const { email, password, remember } = values;
    const data = { email, password };
    dispatch(loginUser(data, remember, onLoginSuccess));
  };

  return (
    <div className={styles.login_form_container}>
      <img src={logo} alt="Logo" className={styles.login_form_logo} />
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          className={styles.space}
          name="email"
          rules={[
            { required: true, message: 'Por favor introduce tu email' },
            { type: 'email', message: 'Introduce un email válido' },
          ]}
          validateStatus={loginError && 'error'}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Tu email"
            disabled={loggingIn}
            style={{ color: '#199479' }}
          />
        </Form.Item>
        <Form.Item
          className={styles.space}
          name="password"
          rules={[
            { required: true, message: 'Por favor introduce tu contraseña' },
          ]}
          validateStatus={loginError && 'error'}
          help={loginError || null}
        >
          <Input
            prefix={
              <LockOutlined
                className="site-form-item-icon"
                style={{ color: '#199479' }}
              />
            }
            type="password"
            placeholder="Tu contraseña"
            disabled={loggingIn}
          />
        </Form.Item>
        <Form.Item className={styles.space}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox disabled={loggingIn} style={{ float: 'left' }}>
              Recuerdame
            </Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item className={styles.space}>
          <Link className={styles.login_form_forgot} to="/recover/request">
            ¿Olvidaste la contraseña?
          </Link>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login_form-submit"
            disabled={loggingIn}
            block
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
