import seedsServices from "./services";
import seedsConstants from "./constant";

const {
    CREATE_PLAN,
    SET_CREATING_PLAN,
    SET_UPDATING_PLAN,
    SET_LOADING_PLANS,
    SET_PLANS,

    SET_SELECTED_ROW_KEYS,
    SET_LOADING_HISTORY,
    SET_PLANS_HISTORY,
} = seedsConstants


const loadingPlans = (isLoadingPlans: boolean) => ({
    type: SET_LOADING_PLANS,
    isLoadingPlans
}) 

const setPlans = (plans: any) => ({
    type: SET_PLANS,
    plans
})

export const findAllPlans = ()=>{
    return (dispatch: any) => {
        dispatch(loadingPlans(true))
        seedsServices.fetch()
        .then( (res)=>{
            
            dispatch(loadingPlans(false))
            dispatch(setPlans(res.data.response))
        })
    }
}

const setCreatingPlan = (isLoading: Boolean) => ({
    type: SET_CREATING_PLAN,
    isLoading
})

export const createPlan = (data: FormData) => {
    return (dispatch: any) => {
        dispatch(setCreatingPlan(true))
        seedsServices.create(data)
        .then( (res)=>{
            dispatch(setCreatingPlan(false))
            dispatch(findAllPlans())
        })
        .catch( (res)=>{
            dispatch(setCreatingPlan(false))
        })
    }
}

const setUpdatingPlan = (isLoading: Boolean) => ({
    type: SET_UPDATING_PLAN,
    isLoading
})

export const updatePlan = (data: FormData, id: String) => {
    return (dispatch: any) => {
        dispatch(setUpdatingPlan(true))
        seedsServices.update(data, id)
        .then( (res)=>{
            dispatch(setUpdatingPlan(false))
            dispatch(findAllPlans())
        })
    }
}

export const sortPlans = (data: Object[]) => {
    return (dispatch: any) => {
    //   dispatch(setSortingChallenges(true));
    //   dispatch(setSortChallengesError(null));
        seedsServices
        .sort(data)
        .then((res) => {
        //   dispatch(setSortingChallenges(false));
        })
        .catch((err) => {
        //   dispatch(setSortChallengesError(err.response.data.errors.message));
        //   dispatch(setSortingChallenges(false));
        });
    };
};

export const changePlanVisibility = (
    id: String,
    newVisibility: boolean
  ) => {
    return (dispatch: any) => {
      seedsServices
        .changeVisibility(id, newVisibility)
        .then((res) => {
            dispatch(findAllPlans());
        })
        .catch((err) => {
            // dispatch(
            //     setChangePublicationVisibilityError(err.response.data.errors.message)
            // );
            // dispatch(setChangingPublicationVisibility(false));
        });
    };
};

export const deletePlan = (id: String) => {
    return (dispatch: any) => {
    //   dispatch(setDeletingPublication(true));
        seedsServices.delete(id).then((res) => {
        // dispatch(setDeletingPublication(false));
        // if (callback) callback();
        // return dispatch(removePublication(id));
        dispatch(findAllPlans());
        });
    };
};


export const setHistory = (data: any) => ({
    type: SET_PLANS_HISTORY,
    data
})

export const setLoadingHistory = (isLoading: boolean) => ({
    type: SET_LOADING_HISTORY,
    isLoading
})

export const getHistory = () => {
    return (dispatch: any) => {
        
        dispatch(setLoadingHistory(true))
        seedsServices
        .getHistory()
        .then( (res)=>{
            dispatch(setHistory(res.data.response))
            dispatch(setLoadingHistory(false))
                console.log(res)
            })
    }
}

export function setSelectedRowKeys(selectedRowKeys: any) {
    return (dispatch: any) => {
        return dispatch(selecteddRowKeys(selectedRowKeys));
    };
}

  
const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });