import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Select,
  Upload,
  Row,
  Col,
  Avatar,
  Divider,
  Typography,
  Tooltip,
  InputNumber,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  EditOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { fetchById } from "../../redux/challenges/actions";
import { fetchAllClients } from "redux/clients";
import styles from "./styles.module.scss";

const { Option } = Select;
const { TextArea } = Input;

function ChallengesEditModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  selectedId: String;
  successMessage?: string;
  image: string;
  existingSpecialTypes: string[];
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
    selectedId,
    image,
  } = props;

  const dispatch = useDispatch();

  const [initialValues, setInitialValues]: any = useState(null);
  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  const [isOpening, setIsOpening] = useState(false);
  const [isLinkAllowed, setIsLinkAllowed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { selectedChallenge, editChallengeError } = useSelector(
    (state: any) => state.challenges
  );
  const [selectedIcon, setSelectedIcon]: any = useState(null);
  const [selectedChallengeImage, setSelectedChallengeImage]: any =
    useState(null);
  const [selectedInfographics, setSelectedInfographics]: any = useState(null);
  const [selectedImage, setSelectedImage]: any = useState(null);

  const [defaultIcon, setDefaultIcon] = useState("");
  const [defaultChallengeImage, setDefaultChallengeImage] = useState("");
  const [defaultInfographics, setDefaultInfographics] = useState("");
  const [defaultImage, setDefaultImage] = useState("");
  const [text, setText] = useState("");
  const [textPreview, setTextPreview] = useState(false);

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevState: any = usePrevious({ selectedChallenge });

  useEffect(() => {
    if (isOpening) {
      dispatch(fetchById(selectedId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpening]);

  useEffect(() => {
    if (
      isOpening &&
      selectedChallenge &&
      prevState &&
      selectedChallenge !== prevState.selectedChallenge
    ) {
      const {
        title,
        points,
        duration,
        display_type,
        category,
        company,
        text,
        second_text,
        type,
        icon,
        challenge_image,
        infographics,
        image,
        link,
        special_type,
      } = selectedChallenge;
      const new_special_type = special_type ? special_type : "none";
      setInitialValues({
        title,
        points,
        duration,
        display_type,
        category,
        company,
        text,
        second_text,
        type,
        link,
        special_type: new_special_type,
      });
      form.resetFields()
      setIsLinkAllowed(display_type === "link");
      setDefaultIcon(icon);
      setDefaultChallengeImage(challenge_image);
      setDefaultInfographics(infographics);
      setDefaultImage(image);
      setVisible(true);
      setIsOpening(false);
      setText(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpening, selectedChallenge]);

  const resetSelected = () => {
    setInitialValues(null);
    setSelectedIcon(null);
    setSelectedChallengeImage(null);
    setSelectedInfographics(null);
    setSelectedImage(null);
    setDefaultIcon("");
    setDefaultChallengeImage("");
    setDefaultInfographics("");
    setDefaultImage("");
    setText("");
    setTextPreview(false);
  };

  const openModal = () => setIsOpening(true);
  const onCancel = () => {
    setVisible(false);
    resetSelected();
    form.resetFields();
  };
  const onSuccess = () => {
    resetSelected();
    setVisible(false);
    form.resetFields();
    message.success(successMessage || "Exito");
  };

  const handleTypeSelect = (value: any) => {
    if (value === "text") {
      setIsLinkAllowed(false);
    }

    if (value === "link") {
      setIsLinkAllowed(true);
    }
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const {
          title,
          category,
          company,
          points,
          duration,
          text,
          display_type,
          link,
          second_text,
          special_type,
        } = values;
        const id = selectedChallenge.id;
        const formData = new FormData();

        formData.append("image", selectedImage);
        formData.append(
          "icon",
          selectedIcon ? selectedIcon.originFileObj : null
        );
        formData.append(
          "challenge_image",
          selectedChallengeImage ? selectedChallengeImage.originFileObj : null
        );
        formData.append("title", title);
        formData.append("points", points);
        formData.append("duration", duration);
        formData.append("display_type", display_type);
        formData.append("category", category);
        formData.append("company", company);
        formData.append(
          "infographics",
          selectedInfographics ? selectedInfographics.originFileObj : null
        );
        formData.append("text", text);
        formData.append("second_text", second_text);
        formData.append(
          "image",
          selectedImage ? selectedImage.originFileObj : null
        );
        formData.append("link", link);
        formData.append("special_type", special_type);

        onConfirm(id, formData, onSuccess);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleTextChange = (e: any) => {
    setText(e.target.value);
    if (e.target.value.length <= 130) {
      setTextPreview(false);
    }
  };

  const togglePreview = () => {
    if (textPreview) {
      setTextPreview(false);
    } else {
      setTextPreview(true);
    }
  };

  const [showSpendingTooltip, setShowSpendingTooltip] = useState(false);

  return (
    <>
      <EditOutlined
        style={{ fontSize: "20px", color: "#199479" }}
        onClick={openModal}
      >
        {openButtonText}
      </EditOutlined>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        destroyOnClose
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Guardar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <img src={image} alt="" />

          <Form.Item
            label="Icono"
            name="icon"
            // rules={[{ required: true, message: "El campo icono es obligatorio" }]}
            validateStatus={editChallengeError && "error"}
          >
            <Upload
              type="select"
              name="icon"
              onChange={({ fileList }) => setSelectedIcon(fileList[0])}
              onRemove={() => setSelectedIcon(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={selectedIcon ? [selectedIcon] : undefined}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Cambiar Icono</Button>
              <Divider type="vertical" />
              {defaultIcon && (
                <Avatar
                  src={defaultIcon}
                  alt="icon"
                  shape="square"
                  size="large"
                />
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Imagen del reto"
            name="challenge_image"
            validateStatus={editChallengeError && "error"}
            // rules={[
            //   {
            //     required: true, message: 'El campo es obligatorio'
            //   }
            // ]}
          >
            <Upload
              type="select"
              name="challenge_image"
              onChange={({ fileList }) =>
                setSelectedChallengeImage(fileList[0])
              }
              onRemove={() => setSelectedChallengeImage(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={
                selectedChallengeImage ? [selectedChallengeImage] : undefined
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Cambiar Imagen del Reto</Button>
              <Divider type="vertical" />
              {defaultChallengeImage && (
                <Avatar
                  src={defaultChallengeImage}
                  alt="challenge image"
                  shape="square"
                  size="large"
                />
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Titulo"
            name="title"
            rules={[
              {
                required: true,
                message: "El campo es obligatorio",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Semillas"
                name="points"
                rules={[
                  { required: true, message: "El campo es obligatorio" },
                  {
                    type: "number",
                    message: "Solo se pueden introducir numeros",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Duración"
                name="duration"
                rules={[
                  { required: true, message: "El campo es obligatorio" },
                  {
                    type: "number",
                    message: "Solo se pueden introducir numeros",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Tipo"
            name="display_type"
            rules={[
              {
                required: true,
                message: "El campo es obligatorio",
              },
            ]}
          >
            <Select onChange={(value) => handleTypeSelect(value)}>
              <Option value="text">Texto</Option>
              <Option value="link">Link</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Categoría"
            name="category"
            rules={[
              {
                required: true,
                message: "El campo es obligatorio",
              },
            ]}
          >
            <Select>
              <Option value="Premium">Premium</Option>
              <Option value="Free">Free</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Empresa"
            name="company"
            initialValue="0"
            rules={[
              {
                required: true,
                message: "El campo es obligatorio",
              },
            ]}
          >
            <Select>
              <Select.Option value="0">Todas</Select.Option>
              {dataAllClients.map((company_map: any) => (
                <Select.Option key={company_map.id} value={company_map.id}>
                  {company_map.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <Tooltip title="Indica si este reto tiene algún comportamiento especial que lo diferencia del resto">
                Reto Especial <QuestionCircleOutlined color="#199479" />
              </Tooltip>
            }
            name="special_type"
            initialValue={"special_type"}
            // validateStatus={editChallengeError && "error"}
            rules={[
              {
                required: true,
                message: "El campo es obligatorio",
              },
            ]}
          >
            <Select defaultValue="none">
              <Option value="none">Ninguno</Option>
              <Option
                value="spending"
                disabled={
                  props.existingSpecialTypes.includes("spending") &&
                  selectedChallenge &&
                  selectedChallenge.special_type !== "spending"
                }
              >
                <Tooltip
                  title="Sólo puede haber un reto de Ahorro conseguido"
                  visible={showSpendingTooltip}
                  onVisibleChange={(visible) => {
                    if (
                      props.existingSpecialTypes.includes("spending") &&
                      selectedChallenge &&
                      selectedChallenge.special_type !== "spending"
                    ) {
                      setShowSpendingTooltip(visible);
                    }
                  }}
                >
                  Ahorro conseguido
                </Tooltip>
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Infografía"
            name="infographics"
            validateStatus={editChallengeError && "error"}
            // rules={[
            //   {
            //     required: true, message: 'El campo es obligatorio'
            //   }
            // ]}
          >
            <Upload
              type="select"
              name="infographics"
              onChange={({ fileList }) => setSelectedInfographics(fileList[0])}
              onRemove={() => setSelectedInfographics(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={
                selectedInfographics ? [selectedInfographics] : undefined
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Cambiar Infografía</Button>
              <Divider type="vertical" />
              {defaultInfographics && (
                <Avatar
                  src={defaultInfographics}
                  alt="icon"
                  shape="square"
                  size="large"
                />
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            name="text"
            label="Texto"
            rules={[
              {
                required: true,
                message: "El campo es obligatorio",
              },
            ]}
          >
            <TextArea value={text} rows={4} onChange={handleTextChange} />
          </Form.Item>

          {text.length <= 130 ? (
            `${text.length} / 130`
          ) : (
            <>
              <Typography.Text type="warning">
                En la primera pantalla del reto se mostrarán sólo 127
                caracteres.{" "}
              </Typography.Text>
              <Button onClick={togglePreview} type="link">
                {textPreview ? "Ocultar vista previa" : "Mostrar vista previa"}
              </Button>
            </>
          )}
          {textPreview && (
            <Typography.Paragraph>
              {text.substring(0, 127).trim().concat("...")}
            </Typography.Paragraph>
          )}

          <Form.Item name="second_text" label="Segundo texto">
            <TextArea rows={3} />
          </Form.Item>

          <Form.Item
            label="Imagen"
            name="image"
            validateStatus={editChallengeError && "error"}
          >
            <Upload
              type="select"
              name="image"
              onChange={({ fileList }) => setSelectedImage(fileList[0])}
              onRemove={() => setSelectedImage(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={selectedImage ? [selectedImage] : undefined}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Cambiar Imagen</Button>
              <Divider type="vertical" />
              {defaultImage && (
                <Avatar
                  src={defaultImage}
                  alt="icon"
                  shape="square"
                  size="large"
                />
              )}
            </Upload>
          </Form.Item>

          {isLinkAllowed && (
            <Form.Item
              label="Link"
              name="link"
              rules={[{ required: true, message: "El campo es obligatorio" }]}
              // validateStatus={addChallengeError && "error"}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default ChallengesEditModalForm;
