import api from "../../helpers/api";

const subscriptionsServices = {
  fetchAllCodes: () => {
    return api.get("/personalization_code/getall");
  },
  fetchAllCodeUsers: () => {
    return api.get("/personalization_code/allusers");
  },
  addCode: (
    code: string,
    company: string,
    type: string,
    duration: string,
    validCodeText: string,
    youHaveText: string,
    tariffRecommender: boolean,
    validUntil: string,
    premiumUsers: number,
    traffic_light_enabled: boolean,
    urlBanner: string,
    textBanner: string,
    buttonTextBanner: string
  ) => {
    return api.post("/personalization_code/add", {
      code,
      company,
      type,
      duration,
      validCodeText,
      youHaveText,
      tariffRecommender,
      validUntil,
      premiumUsers,
      traffic_light_enabled,
      urlBanner,
      textBanner,
      buttonTextBanner,
    });
  },
  editCode: (
    id: string,
    code: string,
    duration: number,
    company: string,
    type: string,
    validCodeText: string,
    youHaveText: string,
    tariffRecommender: boolean,
    validUntil: string,
    premiumUsers: number,
    traffic_light_enabled: boolean,
    urlBanner: string,
    textBanner: string,
    buttonTextBanner: string
  ) => {
    return api.put(`/personalization_code/${id}`, {
      code,
      duration,
      company,
      type,
      validCodeText,
      youHaveText,
      tariffRecommender,
      validUntil,
      premiumUsers,
      traffic_light_enabled,
      urlBanner,
      textBanner,
      buttonTextBanner,
    });
  },
  deleteCode: (id: string) => {
    return api.delete(`/personalization_code/${id}`);
  },
  editSubscription: ({
    userId,
    userType,
    userModelType,
    codeId,
    trialEndDate,
  }: any) => {
    return api.put(`/personalization/${userId}`, {
      userType,
      userModelType,
      codeId,
      trialEndDate,
    });
  },
  fetchAll: () => {
    return api.get("/admin/subscriptions");
  }
};

export default subscriptionsServices;
