import usersService from "./services";

import usersConstants from "./constants";
import store from "redux/rootStore";

const {
  SET_LOADING_USERS,
  SET_USERS,
  SET_HOME,
  SET_EDIT_OK,
  SET_SELECTED_ROW_KEYS,
  SET_DELETING_USER,
  SET_DELETE_USER_ERROR,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
  SET_LAST_QUERY
} = usersConstants;

export const setLoadingUsers = (isLoadingUsers: boolean) => ({
  type: SET_LOADING_USERS,
  isLoadingUsers,
});

const setUsers = (users: any) => ({
  type: SET_USERS,
  users,
});

const setReducerPageNumber = (number: any) => ({
  type: SET_PAGE_NUMBER,
  number,
});

const setReducerPageSize = (number: any) => ({
  type: SET_PAGE_SIZE,
  number,
});

const setLastQuery = (lastQuery: any)=>({
  type: SET_LAST_QUERY,
  lastQuery
})


export const setPageNumber = (number: any) =>{
  return (dispatch: any) => {
    dispatch(setReducerPageNumber(number))
    dispatch(lastQueryHandler())
  }
}

export const setPageSize = (number: any) =>{
  return (dispatch: any) => {
    dispatch(setReducerPageSize(number))
    dispatch(lastQueryHandler())
  }
}

const lastQueryHandler = ()=>{
  return (dispatch: any)=>{
    let {users} = store.getState()
    console.log(users.lastQuery.type)
    if(users.lastQuery.type === 'fetch-all'){
      dispatch(fetchAllUsers())
    }

    if(users.lastQuery.type === 'search'){
      dispatch(searchHandler(users.lastQuery.value))
    }
  }
}


export const fetchAllUsers = () => {
  return (dispatch: any) => {
    dispatch(setLoadingUsers(true));
    let {users} = store.getState()
    dispatch(setLastQuery({
      type: 'fetch-all'
    }))
    usersService.fetchAll({
      pageNumber: users.pageNumber,
      pageSize: users.pageSize
    }).then((res) => {
      dispatch(setLoadingUsers(false));
      return dispatch(setUsers({users: res.data.response.users, usersTotal: res.data.response.usersTotal}));
    });
  };
};

export const searchHandler = (searchString: string)=>{
  return (dispatch: any)=>{
    dispatch(setLoadingUsers(true))
    dispatch(setLastQuery({
      type: 'search',
      value: searchString
    }))
    let {users} = store.getState()
    usersService.searchUser(searchString, users.pageSize).then( (res)=>{
      dispatch(setLoadingUsers(false))
      return dispatch(setUsers({users: res.data.response.users, usersTotal: res.data.response.usersTotal}))
    })
  }
}

// NEW
const { CHECKING_HOME } = usersConstants;
const setCheckingHome = (isChecking: boolean) => ({
  type: CHECKING_HOME,
  isChecking,
});

const setHome = (home: any) => ({ type: SET_HOME, home });

export const fetchHome = (id: any) => {
  return async (dispatch: any) => {
    dispatch(setCheckingHome(true));
    try {
      const response = await usersService.fetchUserHose(id);
      if (response.data.response) {
        dispatch(setHome(response.data.response));
      }
      dispatch(setCheckingHome(false));
    } catch (e) {
      dispatch(setCheckingHome(false));
    }
  };
};

const { SET_EDITING_USER, SET_EDIT_ERROR } = usersConstants;
const setEditingUser = (isEditingUser: boolean) => ({
  type: SET_EDITING_USER,
  isEditingUser,
});

const setEditError = (editError: string | null) => ({
  type: SET_EDIT_ERROR,
  editError,
});

const setEditOk = (editOk: boolean) => ({
  type: SET_EDIT_OK,
  editOk,
});

export const editUser = (data: any) => {
  return (dispatch: any) => {
    dispatch(setEditingUser(true));
    dispatch(setEditError(null));
    usersService
      .editUser(
        data.id,
        data.full_name,
        data.email,
        data.city,
        data.address,
        data.postal_code,
        data.delivery_note
      )
      .then((res: any) => {
        dispatch(setEditingUser(false));
        dispatch(setEditOk(true));
      })
      .catch((err) => {
        if (
          // Messy
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setEditError(err.response.data.errors.message));
        } else {
          dispatch(setEditError("Ha ocurrido un error"));
        }
        dispatch(setEditingUser(false));
      });
  };
};

const selecteddRowKeys = (selectedRowKeys: any) => ({
  type: SET_SELECTED_ROW_KEYS,
  selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
  return (dispatch: any) => {
    return dispatch(selecteddRowKeys(selectedRowKeys));
  };
};

const setDeletingUser = (isDeletingUser: boolean) => ({
  type: SET_DELETING_USER,
  isDeletingUser,
});

const setDeleteUserError = (deleteUserError: any) => ({
  type: SET_DELETE_USER_ERROR,
  deleteUserError,
});

export const deleteUser =
  (user_id: string, callback?: () => any) => (dispatch: any) => {
    dispatch(setDeletingUser(true));
    dispatch(setDeleteUserError(null));
    usersService
      .deleteUser(user_id)
      .then(() => {
        dispatch(setDeletingUser(false));
        if (callback) callback();
      })
      .catch((e) => {
        dispatch(setDeletingUser(false));
        dispatch(setDeleteUserError(e));
      });
  };
