import api from '../../helpers/api';

const authServices = {
  fetchAll: (
    body: any
  ) => {
    return api.post('/admin/list_users',{
      ...body
    });
  },

  getAllUsers: () =>{
    return api.get('/admin/users/all')
  },

  fetchUserHose: (id: string) => {
    return api.get(`/admin/user_home/${id}`);
  },

  editUser: (
    id: any,
    full_name: any,
    email: any,
    city: any,
    address: any,
    postal_code: any,
    delivery_note: any
  ) => {
    return api.put('/admin/edit_user/' + id, {
      full_name,
      email,
      city,
      address,
      postal_code,
      delivery_note,
    });
  },


  searchUser: (searchString: string, pageSize: number)=>{
    return api.post(`/admin/searchUser/`, {searchString, pageSize})
  },

  deleteUser: (id: string) => {
    return api.delete(`/admin/user/${id}`);
  }
};

export default authServices;
