const publicationsConstants = {
  SET_NEW_PUBLICATION: "SET_NEW_PUBLICATION",
  SET_ADD_PUBLICATION_ERROR: "SET_ADD_PUBLICATION_ERROR",
  SET_LOADING_PUBLICATIONS: "SET_LOADING_PUBLICATIONS",
  SET_PUBLICATIONS: "SET_PUBLICATIONS",
  SET_SELECTED_PUBLICATIONS: "SET_SELECTED_PUBLICATIONS",
  SET_EDIT_PUBLICATION_ERROR: "SET_EDIT_PUBLICATION_ERROR",
  SET_EDITED_PUBLICATION: "SET_EDITED_PUBLICATION",
  SET_DELETING_PUBLICATION: "SET_DELETING_PUBLICATION",
  SET_CHANGING_PUBLICATION_VISIBILITY: "SET_CHANGING_PUBLICATION_VISIBILITY",
  SET_CHANGE_PUBLICATION_VISIBILITY_ERROR:
    "SET_CHANGE_PUBLICATION_VISIBILITY_ERROR",
  REMOVE_PUBLICATION: "REMOVE_PUBLICATION",
  SET_SORTING_PUBLICATIONS: "SET_SORTING_PUBLICATIONS",
  SET_SORT_PUBLICATIONS_ERROR: "SET_SORT_PUBLICATIONS_ERROR",
};

export default publicationsConstants;
