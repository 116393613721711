import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Select,
  Upload,
  Row,
  Col,
  Typography,
  Tooltip,
  InputNumber
} from "antd";

import { useSelector } from "react-redux";
import {
  PlusOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { SelectValue } from "antd/lib/select";

const { Option } = Select;
const { TextArea } = Input;

function ChallengeModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  successMessage?: string;
  image: string;
  existingSpecialTypes: string[];
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
    image,
  } = props;

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  const [visible, setVisible] = useState(false);
  const [isLinkAllowed, setIsLinkAllowed] = useState(false)
  const [form] = Form.useForm();
  const { addChallengeError } = useSelector((state: any) => state.challenges);
  const [selectedImage, setSelectedImage]: any = useState(null);
  const [selectedIcon, setSelectedIcon]: any = useState(null);
  const [selectedChallengeImage, setSelectedChallengeImage]: any =
    useState(null);
  const [selectedInfographics, setSelectedInfographics]: any = useState(null);
  const [text, setText] = useState("");
  const [textPreview, setTextPreview] = useState(false);

  const resetSelected = () => {
    setSelectedImage(null);
    setSelectedIcon(null);
    setSelectedChallengeImage(null);
    setSelectedInfographics(null);
    setText("");
    setTextPreview(false);
  };

  const openModal = () => setVisible(true);
  const onCancel = () => {
    setVisible(false);
    resetSelected();
    form.resetFields();
  };
  const onSuccess = () => {
    setVisible(false);
    resetSelected();
    form.resetFields();

    message.success(successMessage || "Exito");
  };

  const handleTypeSelect = (value: any)=>{
    if(value === 'text'){
      setIsLinkAllowed(false)
    }

    if(value === 'link'){
      setIsLinkAllowed(true)
    }
  }

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const {
          title,
          category,
          company,
          points,
          duration,
          // text,
          display_type,
          link,
          second_text,
          special_type,
        } = values;
        const formData = new FormData();

        formData.append("icon", selectedIcon.originFileObj);
        formData.append(
          "challenge_image",
          selectedChallengeImage.originFileObj
        );
        formData.append("title", title);
        formData.append("points", points);
        formData.append("duration", duration);
        formData.append("display_type", display_type);
        formData.append("category", category);
        formData.append("company", company);
        formData.append("infographics", selectedInfographics.originFileObj);
        formData.append("text", text);
        formData.append("second_text", second_text);
        formData.append("image", selectedImage?.originFileObj);
        formData.append("link", link);
        formData.append("special_type", special_type);

        onConfirm(formData, onSuccess);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleTextChange = (e: any) => {
    setText(e.target.value);
    if (e.target.value.length <= 130) {
      setTextPreview(false);
    }
  };

  const togglePreview = () => {
    if (textPreview) {
      setTextPreview(false);
    } else {
      setTextPreview(true);
    }
  };

  const [showSpendingTooltip, setShowSpendingTooltip] = useState(false);

  return (
    <>
      <Button
        type="primary"
        onClick={openModal}
        style={{
          color: "#199479",
          border: "1px solid #199479",
          borderRadius: "4px",
          backgroundColor: "transparent",
        }}
      >
        <PlusOutlined />
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        destroyOnClose
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onOk}
            loading={confirmLoading}
          >
            Crear
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <img src={image} alt="" />

          <Form.Item
            label="Icono"
            name="icon"
            rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
            // validateStatus={addChallengeError && "error"}
          >
            <Upload
              type="select"
              name="icon"
              onChange={({ fileList }) => setSelectedIcon(fileList[0])}
              onRemove={() => setSelectedIcon(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={selectedIcon ? [selectedIcon] : undefined}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Subir Icono</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Imagen del reto"
            name="challenge_image"
            rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
            // validateStatus={addChallengeError && "error"}
          >
            <Upload
              type="select"
              name="challenge_image"
              onChange={({ fileList }) =>
                setSelectedChallengeImage(fileList[0])
              }
              onRemove={() => setSelectedChallengeImage(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={
                selectedChallengeImage ? [selectedChallengeImage] : undefined
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Subir Imagen del Reto</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Titulo"
            name="title"
            rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Semillas"
                name="points"
                rules={[
                  { required: true, message: 'El campo es obligatorio'},
                  { type: 'number', message: 'Solo se pueden introducir numeros' },
                ]}
              >
                <InputNumber style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Duración"
                name="duration"
                rules={[
                  { required: true, message: 'El campo es obligatorio'},
                  { type: 'number', message: 'Solo se pueden introducir numeros' },
                ]}
              >
                <InputNumber style={{width: '100%'}}/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Tipo"
            name="display_type"
             rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
          >
            <Select onChange={(value)=>handleTypeSelect(value)}>
              <Option value="text">Texto</Option>
              <Option value="link">Link</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Categoría"
            name="category"
             rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
          >
            <Select >
              <Option value="Premium">Premium</Option>
              <Option value="Free">Free</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Empresa"
            name="company"
            // validateStatus={addChallengeError && "error"}
            initialValue="0"
          >
            <Select>
              <Select.Option value="0">Todas</Select.Option>
              {dataAllClients.map((company_map: any) => (
                <Select.Option key={company_map.id} value={company_map.id}>
                  {company_map.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>


          <Form.Item
            label="Infografía"
            name="infographics"
            rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
          >
            <Upload
              type="select"
              name="infographics"
              onChange={({ fileList }) => setSelectedInfographics(fileList[0])}
              onRemove={() => setSelectedInfographics(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={
                selectedInfographics ? [selectedInfographics] : undefined
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Subir Infografía</Button>
            </Upload>
          </Form.Item>

          <Form.Item name="text" label="Texto" style={{marginBottom: 0}}>
            <TextArea rows={4} value={text} onChange={handleTextChange} />
          </Form.Item>
            {text.length <= 130 ? (
              `${text.length} / 130`
            ) : (
              <>
                <Typography.Text type="warning">
                  En la primera pantalla del reto se mostrarán sólo 127
                  caracteres.{" "}
                </Typography.Text>
                <Button onClick={togglePreview} type="link">
                  {textPreview
                    ? "Ocultar vista previa"
                    : "Mostrar vista previa"}
                </Button>
              </>
            )}
            {textPreview && (
              <Typography.Paragraph>
                {text.substring(0, 127).trim().concat("...")}
              </Typography.Paragraph>
            )}

          <Form.Item name="second_text" label="Segundo texto">
            <TextArea rows={3} />
          </Form.Item>

          <Form.Item
            label="Imagen"
            name="image"
            // validateStatus={addChallengeError && "error"}
            rules={[
              {
                required: true, message: 'El campo es obligatorio'
              }
            ]}
          >
            <Upload
              type="select"
              name="image"
              onChange={({ fileList }) => setSelectedImage(fileList[0])}
              onRemove={() => setSelectedImage(null)}
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              multiple={false}
              beforeUpload={() => false}
              fileList={selectedImage ? [selectedImage] : undefined}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Subir Imagen</Button>
            </Upload>
          </Form.Item>

          {isLinkAllowed && <Form.Item
            label="Link"
            name="link"
            rules={[{required: true, message: 'El campo es obligatorio'}]}
            // validateStatus={addChallengeError && "error"}
          >
            <Input />
          </Form.Item>}
        </Form>
      </Modal>
    </>
  );
}

export default ChallengeModalForm;
