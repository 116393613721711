
const seedsConstants = {
    CREATE_PLAN: "CREATE_PLAN",
    SET_CREATING_PLAN: "SET_CREATING_PLAN",
    SET_UPDATING_PLAN: 'SET_UPDATING_PLAN',
    SET_CREATED_PLAN: "SET_CREATED_PLAN",
    SET_DELETING_PLAN: "SET_DELETING_PLAN",
    SET_PLANS: 'SET_PLANS',
    SET_LOADING_PLANS: 'SET_LOADING_PLANS',

    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_LOADING_HISTORY: 'SET_LOADING_HISTORY',
    SET_PLANS_HISTORY: 'SET_PLANS_HISTORY'
};

export default seedsConstants;