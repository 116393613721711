import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import {debounce} from 'lodash'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Table, Button, Dropdown, Input, Menu, Popconfirm, PageHeader } from 'antd'
import { DownOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

import update from "immutability-helper";

import PublicationsToggleVisibilityButton from '../../../../components/PublicationsToggleVisibilityButton'
import DraggableRow from "../../../../components/DraggableRow";
import PlansModalForm from './components/PlansModalForm'

import { findAllPlans, changePlanVisibility, sortPlans, deletePlan } from 'redux/seeds/action'
import { fetchAllClients } from "redux/clients";

const getCorrectName = (name: String) => {
  let reformattedName = ''
  
  if(name === 'discount') reformattedName = 'Dcto. Xx%'
  if(name === 'seeds') reformattedName = 'Semillas'
  if(name === 'others') reformattedName = 'Otros'
  
  return reformattedName
}

export default function Index() {
  const changeItemVisibility = (id: String, value: boolean) => {
    dispatch(changePlanVisibility(id, value));
  };

  const [companyFilters, setCompanyFilters] = useState([] as any[]);


    const columns = [
        {
          title: "Titulo",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "Tipo",
          dataIndex: "type",
          key: "type",
          render: (text: any, record: any) => {
            const obj = {
              children: getCorrectName(text)
            }
            return obj
          },
        },
        {
          title: "Semillas",
          dataIndex: "seeds",
          key: "seeds",
        },
        {
          title: "Valido hasta",
          dataIndex: "valid_until",
          key: "valid_until",
          render: (text: any, record: any) => {
            const obj = {
              children: !text ? '-' : text
            }
            return obj
          },
        },
        {
          title: "Publicado",
          dataIndex: "id",
          key: "is_shown",
          render: (id: String, record: any) => (
            <PublicationsToggleVisibilityButton
              current={record.is_shown}
              onClick={() => changeItemVisibility(id, !record.is_shown)}
            />
          ),
        },
        {
          title: "Empresa",
          dataIndex: "company_name",
          key: "company_name",
          filters: companyFilters,
          onFilter: (value: any, record: any) => {
          return record.company_name === value;
        },
        },
        {
          title: "Editar",
          dataIndex: "id",
          key: "edit",
          render: (text: String, record: any) => (
            <>
              <EditOutlined 
                onClick={()=>changeSelectedItem(text)}
              />
            </>
          ),
        },

        {
          title: "Eliminar",
          dataIndex: "id",
          key: "id",
          render: (text: String, record: any) => (
            <>
              <Popconfirm
                title="Confirmar"
                okText="Si"
                cancelText="No"
                onConfirm={() => dispatch(deletePlan(text))}
              >
                <DeleteOutlined
                  style={{ fontSize: "20px", color: "#47464E" }}
                />
              </Popconfirm>
            </>
          ),
        },
      ]
    
    const [visible, setVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false)
    const [item, setItem] = useState(false)

    const [data, setData] = useState([])
    const [pageSize, setPageSize] = useState(10)


    const dispatch = useDispatch()
    const {data: listData, loading} = useSelector((state:any) => state.seeds)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedToExport, setSelectedToExport] = useState([] as any[]);

    const [twice, setTwice] = useState(2);

    useEffect( ()=>{
      setData(listData)
      setSelectedToExport(listData)
    }, [listData])

    useEffect(() => {
      setCompanyFilters(getFilters("company_name", listData));
    }, [listData]);

    useEffect(()=>{
      dispatch(findAllPlans())
      dispatch(fetchAllClients(dispatch))
    }, [])
    
    const routes = [
    {
        path: "",
        breadcrumbName: "Planes",
    }];

    const { Search } = Input;

    function handleMenuClick(e: any) {
      setPageSize(e.key);
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="10">10</Menu.Item>
          <Menu.Item key="20">20</Menu.Item>
          <Menu.Item key="30">30</Menu.Item>
        </Menu>
      );

    const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
        const dragRow = data[dragIndex];
        setData(
          update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        );
      },
      [data]
    );

    const changeSelectedItem = (id: String) =>{
        let filteredItem: any = data.filter( (item: any) => item.id === id)
        setItem(filteredItem)
        setEditVisible(true)
    }
    
    const setIndexes = () => {
      return data.map(({ id }: { id: string }, index: number) => ({
        id,
        index,
      }));
    };

    useEffect(() => {
      if (!twice) {
        dispatch(sortPlans(setIndexes()));
      } else setTwice(twice - 1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onSearch = (value: any) => {
      const results: any = [];
      if (value) {
        listData.forEach((plan: any) => {
          const { title } = plan;
          const superString = `${title}`;
          if (superString.includes(value)) {
            results.push(plan);
          }
        });
        return setData(results);
      }
      if (value.trim() === "") {
        return setData(listData);
      }
    };

    const onFilter = (p: any, filters: any, s: any, { action }: any) => {
      if (action === "filter" && selectedRowKeys?.length === 0) {
        let exportData = [...data];
        for (let i in filters) {
          exportData = listData.filter(
            (entry: any) =>
              filters?.[i]?.reduce(
                (prev: boolean, filter: string) =>
                  prev ||
                  (filter !== "null"
                    ? entry[i] === filter
                    : entry[i] === undefined),
                false
              ) ?? true
          );
        }
        setCompanyFilters(getFilters("company_name", exportData));
        setSelectedToExport(exportData);
      }
    };

    const getFilters = (field: string, data: any[]) => {
      const filters: any[] = [];
      
      listData.forEach((entry: any) => {
        const existingFilterIndex = filters.findIndex((x) => {
          if (!entry[field] && x.value === null) return true;
          else return x.value === entry[field];
        });
  
        if (existingFilterIndex >= 0) {
          const quantity =
            parseInt(
              filters[existingFilterIndex].text
                .split(" ")
                [
                  filters[existingFilterIndex].text.split(" ").length - 1
                ].replaceAll(/\(*\)*/g, "")
            ) + 1;
          filters[existingFilterIndex].text = `${
            filters[existingFilterIndex].text.split(" ")[0]
          } (${quantity})`;
        } else {

          if (field === "company_name" && !entry[field]) {
            filters.push({ text: `Todas (1)`, value: entry[field] });
          } else {
            filters.push({ text: `${entry[field]} (1)`, value: entry[field] });
          }
        }
      });
      
      return filters;
    };

    return (
        <div>
            <PageHeader
                title="Planes"
                ghost={false}
                breadcrumb={{ routes }}
            />
            <div className="top_content_container">
                <Dropdown overlay={menu}>
                <Button>
                  {pageSize}  <DownOutlined />
                </Button>
                </Dropdown>

                <Search
                    placeholder="Buscar..."
                    onSearch={onSearch}
                    style={{ width: 200 }}
                />

                <PlansModalForm
                    openButtonText="Añadir"
                    modalTitle="Crear nuevo plan"
                    successMessage="Plan creado"
                    onConfirm={(e: any)=>console.log(e)}
                    confirmLoading={(e: any)=>console.log(e)}
                    setVisible={setVisible}
                    visible={visible}
                />
            </div>


            <PlansModalForm
              openButtonText="Editar"
              modalTitle="Editar el plan"
              successMessage="Plan editado"
              onConfirm={(e: any)=>console.log(e)}
              confirmLoading={(e: any)=>console.log(e)}
              setVisible={setEditVisible}
              visible={editVisible}
              plan={item}
            />
            
            <div className="content_container">
              <DndProvider backend={HTML5Backend}>
              <Table 
                  columns={columns}
                  dataSource={data}
                  pagination={{ pageSize }}
                  loading={loading}
                  onChange={onFilter}
                  rowKey='id'
                  onRow={(record, index) =>
                    ({
                      index,
                      moveRow,
                    } as any)
                  }
                  components={{
                    body: {
                      row: DraggableRow,
                    },
                  }}
                  />
                </DndProvider>
              </div>
        </div>
    )
}
