const challengesConstants = {
  SET_NEW_CHALLENGE: "SET_NEW_CHALLENGE",
  SET_ADD_CHALLENGE_ERROR: "SET_ADD_CHALLENGE_ERROR",
  SET_LOADING_CHALLENGES: "SET_LOADING_CHALLENGES",
  SET_CHALLENGES: "SET_CHALLENGES",
  SET_SELECTED_CHALLENGES: "SET_SELECTED_CHALLENGES",
  SET_EDIT_CHALLENGE_ERROR: "SET_EDIT_CHALLENGE_ERROR",
  SET_EDITED_CHALLENGE: "SET_EDITED_CHALLENGE",
  SET_DELETING_CHALLENGE: "SET_DELETING_CHALLENGE",
  SET_SORTING_CHALLENGES: "SET_SORTING_CHALLENGES",
  SET_SORT_CHALLENGES_ERROR: "SET_SORT_CHALLENGES_ERROR",
  REMOVE_CHALLENGE: "REMOVE_CHALLENGE",
  SET_LOADING_SPECIAL_CHALLENGES: "SET_LOADING_SPECIAL_CHALLENGES",
  SET_SPECIAL_CHALLENGES: "SET_SPECIAL_CHALLENGES",
  SET_SPECIAL_CHALLENGES_ERROR: "SET_SPECIAL_CHALLENGES_ERROR",
};

export default challengesConstants;
