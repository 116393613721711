import React from "react";

import { FlagOutlined, FlagFilled } from "@ant-design/icons";

const PublicationsToggleVisibilityButton = ({
  current,
  onClick,
}: {
  current: boolean;
  onClick: () => void;
}) => {
  return (
    <>
      {current ? (
        <FlagFilled
          style={{ fontSize: "20px", color: "#199479" }}
          onClick={onClick}
        />
      ) : (
        <FlagOutlined
          style={{ fontSize: "20px", color: "#111111" }}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default PublicationsToggleVisibilityButton;
