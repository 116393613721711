import api from "../../helpers/api";

const LanguageService = {
  fetchAll: async () => {
    return await api.get("/language/findAll");
  },
  createLanguage: async (formData: FormData) => {
    return await api.post("/language/create", formData);
  },
  updateLanguage: async (id: string, formData: FormData) => {
    return await api.put("/language/update/" + id, formData);
  },
  deleteLanguage: async (id: string) => {
    return await api.delete("/language/delete/" + id);
  },
};

export default LanguageService;
