import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import clientService from "./services";

const clientSlicer = createSlice({
  name: "clients",
  initialState: {
    // All Clients
    loadingAllClients: false,
    successAllClients: false,
    errorAllClients: false,
    dataAllClients: [],
    // Create Client
    loadingCreateClient: false,
    successCreateClient: false,
    errorCreateClient: false,
    // Update Client
    loadingUpdateClient: false,
    successUpdateClient: false,
    errorUpdateClient: false,
    // Delete Client
    loadingDeleteClient: false,
    successDeleteClient: false,
    errorDeleteClient: false,
  },
  reducers: {
    // All Clients
    setLoadingAllClients: (state) => {
      state.loadingAllClients = true;
    },
    setSuccessAllClients: (state, action) => {
      state.successAllClients = action.payload.response;
      state.dataAllClients = action.payload.data;
      state.errorAllClients = false;
      state.loadingAllClients = false;
    },
    setRejectAllClients: (state, action) => {
      state.successAllClients = false;
      state.errorAllClients = action.payload.response;
      state.loadingAllClients = false;
    },
    // Create Client
    setLoadingCreateClient: (state) => {
      state.loadingCreateClient = true;
    },
    setSuccessCreateClient: (state, action) => {
      state.successCreateClient = action.payload.response;
      state.errorCreateClient = false;
      state.loadingCreateClient = false;
    },
    setRejectCreateClient: (state, action) => {
      state.successCreateClient = false;
      state.errorCreateClient = action.payload.response;
      state.loadingCreateClient = false;
    },
    // Update Client
    setLoadingUpdateClient: (state) => {
      state.loadingUpdateClient = true;
    },
    setSuccessUpdateClient: (state, action) => {
      state.successUpdateClient = action.payload.response;
      state.errorUpdateClient = false;
      state.loadingUpdateClient = false;
    },
    setRejectUpdateClient: (state, action) => {
      state.successUpdateClient = false;
      state.errorUpdateClient = action.payload.response;
      state.loadingUpdateClient = false;
    },
    // Delete Client
    setLoadingDeleteClient: (state) => {
      state.loadingDeleteClient = true;
    },
    setSuccessDeleteClient: (state, action) => {
      state.successDeleteClient = action.payload.response;
      state.errorDeleteClient = false;
      state.loadingDeleteClient = false;
    },
    setRejectDeleteClient: (state, action) => {
      state.successDeleteClient = false;
      state.errorDeleteClient = action.payload.response;
      state.loadingDeleteClient = false;
    },
  },
});

export const {
  setLoadingAllClients,
  setSuccessAllClients,
  setRejectAllClients,
  setLoadingCreateClient,
  setSuccessCreateClient,
  setRejectCreateClient,
  setLoadingUpdateClient,
  setSuccessUpdateClient,
  setRejectUpdateClient,
  setLoadingDeleteClient,
  setSuccessDeleteClient,
  setRejectDeleteClient,
} = clientSlicer.actions;

export default clientSlicer;

export const fetchAllClients = (dispatch: any) => {
  return async () => {
    dispatch(setLoadingAllClients());
    const response = await clientService
      .fetchAll()
      .then((res) => {
        const responsePayload = {
          data: res.data,
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessAllClients(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectAllClients(responsePayload));
      });
  };
};

export const createClient = (dispatch: any, formData: FormData) => {
  return async () => {
    dispatch(setLoadingCreateClient());
    await clientService
      .createClient(formData)
      .then((res) => {
        const responsePayload = {
          data: res.data,
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessCreateClient(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectCreateClient(responsePayload));
      });
  };
};

export const updateClient = (dispatch: any, id: string, formData: FormData) => {
  return async () => {
    dispatch(setLoadingUpdateClient());
    await clientService
      .updateClient(id, formData)
      .then((res) => {
        const responsePayload = {
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessUpdateClient(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectUpdateClient(responsePayload));
      });
  };
};

export const deleteClient = (dispatch: any, id: string) => {
  return async () => {
    dispatch(setLoadingDeleteClient());
    await clientService
      .deleteClient(id)
      .then((res) => {
        const responsePayload = {
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessDeleteClient(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectDeleteClient(responsePayload));
      });
  };
};
