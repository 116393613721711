import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import style from "./styles.module.scss";
import {
  CameraFilled,
  PlusOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import defaultIcon from "./../../assets/defaults_icons/person_default.png";
import defaultLogo from "./../../assets/defaults_icons/logo_default.png";
import Icono from "components/MenuItemImg";
import { HuePicker, TwitterPicker } from "react-color";
import Block from "react-color/lib/components/block/Block";
import Hue from "react-color/lib/components/block/Block";
import { v4 as uuidv4 } from "uuid";
import {
  createClient,
  deleteClient,
  fetchAllClients,
  updateClient,
} from "redux/clients";
import { Link } from "react-router-dom";
import {
  createLanguage,
  deleteLanguage,
  fetchAllLanguage,
  updateLanguage,
} from "redux/languages";

interface Props {
  modalTitle: string;
  openButtonText: string;
  visible: boolean;
  setVisible: any;
  client: any;
  setClient: any;
}

const ClientModalForm = ({
  modalTitle,
  openButtonText,
  visible,
  setVisible,
  client,
  setClient,
}: Props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const {
    loadingCreateClient,
    successCreateClient,
    errorCreateClient,
    loadingUpdateClient,
    successUpdateClient,
    errorUpdateClient,
    loadingDeleteClient,
    successDeleteClient,
    errorDeleteClient,
  } = useSelector((state: any) => state.clients);

  const {
    loadingAllLanguage,
    successAllLanguage,
    errorAllLanguage,
    dataAllLanguage,
    successUpdateLanguage,
    successCreateLanguage,
    successDeleteLanguage,
  } = useSelector((state: any) => state.languages);

  useEffect(() => {
    if (successCreateClient) {
      setVisible(false);
      message.success("Cliente creado con éxito");
      dispatch(fetchAllClients(dispatch));
      resetAll();
    }
    if (errorCreateClient) {
      message.error(errorCreateClient.message);
    }
  }, [successCreateClient, errorCreateClient]);

  useEffect(() => {
    dispatch(fetchAllLanguage(dispatch));
  }, [
    successUpdateLanguage,
    successCreateLanguage,
    successDeleteLanguage,
    successUpdateClient,
    successDeleteClient,
    successCreateClient,
  ]);

  useEffect(() => {
    if (successUpdateClient) {
      setVisible(false);
      message.success("Cliente actualizado con éxito");
      dispatch(fetchAllClients(dispatch));
      resetAll();
    }
    if (errorUpdateClient) {
      setVisible(false);
      message.success("Cliente actualizado con éxito");
      dispatch(fetchAllClients(dispatch));
      resetAll();
    }
  }, [successUpdateClient, errorUpdateClient]);

  useEffect(() => {
    if (successDeleteClient) {
      setVisible(false);
      message.success("Cliente eliminado con éxito");
      dispatch(fetchAllClients(dispatch));
      resetAll();
    }
    if (errorDeleteClient) {
      setVisible(false);
      message.error("Error al eliminar el cliente");
    }
  }, [successDeleteClient, errorDeleteClient]);

  const openModal = () => setVisible(true);

  const [companyUuid, setCompanyUuid] = useState(uuidv4());

  //fields
  const iconInputFile = useRef<HTMLInputElement>(null);
  const [icon, setIcon] = useState<any>();
  const [tempIcon, setTempIcon]: any = useState(null);
  const logoInputFile = useRef<HTMLInputElement>(null);
  const [logo, setLogo] = useState<any>();
  const [tempLogo, setTempLogo]: any = useState(null);
  const [textsColor, setTextsColor]: any = useState("#199479");
  const [graphicsColor, setGraphicsColor]: any = useState("#199479");
  const [defaultLanguage, setDefaultLanguage]: any = useState(
    client?.default_language || ""
  );
  const [enableLanguages, setEnableLanguages]: any = useState(
    client?.is_language_enable || false
  );
  const [languageFileList, setLanguageFileList]: any = useState([]);

  const handleIconChange = (event: any) => {
    setTempIcon(URL.createObjectURL(event.target?.files[0]));
    setIcon(event.target.files[0]);
    event.target.value = null;
  };
  const handleLogoChange = (event: any) => {
    setTempLogo(URL.createObjectURL(event.target?.files[0]));
    setLogo(event.target.files[0]);
    event.target.value = null;
  };

  const resetAll = () => {
    setClient(null);
    setIcon(null);
    setLogo(null);
    setTempIcon(null);
    setTempLogo(null);
    setEnableLanguages(false);
    setLanguageFileList([]);
    form.resetFields();
    setTextsColor("#199479");
    setGraphicsColor("#199479");
    setCompanyUuid(uuidv4());
    setDefaultLanguage("");
  };

  const resetCustomApparence = () => {
    setTempLogo(null);
    form.resetFields(["icon", "logo"]);
    setTextsColor("#199479");
    setGraphicsColor("#199479");
  };

  useEffect(() => {
    if (client) {
      form.setFieldsValue({
        company_name: client?.company_name,
        cif: client?.cif,
        address: client?.address === 'undefined' ? '' : client?.address,
        postal_code: client?.postal_code,
        municipality: client?.municipality === 'undefined' ? '' : client?.municipality,
        web: client?.web === 'undefined' ? '' : client?.web,
        sector: client?.sector === 'undefined' ? '' : client?.sector,
        url_market: client?.url_market  === 'undefined' ? '' : client?.sector,
      });
      setIcon(client?.icon);
      setLogo(client?.logo);
      setTempIcon(client?.icon);
      setTempLogo(client?.logo);
      setTextsColor(client?.texts_color);
      setGraphicsColor(client?.graphics_color);
      setEnableLanguages(client?.is_language_enable || false);
      setDefaultLanguage(client?.default_language);
    }
  }, [client]);

  const handleCancel = () => {
    setVisible(false);
    resetAll();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const {
          company_name,
          cif,
          address,
          postal_code,
          municipality,
          telephone,
          web,
          sector,
          url_market,
        } = values;

        const formData = new FormData();
        formData.append("icon", icon);
        formData.append("company_name", company_name);
        formData.append("cif", cif);
        formData.append("address", address);
        formData.append("postal_code", postal_code);
        formData.append("municipality", municipality);
        formData.append("telephone", telephone);
        formData.append("web", web);
        formData.append("sector", sector);
        formData.append("url_market", url_market);
        formData.append("logo", logo);
        formData.append("texts_color", textsColor);
        formData.append("graphics_color", graphicsColor);
        formData.append("is_language_enable", enableLanguages);
        formData.append("default_language", defaultLanguage);
        formData.append("uuid", companyUuid);
        
        if (client) {
          dispatch(updateClient(dispatch, client.id, formData));
        } else {
          dispatch(createClient(dispatch, formData));
        }
      })
      .catch((info) => {
        message.error("Verificar campos obligarios");
      });
  };

  const handleDeleteClient = () => {
    dispatch(deleteClient(dispatch, client.id));
  };

  return (
    <>
      <Button className={style.button} type="primary" onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <div className={style.flex_between}>
            <div>
              {client != null && (
                <Popconfirm
                  title={`¿Estás seguro de eliminar el cliente?`}
                  okText="Si"
                  cancelText="No"
                  onConfirm={() => handleDeleteClient()}
                >
                  <Button
                    key="submit"
                    loading={loadingCreateClient}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Eliminar
                  </Button>
                </Popconfirm>
              )}
            </div>
            <div>
              <Button key="back" type="primary" onClick={handleCancel}>
                Cancelar
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loadingCreateClient}
                onClick={handleOk}
              >
                {client != null ? "Editar" : "Crear"}
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={style.min_margin}>
          <input
            type="file"
            name="icon"
            ref={iconInputFile}
            accept="image/png, image/jpg, image/jpeg, image/bmp"
            style={{ display: "none" }}
            onChange={(event) => handleIconChange(event)}
          />
          <div>
            <img
              src={tempIcon || defaultIcon}
              alt="Icon"
              className={style.logo + " " + style.card_image}
            />
          </div>
          <div className={style.min_margin}>
            <div>Icono</div>
          </div>
          <Button
            onClick={() => {
              iconInputFile.current!.click();
            }}
          >
            Subir icono del cliente
          </Button>
        </div>
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Nombre de la empresa"
            name="company_name"
            rules={[
              { required: true, message: "Por favor introduce un nombre" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="CIF"
            name="cif"
            rules={[
              { required: true, message: "Por favor introduce un codigo cif" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Domicilio" name="address">
            <Input />
          </Form.Item>

          <div className={style.two_column}>
            <Form.Item
              label="Codigo postal"
              name="postal_code"
              initialValue="0"
            >
              <Input />
            </Form.Item>

            <Form.Item label="Municipio" name="municipality">
              <Input />
            </Form.Item>
          </div>

          <Form.Item label="Web" name="web">
            <Input />
          </Form.Item>

          <Form.Item label="Sector/Actividad" name="sector" initialValue="0">
            <Select defaultValue={"0"}>
              <Select.Option value="0">Ninguno</Select.Option>
              <Select.Option value="1">Automoción y transporte</Select.Option>
              <Select.Option value="2">
                Químico, farmacéutico y sanitario
              </Select.Option>
              <Select.Option value="3">
                Textil, calzado y confección
              </Select.Option>
              <Select.Option value="4">Construcción</Select.Option>
              <Select.Option value="5">
                Alimentación y restauración
              </Select.Option>
              <Select.Option value="6">
                Siderurgia, metalurgia, fabricación y comercialización de
                maquinaria
              </Select.Option>
              <Select.Option value="7">Servicios a empresas</Select.Option>
              <Select.Option value="8">
                Papel, cartón, artes gráficas, edición
              </Select.Option>
              <Select.Option value="9">
                Servicios recreativos, culturales, ocio
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tienda(url)" name="url_market">
            <Input />
          </Form.Item>
        </Form>
        <div className={style.margin_top_bottom}>
          <div>Datos de la App</div>
          <Divider />
        </div>
        <div className={style.flex_between}>
          <Form.Item
            label="Habilitar idiomas"
            name="is_language_enable"
            initialValue={enableLanguages}
          >
            <Switch
              onChange={(value) => setEnableLanguages(value)}
              checked={enableLanguages}
            />
          </Form.Item>

          <Link to="/files/sample_languages.json" target="_blank" download>
            Descargar plantilla
          </Link>
        </div>
        {enableLanguages && (
          <div>
            <div>
              <Form.Item name="default_language">
                <Select
                  onChange={(value) => {
                    setDefaultLanguage(value);
                  }}
                  defaultValue={defaultLanguage}
                >
                  {dataAllLanguage &&
                    dataAllLanguage.length > 0 &&
                    dataAllLanguage.map((language: any) => {
                      if (
                        language.company.length == 0 ||
                        (client && language.company == client.id) ||
                        language.company == "temp" + companyUuid
                      ) {
                        return (
                          <Select.Option value={language.id}>
                            {language.language}
                          </Select.Option>
                        );
                      }
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              {dataAllLanguage &&
                dataAllLanguage.length > 0 &&
                dataAllLanguage.map((language: any) => {
                  if (
                    language.company.length == 0 ||
                    (client && language.company == client.id) ||
                    language.company == "temp" + companyUuid
                  ) {
                    return (
                      <div
                        className={
                          style.flex_between + " " + style.margin_top_bottom_10
                        }
                      >
                        <div>
                          <text>{language.language}</text>
                        </div>
                        <div>json</div>
                        <div>
                          <Button
                            onClick={() => {
                              dispatch(deleteLanguage(dispatch, language.id));
                            }}
                          >
                            <DeleteOutlined style={{ fontSize: "20px" }} />
                          </Button>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            <div className={style.center}>
              <Upload
                name="languages"
                accept="application/JSON"
                onChange={(file: any) => {
                  const formData = new FormData();
                  formData.append("uuid", companyUuid);
                  formData.append("language", file.file.name.split('.')[0]);
                  formData.append("languages", file.file);
                  dispatch(createLanguage(dispatch, formData));
                }}
                beforeUpload={() => false}
                showUploadList={false}
                fileList={languageFileList}
              >
                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
              </Upload>
            </div>
          </div>
        )}
        <div
          className={style.center + " " + style.margin_top_bottom}
          style={{ backgroundColor: "#e9e9e9" }}
        >
          <div>Logo</div>
        </div>
        <div className={style.margin_right_left}>
          <input
            type="file"
            name="logo"
            ref={logoInputFile}
            accept="image/png, image/jpg, image/jpeg, image/bmp"
            style={{ display: "none" }}
            onChange={(event) => handleLogoChange(event)}
          />
          <div
            className={style.card}
            onClick={() => {
              logoInputFile.current!.click();
            }}
          >
            <img
              src={tempLogo || defaultLogo}
              alt="Logo"
              className={style.logo + " " + style.card_image}
            />
          </div>
        </div>
        <div
          className={style.center + " " + style.margin_top_bottom}
          style={{ backgroundColor: "#e9e9e9" }}
        >
          <div>Botónes, íconos y Títulos</div>
        </div>
        <div className={style.margin_right_left}>
          <div className={style.color_picker}>
            <TwitterPicker
              triangle="hide"
              color={textsColor}
              onChange={(color: any) => {
                setTextsColor(color.hex);
              }}
            />
            <div className={style.color_div_preview}>
              <div
                className={style.color_preview}
                style={{ backgroundColor: textsColor }}
              />
              <HuePicker
                color={textsColor}
                onChange={(color: any) => {
                  setTextsColor(color.hex);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={style.center + " " + style.margin_top_bottom}
          style={{ backgroundColor: "#e9e9e9" }}
        >
          <div>Color secundario de gráficos</div>
        </div>
        <div className={style.margin_right_left}>
          <div className={style.color_picker}>
            <TwitterPicker
              triangle="hide"
              color={graphicsColor}
              onChange={(color: any) => {
                setGraphicsColor(color.hex);
              }}
            />
            <div className={style.color_div_preview}>
              <div
                className={style.color_preview}
                style={{ backgroundColor: graphicsColor }}
              />
              <HuePicker
                color={graphicsColor}
                onChange={(color: any) => {
                  setGraphicsColor(color.hex);
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.center}>
          <Button
            key="submit"
            type="primary"
            className={style.button_black}
            onClick={resetCustomApparence}
          >
            Restablecer apariencia original
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ClientModalForm;
