import React, { useState, useEffect } from "react";
import xlsx from "json-as-xlsx";

const XLSXLink = ({
  children,
  style = {},
  data,
  filename,
  sheet = "Hoja",
  headers,
}: {
  children: any;
  style?: Object;
  data: any[];
  filename: string;
  sheet?: string;
  headers: any[];
}) => {
  const [settings, setSettings] = useState({});
  const [formattedData, setFormattedData] = useState([
    {
      sheet: "",
      columns: [] as any[],
      content: [] as any[],
    },
  ]);

  useEffect(() => {
    setSettings({
      fileName: filename,
      extraLength: 3,
      writeOptions: {},
    });
  }, [filename]);

  useEffect(() => {
    const formattedHeaders = headers.map((header) => {
      if (header.key && header.value === undefined) {
        return { ...header, value: header.key };
      } else return header;
    });
    setFormattedData([
      {
        sheet,
        columns: formattedHeaders,
        content: data,
      },
    ]);
  }, [data, sheet, headers]);

  const onClick = () => {
    xlsx(formattedData, settings);
  };

  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "inherit",
        border: "none",
        outline: "none",
        cursor: "pointer",
        padding: "none",
        margin: "none",
      }}
    >
      {children}
    </button>
  );
};

export default XLSXLink;
