import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import LanguageService from "./services";

const Languagelicer = createSlice({
  name: "Language",
  initialState: {
    // All Languages
    loadingAllLanguage: false,
    successAllLanguage: false,
    errorAllLanguage: false,
    dataAllLanguage: [],
    // Create Language
    loadingCreateLanguage: false,
    successCreateLanguage: false,
    errorCreateLanguage: false,
    // Update Language
    loadingUpdateLanguage: false,
    successUpdateLanguage: false,
    errorUpdateLanguage: false,
    // Delete Language
    loadingDeleteLanguage: false,
    successDeleteLanguage: false,
    errorDeleteLanguage: false,
  },
  reducers: {
    // All Language
    setLoadingAllLanguage: (state) => {
      state.loadingAllLanguage = true;
    },
    setSuccessAllLanguage: (state, action) => {
      state.successAllLanguage = action.payload.response;
      state.dataAllLanguage = action.payload.data.data;
      state.errorAllLanguage = false;
      state.loadingAllLanguage = false;
    },
    setRejectAllLanguage: (state, action) => {
      state.successAllLanguage = false;
      state.errorAllLanguage = action.payload.response;
      state.loadingAllLanguage = false;
    },
    // Create Language
    setLoadingCreateLanguage: (state) => {
      state.loadingCreateLanguage = true;
    },
    setSuccessCreateLanguage: (state, action) => {
      state.successCreateLanguage = action.payload.response;
      state.errorCreateLanguage = false;
      state.loadingCreateLanguage = false;
    },
    setRejectCreateLanguage: (state, action) => {
      state.successCreateLanguage = false;
      state.errorCreateLanguage = action.payload.response;
      state.loadingCreateLanguage = false;
    },
    // Update Language
    setLoadingUpdateLanguage: (state) => {
      state.loadingUpdateLanguage = true;
    },
    setSuccessUpdateLanguage: (state, action) => {
      state.successUpdateLanguage = action.payload.response;
      state.errorUpdateLanguage = false;
      state.loadingUpdateLanguage = false;
    },
    setRejectUpdateLanguage: (state, action) => {
      state.successUpdateLanguage = false;
      state.errorUpdateLanguage = action.payload.response;
      state.loadingUpdateLanguage = false;
    },
    // Delete Language
    setLoadingDeleteLanguage: (state) => {
      state.loadingDeleteLanguage = true;
    },
    setSuccessDeleteLanguage: (state, action) => {
      state.successDeleteLanguage = action.payload.response;
      state.errorDeleteLanguage = false;
      state.loadingDeleteLanguage = false;
    },
    setRejectDeleteLanguage: (state, action) => {
      state.successDeleteLanguage = false;
      state.errorDeleteLanguage = action.payload.response;
      state.loadingDeleteLanguage = false;
    },
  },
});

export const {
  setLoadingAllLanguage,
  setSuccessAllLanguage,
  setRejectAllLanguage,
  setLoadingCreateLanguage,
  setSuccessCreateLanguage,
  setRejectCreateLanguage,
  setLoadingUpdateLanguage,
  setSuccessUpdateLanguage,
  setRejectUpdateLanguage,
  setLoadingDeleteLanguage,
  setSuccessDeleteLanguage,
  setRejectDeleteLanguage,
} = Languagelicer.actions;

export default Languagelicer;

export const fetchAllLanguage = (dispatch: any) => {
  return async () => {
    dispatch(setLoadingAllLanguage());
    const response = await LanguageService.fetchAll()
      .then((res) => {
        const responsePayload = {
          data: res.data,
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessAllLanguage(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectAllLanguage(responsePayload));
      });
  };
};

export const createLanguage = (dispatch: any, formData: FormData) => {
  return async () => {
    dispatch(setLoadingCreateLanguage());
    await LanguageService.createLanguage(formData)
      .then((res) => {
        const responsePayload = {
          data: res.data,
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessCreateLanguage(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectCreateLanguage(responsePayload));
      });
  };
};

export const updateLanguage = (
  dispatch: any,
  id: string,
  formData: FormData
) => {
  return async () => {
    dispatch(setLoadingUpdateLanguage());
    await LanguageService.updateLanguage(id, formData)
      .then((res) => {
        const responsePayload = {
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessUpdateLanguage(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectUpdateLanguage(responsePayload));
      });
  };
};

export const deleteLanguage = (dispatch: any, id: string) => {
  return async () => {
    dispatch(setLoadingDeleteLanguage());
    await LanguageService.deleteLanguage(id)
      .then((res) => {
        const responsePayload = {
          response: { status: res.status, statusText: res.statusText },
        };
        dispatch(setSuccessDeleteLanguage(responsePayload));
      })
      .catch((res) => {
        const responsePayload = {
          response: {
            status: res.response.status,
            message: res.response.data.errors.message,
          },
        };
        dispatch(setRejectDeleteLanguage(responsePayload));
      });
  };
};
