import React, { useCallback, useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  Input,
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  message,
} from "antd";
import { DownOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import moment from "moment";

import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import PublicationsModalForm from "../../../../components/PublicationsModalForm";
import PublicationsEditModalForm from "../../../../components/PublicationsEditModalForm";
import PublicationsToggleVisibilityButton from "../../../../components/PublicationsToggleVisibilityButton";
import update from "immutability-helper";

import {
  fetchAllPublications,
  newPublication,
  editPublication,
  deletePublication,
  changePublicationVisibility,
  sortPublications,
} from "redux/publications/actions";
import { fetchAllClients } from "redux/clients";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableRow from "components/DraggableRow";

function Light() {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [once, setOnce] = useState(true);

  const [displayData, setDisplayData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [companyFilters, setCompanyFilters] = useState([] as any[]);
  const [selectedToExport, setSelectedToExport] = useState([] as any[]);
  const [twice, setTwice] = useState(2);

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllPublications("light"));
  }, [dispatch]);

  const {
    isNewPublication,
    publications,
    isLoadingPublications,
    isEditedPublication,
    isChangingPublicationVisibility,
  } = useSelector((state: any) => state.publications);

  const [data, setData] = useState(publications || []);

  
  useEffect( ()=>{
    setData(publications)
  }, [publications])
  
  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  useEffect(() => {
    dispatch(fetchAllClients(dispatch));
  }, [dispatch]);

  useEffect(() => {
    for (let dataChallenge of publications) {
      if (
        dataChallenge.company != undefined &&
        dataChallenge.company != null &&
        dataChallenge.company != ""
      ) {
        var newCompany = "0";
        for (let client of dataAllClients) {
          if (client.id == dataChallenge.company) {
            newCompany = client.company_name;
            break;
          }
        }
        dataChallenge.company = newCompany;
      }
    }
    const sortedPublications = [...publications];
    sortedPublications.sort((a: any, b: any) => {
      if (a.is_shown && b.is_shown) {
        if (a.index == b.index) {
          return moment(b.updated_at).diff(moment(a.updated_at));
        } else if (a.index < b.index) return -1;
        else if (a.index > b.index) return 1;
        else return 0;
      } else if (a.is_shown) return -1;
      else if (b.is_shown) return 1;
      else if (!a.is_shown && !b.is_shown) {
        if (a.index == b.index) {
          return moment(b.updated_at).diff(moment(a.updated_at));
        } else if (a.index < b.index) return -1;
        else if (a.index > b.index) return 1;
        else return 0;
      } else return 0;
    });

    setData(sortedPublications);
  }, [publications]);

  useEffect(() => {
    if (!isChangingPublicationVisibility && !once) {
      dispatch(fetchAllPublications("light"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangingPublicationVisibility]);

  const createItem = (formData: FormData, callback: any) => {
    formData.append("type", "light");
    dispatch(newPublication(formData, "light", callback));
  };

  const editItem = (id: String, formData: FormData, callback: any) => {
    formData.append("type", "light");
    dispatch(editPublication(id, formData, "light", callback));
  };

  const deleteItem = (id: String) => {
    dispatch(
      deletePublication(id, () => {
        message.success("Publicacion borrada con exito");
      })
    );
  };

  const changeItemVisibility = (id: String, value: boolean) => {
    dispatch(changePublicationVisibility(id, value));
  };

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      publications.forEach((publication: any) => {
        const { title, author } = publication;
        const superString = `${title},${author}`;
        if (superString.includes(value)) {
          results.push(publication);
        }
      });
      return setData(results);
    }
    if (value.trim() === "") {
      return setData(publications);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const onFilter = (p: any, filters: any, s: any, { action }: any) => {
    if (action === "filter" && selectedRowKeys?.length === 0) {
      let exportData = [...data];

      for (let i in filters) {
        exportData = exportData.filter(
          (entry) =>
            filters?.[i]?.reduce(
              (prev: boolean, filter: string) =>
                prev ||
                (filter !== "null"
                  ? entry[i] === filter
                  : entry[i] === undefined),
              false
            ) ?? true
        );
      }
      console.log(filters, exportData);
      setCompanyFilters(getFilters("company", exportData));
      // Translate License code type
      setSelectedToExport(
        exportData.map((entry: any) =>
          entry.code_type === "License"
            ? { ...entry, code_type: "Licencia" }
            : entry
        )
      );
    }
  };

  const getFilters = (field: string, data: any[]) => {
    const filters: any[] = [];

    data.forEach((entry) => {
      const existingFilterIndex = filters.findIndex((x) => {
        if (!entry[field] && x.value === null) return true;
        else return x.value === entry[field];
      });

      if (existingFilterIndex >= 0) {
        const quantity =
          parseInt(
            filters[existingFilterIndex].text
              .split(" ")
              [
                filters[existingFilterIndex].text.split(" ").length - 1
              ].replaceAll(/\(*\)*/g, "")
          ) + 1;
        filters[existingFilterIndex].text = `${
          filters[existingFilterIndex].text.split(" ")[0]
        } (${quantity})`;
      } else {
        if (field === "company" && entry[field] === "0") {
          filters.push({ text: `Todas (1)`, value: entry[field] });
        } else if (!entry[field]) {
          filters.push({ text: `Ninguno (1)`, value: null });
        } else {
          filters.push({ text: `${entry[field]} (1)`, value: entry[field] });
        }
      }
    });

    return filters;
  };

  useEffect(() => {
    setDisplayData(data);
    setSelectedToExport(data);
  }, [data]);

  useEffect(() => {
    setCompanyFilters(getFilters("company", displayData));
  }, [displayData]);

  const setIndexes = () => {
    return data.map(({ id }: { id: string }, index: number) => ({
      id,
      index,
    }));
  };

  useEffect(() => {
    if (!twice) {
      dispatch(sortPublications(setIndexes()));
    } else setTwice(twice - 1);
  }, [data]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "",
      breadcrumbName: "Publicaciones",
    },
    {
      path: "luz",
      breadcrumbName: "Luz",
    },
  ];

  if (once) setOnce(false);

  return (
    <div>
      <PageHeader
        title="Publicaciones de luz"
        ghost={false}
        breadcrumb={{ routes }}
      />

      <div className="top_content_publications">
        <Dropdown overlay={menu}>
          <Button>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>

        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      <div style={{display: 'flex', gap: '1em' }}>
        <Button
        style={{
          color: "#199479",
          border: "1px solid #199479",
          borderRadius: "4px",
          backgroundColor: "transparent",
        }}
        onClick={()=>dispatch(fetchAllPublications('light'))}>
          Refrescar
          </Button>
        <PublicationsModalForm
          openButtonText="Añadir"
          modalTitle="Crear nueva publicación"
          successMessage="Publicación creada"
          onConfirm={createItem}
          confirmLoading={isNewPublication}
        />
        </div>
      </div>
      <div className="content_container">
        <DndProvider backend={HTML5Backend}>
          <Table
            components={{
              body: {
                row: DraggableRow,
              },
            }}
            onRow={(record, index) =>
              ({
                index,
                moveRow,
              } as any)
            }
            dataSource={displayData}
            loading={isLoadingPublications}
            pagination={{ pageSize }}
            rowKey="id"
            onChange={onFilter}
            columns={[
              {
                title:(
                  <div className="">
                    Like (<LikeOutlined />)
                  </div>
                ),
                dataIndex: 'likes'
              },
              {
                title: (
                  <div className="">
                    DisLike (<DislikeOutlined />)
                  </div>
                ),
                dataIndex: 'dislikes'
              },
              {
                title: "Titulo",
                dataIndex: "title",
                key: "title",
              },
              {
                title: "Autor",
                dataIndex: "author",
                key: "author",
              },
              {
                title: "Fecha",
                dataIndex: "created_at",
                key: "created_at",
                render: (text: any, record: any) => (
                  <>{text.substring(0, 10)}</>
                ),
              },
              {
                title: "Publicado",
                dataIndex: "id",
                key: "is_shown",
                render: (id: String, record: any) => (
                  <PublicationsToggleVisibilityButton
                    current={record.is_shown}
                    onClick={() => changeItemVisibility(id, !record.is_shown)}
                  />
                ),
              },
              {
                title: "Empresa",
                dataIndex: "company",
                key: "company",
                filters: companyFilters,
                onFilter: (value: any, record: any) => {
                  if (value === null && record.company === undefined)
                    return true;
                  return record.company === value;
                },
                render: (val) => {
                  return val == "0" ? "Todas" : val;
                },
              },
              {
                title: "Editar",
                dataIndex: "id",
                key: "edit",
                render: (text: String, record: any) => (
                  <>
                    <PublicationsEditModalForm
                      openButtonText="Editar"
                      modalTitle="Editar publicación"
                      successMessage="Publicacion editada correctamente"
                      onConfirm={editItem}
                      confirmLoading={isEditedPublication}
                      selectedId={text}
                    />
                  </>
                ),
              },

              {
                title: "Eliminar",
                dataIndex: "id",
                key: "delete",
                render: (text: String, record: any) => (
                  <>
                    <Popconfirm
                      title="Confirmar"
                      okText="Si"
                      cancelText="No"
                      onConfirm={() => deleteItem(text)}
                    >
                      <DeleteOutlined
                        style={{ fontSize: "20px", color: "#47464E" }}
                      />
                    </Popconfirm>
                  </>
                ),
              },
            ]}
          />
        </DndProvider>
      </div>
    </div>
  );
}

export default Light;
