import api from "../../helpers/api";

const challengeServices = {
  fetchAll: (type: String) => {
    return api.get("/challenge/admin/get/admin/" + type);
  },

  newChallenge: (formData: FormData) => {
    return api.post("/challenge/admin/create", formData);
  },

  fetchById: (id: String) => {
    return api.get("/challenge/admin/getbyid/" + id);
  },

  updateChallenge: (id: String, formData: FormData) => {
    return api.put("/challenge/admin/update/" + id, formData);
  },
  delete: (id: String) => {
    return api.delete("/challenge/admin/delete/" + id);
  },

  sortChallenges: (sortData: Object[]) => {
    return api.put("/challenge/admin/sort", sortData);
  },

  fetchSpecial: () => {
    return api.get("/challenge/admin/special");
  },
};

export default challengeServices;
