import api from '../../helpers/api';

const seedsServices = {
  fetch: () => {
    return api.get('/seeds/fetch');
  },
  create: async (formData: FormData) => {
    return await api.post(`/seeds/create`, formData);
  },
  update: async (formData: FormData, id: String)=>{
    return await api.put(`/seeds/update/${id}`, formData)
  },
  sort: (sortData: Object[]) => {
    return api.put("/seeds/sort", sortData);
  },
  changeVisibility: (id: String, visibility: Boolean) =>{
    return api.post(`/seeds/visibility/${id}`, {visibility: visibility})
  },
  delete: (id: String) =>{
    return api.delete(`/seeds/plan/${id}`)
  },
  getHistory: () => {
    return api.get('/seeds/history')
  }
};

export default seedsServices;
