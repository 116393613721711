import usersConstants from "./constants";

const {
  SET_LOADING_USERS,
  SET_USERS,
  SET_HOME,
  CHECKING_HOME,
  SET_EDITING_USER,
  SET_EDIT_ERROR,
  SET_EDIT_OK,
  SET_SELECTED_ROW_KEYS,
  SET_DELETING_USER,
  SET_DELETE_USER_ERROR,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
  SET_LAST_QUERY
} = usersConstants;

const initialState = {
  loading: false,
  data: [],
  usersTotal: 0,
  home: [],
  editOk: false,
  selectedRowKeys: [],
  pageNumber: 1,
  pageSize: 10,
  isDeletingUser: false,
  deleteUserError: null,

  lastQuery: {
    type: 'fetch-all'
  }
};

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_USERS: {
      return {
        ...state,
        loading: action.isLoadingUsers,
      };
    }
    case SET_USERS: {
      return {
        ...state,
        users: action.users.users,
        usersTotal: action.users.usersTotal
      };
    }
    case SET_HOME: {
      return {
        ...state,
        home: action.home,
      };
    }
    case CHECKING_HOME: {
      return {
        ...state,
        checkingHome: action.isChecking,
      };
    }
    case SET_EDITING_USER: {
      return {
        ...state,
        isEditingUser: action.isEditingUser,
      };
    }
    case SET_EDIT_ERROR: {
      return {
        ...state,
        editError: action.editError,
      };
    }
    case SET_EDIT_OK: {
      return {
        ...state,
        editOk: action.editOk,
      };
    }
    case SET_SELECTED_ROW_KEYS: {
      return {
        ...state,
        selectedRowKeys: action.selectedRowKeys,
      };
    }
    case SET_DELETING_USER: {
      return {
        ...state,
        isDeletingUser: action.isDeletingUser,
      };
    }
    case SET_DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUserError: action.deleteUserError,
      };
    }

    case SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.number
      }
    }

    case SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.number
      }
    }

    case SET_LAST_QUERY: {
      return {
        ...state,
        lastQuery: action.lastQuery
      }
    }
    default: {
      return state;
    }
  }
};

export default usersReducer;
