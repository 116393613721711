import React, { useEffect, useState, useCallback } from "react";
import {
  PageHeader,
  Table,
  Input,
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ChallengeModalForm from "../../../../components/ChallengeModalForm";
import ChallengesEditModalForm from "../../../../components/ChallengesEditModalForm";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableRow from "../../../../components/DraggableRow";
import update from "immutability-helper";

import {
  fetchAllChallenges,
  newChallenge,
  editChallenge,
  deleteChallenge,
  sortChallenges,
  fetchSpecialChallenges,
} from "redux/challenges/actions";
import { fetchAllClients } from "redux/clients";

function Improvement() {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [twice, setTwice] = useState(2);

  const [displayData, setDisplayData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [companyFilters, setCompanyFilters] = useState([] as any[]);
  const [selectedToExport, setSelectedToExport] = useState([] as any[]);

  const image =
    process.env.REACT_APP_BASE_URL + "/public/images/challenge/mejora.png";

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllChallenges("mejora"));
    dispatch(fetchSpecialChallenges());
  }, [dispatch]);

  const {
    isNewChallenge,
    challenges,
    isLoadingChallenges,
    isEditedChallenge,
    specialChallenges,
  } = useSelector((state: any) => state.challenges);

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  useEffect(() => {
    dispatch(fetchAllClients(dispatch));
  }, [dispatch]);

  const [data, setData] = useState(challenges || []);

  useEffect(() => {
    const sortedChallenges = challenges.sort(
      (a: { index: number }, b: { index: number }) => a.index - b.index
    );
    for (let dataChallenge of sortedChallenges) {
      if (
        dataChallenge.company != undefined &&
        dataChallenge.company != null &&
        dataChallenge.company != ""
      ) {
        var newCompany = "0";
        for (let client of dataAllClients) {
          if (client.id == dataChallenge.company) {
            newCompany = client.company_name;
            break;
          }
        }
        dataChallenge.company = newCompany;
      }
    }
    setData(sortedChallenges);
  }, [challenges]);

  const createItem = (formData: FormData, callback: any) => {
    formData.append("type", "mejora");
    dispatch(
      newChallenge(formData, () => {
        dispatch(fetchAllChallenges("mejora"));
        dispatch(fetchSpecialChallenges());
        callback?.();
      })
    );
  };

  const editItem = (id: String, formData: FormData, callback?: any) => {
    formData.append("type", "mejora");
    dispatch(
      editChallenge(id, formData, () => {
        dispatch(fetchAllChallenges("mejora"));
        dispatch(fetchSpecialChallenges());
        callback?.();
      })
    );
  };

  const deleteItem = (id: String) => {
    dispatch(
      deleteChallenge(id, () => {
        message.success("Publicacion borrada con exito");
      })
    );
  };

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      challenges.forEach((challenge: any) => {
        const { title } = challenge;
        const superString = `${title}`;
        if (superString.includes(value)) {
          results.push(challenge);
        }
      });
      return setData(results);
    }
    if (value.trim() === "") {
      return setData(challenges);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "",
      breadcrumbName: "Retos",
    },
    {
      path: "mejora",
      breadcrumbName: "Mejora",
    },
  ];

  const setIndexes = () => {
    return data.map(({ id }: { id: string }, index: number) => ({
      id,
      index,
    }));
  };

  useEffect(() => {
    if (!twice) {
      dispatch(sortChallenges(setIndexes()));
    } else setTwice(twice - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const onFilter = (p: any, filters: any, s: any, { action }: any) => {
    if (action === "filter" && selectedRowKeys?.length === 0) {
      let exportData = [...data];

      for (let i in filters) {
        exportData = exportData.filter(
          (entry) =>
            filters?.[i]?.reduce(
              (prev: boolean, filter: string) =>
                prev ||
                (filter !== "null"
                  ? entry[i] === filter
                  : entry[i] === undefined),
              false
            ) ?? true
        );
      }
      console.log(filters, exportData);
      setCompanyFilters(getFilters("company", exportData));
      // Translate License code type
      setSelectedToExport(
        exportData.map((entry: any) =>
          entry.code_type === "License"
            ? { ...entry, code_type: "Licencia" }
            : entry
        )
      );
    }
  };

  const getFilters = (field: string, data: any[]) => {
    const filters: any[] = [];

    data.forEach((entry) => {
      const existingFilterIndex = filters.findIndex((x) => {
        if (!entry[field] && x.value === null) return true;
        else return x.value === entry[field];
      });

      if (existingFilterIndex >= 0) {
        const quantity =
          parseInt(
            filters[existingFilterIndex].text
              .split(" ")
              [
                filters[existingFilterIndex].text.split(" ").length - 1
              ].replaceAll(/\(*\)*/g, "")
          ) + 1;
        filters[existingFilterIndex].text = `${
          filters[existingFilterIndex].text.split(" ")[0]
        } (${quantity})`;
      } else {
        if (field === "company" && entry[field] === "0") {
          filters.push({ text: `Todas (1)`, value: entry[field] });
        } else if (!entry[field]) {
          filters.push({ text: `Ninguno (1)`, value: null });
        } else {
          filters.push({ text: `${entry[field]} (1)`, value: entry[field] });
        }
      }
    });

    return filters;
  };

  useEffect(() => {
    setDisplayData(data);
    setSelectedToExport(data);
  }, [data]);

  useEffect(() => {
    setCompanyFilters(getFilters("company", displayData));
  }, [displayData]);

  return (
    <div>
      <PageHeader
        title="Retos de mejora"
        ghost={false}
        breadcrumb={{ routes }}
      />

      <div className="top_content_container">
        <Dropdown overlay={menu}>
          <Button>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>

        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
        />

        <ChallengeModalForm
          openButtonText="Añadir"
          modalTitle="Añadir reto"
          successMessage="Publicacion creada"
          onConfirm={createItem}
          confirmLoading={isNewChallenge}
          image={image}
          existingSpecialTypes={specialChallenges}
        />
      </div>
      <div className="content_container">
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={displayData}
            loading={isLoadingChallenges}
            pagination={{ pageSize }}
            components={{
              body: {
                row: DraggableRow,
              },
            }}
            onRow={(record, index) =>
              ({
                index,
                moveRow,
              } as any)
            }
            onChange={onFilter}
            rowKey="id"
            columns={[
              {
                title: "Usuarios activos",
                dataIndex: "active_users",
                key: "active_users",
              },
              {
                title: "Titulo",
                dataIndex: "title",
                key: "title",
              },
              {
                title: "Fecha",
                dataIndex: "created_at",
                key: "created_at",
                render: (text: any, record: any) => (
                  <>{text.substring(0, 10)}</>
                ),
              },
              {
                title: "Categoria",
                dataIndex: "category",
                key: "category",
              },
              {
                title: "Empresa",
                dataIndex: "company",
                key: "company",
                filters: companyFilters,
                onFilter: (value: any, record: any) => {
                  if (value === null && record.company === undefined)
                    return true;
                  return record.company === value;
                },
                render: (val) => {
                  return val == "0" ? "Todas" : val;
                },
              },
              {
                title: "Editar",
                dataIndex: "id",
                key: "edit",
                render: (text: String, record: any) => (
                  <>
                    <ChallengesEditModalForm
                      openButtonText="Editar"
                      modalTitle="Editar publicación"
                      successMessage="Publicacion editada correctamente"
                      onConfirm={editItem}
                      confirmLoading={isEditedChallenge}
                      selectedId={text}
                      image={image}
                      existingSpecialTypes={specialChallenges}
                    />
                  </>
                ),
              },

              {
                title: "Eliminar",
                dataIndex: "id",
                key: "delete",
                render: (text: String, record: any) => (
                  <>
                    <Popconfirm
                      title="Confirmar"
                      okText="Si"
                      cancelText="No"
                      onConfirm={() => deleteItem(text)}
                    >
                      <DeleteOutlined
                        style={{ fontSize: "20px", color: "#47464E" }}
                      />
                    </Popconfirm>
                  </>
                ),
              },
            ]}
          />
        </DndProvider>
      </div>
    </div>
  );
}

export default Improvement;
