const subsConstants = {
  SET_LOADING_SUBSCRIPTION_CODES: "SET_LOADING_SUBSCRIPTION_CODES",
  SET_SUBSCRIPTION_CODES: "SET_SUBSCRIPTION_CODES",
  SET_SUBSCRIPTION_CODES_ERROR: "SET_SUBSCRIPTION_CODES_ERROR",
  SET_LOADING_SUBSCRIPTION_CODE_USERS: "SET_LOADING_SUBSCRIPTION_CODE_USERS",
  SET_SUBSCRIPTION_CODE_USERS: "SET_SUBSCRIPTION_CODE_USERS",
  SET_SUBSCRIPTION_CODE_USERS_ERROR: "SET_SUBSCRIPTION_CODE_USERS_ERROR",
  SET_ADDING_SUBSCRIPTION_CODE: "SET_ADDING_SUBSCRIPTION_CODE",
  SET_ADD_SUBSCRIPTION_CODE_ERROR: "SET_ADD_SUBSCRIPTION_CODE_ERROR",
  SET_EDITING_SUBSCRIPTION_CODE: "SET_EDITING_SUBSCRIPTION_CODE",
  SET_EDIT_SUBSCRIPTION_CODE_ERROR: "SET_EDIT_SUBSCRIPTION_CODE_ERROR",
  SET_DELETING_SUBSCRIPTION_CODE: "SET_DELETING_SUBSCRIPTION_CODE",
  SET_DELETE_SUBSCRIPTION_CODE_ERROR: "SET_DELETE_SUBSCRIPTION_CODE_ERROR",
  SET_EDITING_SUBSCRIPTION: "SET_EDITING_SUBSCRIPTION",
  SET_EDIT_SUBSCRIPTION_ERROR: "SET_EDIT_SUBSCRIPTION_ERROR",
  SET_LOADING_SUBSCRIPTIONS: "SET_LOADING_SUBSCRIPTIONS",
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",
  SET_SUBSCRIPTIONS_ERROR: "SET_SUBSCRIPTIONS_ERROR",
  SET_USERS_DEVICES: "SET_USERS_DEVICES",
  SET_LOADING_USERS_DEVICES: "SET_LOADING_USERS_DEVICES"
};

export default subsConstants;
