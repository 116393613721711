import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  message,
  Spin
} from "antd";
import exportIcon from "../../../assets/icon/export.png";

import {
  fetchAllUsers,
  fetchHome,
  editUser,
  setSelectedRowKeys,
  deleteUser,
  setPageNumber,
  setPageSize,
  searchHandler,
} from "redux/users/actions";

import userService from '../../../redux/users/services'
import styles from "./styles.module.scss";

import {
  EyeOutlined,
  EditOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { CSVLink } from "react-csv";
import moment from "moment";
import { ExportToCsv } from 'export-to-csv';

function Users() {
  const headers = [
    { label: "Nombre y Apellido", key: "full_name" },
    { label: "Email", key: "email" },
    { label: "Telefono", key: "phone" },
    { label: "Nivel", key: "level" },
    { label: "Subida nivel", key: "level_up" },
    { label: "Categoría", key: "category" },
    { label: "Suscripción", key: "subscription_duration" },
    { label: "Fecha inicio", key: "subscription_start" },
    { label: "Fecha fin", key: "subscription_end" },
    { label: "Recomendador tarifas", key: "tariff_recommender" },
  ];

  const columns = [
    {
      title: "Última conexión",
      dataIndex: "last_connection",
      key: "last_connection",
    },
    {
      title: "Nombre y Apellido",
      dataIndex: "full_name",
      key: "full_name",
      width: '7vw',
      render: (text: any, record: any) => {
        if (record.pay_status === "unpaid") {
          return <td style={{ color: "red" }}>{text}</td>;
        } else {
          return text;
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Telefono",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Nivel",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Subida nivel",
      dataIndex: "level_up",
      key: "level_up",
      render: (text: any, record: any) => {
        if (
          text >
          moment()
            .subtract(3, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss")
        ) {
          return <td style={{ color: "#199479" }}>{text}</td>;
        } else {
          return text;
        }
      },
    },
    {
      title: "Categoría",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Suscripción",
      dataIndex: "subscription_duration",
      key: "subscription_duration",
    },
    {
      title: "Fecha inicio",
      dataIndex: "subscription_start",
      key: "subscription_start",
    },
    {
      title: "Fecha fin",
      dataIndex: "subscription_end",
      key: "subscription_end",
      render: (text: any, record: any) => {
        if (text < moment().format("MM/DD/YYYY")) {
          return <td style={{ color: "red" }}>{text}</td>;
        } else {
          return text;
        }
      },
    },
    {
      title: "Recomendador tarifas",
      dataIndex: "tariff_recommender",
      key: "tariff_recommender",
      render: (val?: boolean) => {
        return val || val === undefined ? "Sí" : "No";
      },
    },

    {
      title: "Ver",
      dataIndex: "id",
      key: "ver",
      render: (text: any, record: any) => (
        <>
          <EyeOutlined
            style={{ fontSize: "20px", color: "#00000" }}
            onClick={() => showModalWatch(text)}
          />
        </>
      ),
    },
    {
      title: "Editar",
      dataIndex: "id",
      key: "editar",
      render: (text: any, record: any) => (
        <>
          <EditOutlined
            style={{ fontSize: "20px", color: "#199479" }}
            onClick={() => showModalEdit(text)}
          />
        </>
      ),
    },
  ];

  const { user: admin } = useSelector((state: any) => state.auth);

  const handleDelete = (userId: string) => {
    dispatch(
      deleteUser(userId, () => {
        message.success("El usuario fue eliminado satisfactoriamente");
        dispatch(fetchAllUsers());
      })
    );
  };

  if (admin?.super_admin) {
    columns.push({
      title: "Eliminar",
      dataIndex: "id",
      key: "delete",
      render: (id: string) => (
        <>
          <Popconfirm
            title="Esta acción eliminará definitivamente toda la información del usuario, incluyendo dispositivos, historial y suscripciones. ¿Continuar?"
            okText="Sí"
            okButtonProps={{ type: "danger" as any }}
            cancelText="No"
            onConfirm={() => handleDelete(id)}
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "#47464E" }} />
          </Popconfirm>
        </>
      ),
    });
  }

  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isExportAll, setIsExportingAll] = useState(false)
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [currentPage, setCurrentPage] = useState();
  const [user, setUser] = useState({
    full_name: "",
    email: "",
    city: "",
    address: "",
    postal_code: "",
    delivery_note: "",
  });
  const [selectedUserName, setSelectedUserName] = useState("");
  const [pagination,setPagination] = useState({ 
		pageSize: 6,current:1,total:50
	})


  const { home } = useSelector((state: any) => state.users);

  // Load home only when change idSelected
  useEffect(() => {
    if (idSelected) {
      dispatch(fetchHome(idSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelectedEdit]);

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.full_name);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalWatchVisible(false);
    setIsModalEditVisible(false);
    setIdSelectedEdit(0);
    setUser({
      full_name: "",
      email: "",
      city: "",
      address: "",
      postal_code: "",
      delivery_note: "",
    });
    setSuccess("");
  };
  const [success, setSuccess] = useState("");
  const { editError, editOk } = useSelector((state: any) => state.users);
  const { Text } = Typography;

  const handleOk = () => {
    setSuccess("Usuario actualizado correctamente");
    dispatch(editUser(user));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch, idSelectedEdit]);

  const { loading, users, pageSize, usersTotal } = useSelector((state: any) => state.users);

  const [data, setData] = useState(users || [{}]);

  useEffect(() => {
    setData(users);
  }, [users]);

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const { Search } = Input;

  const onSearch = (value: any) => {
    dispatch(searchHandler(value))
  };

  function handleMenuClick(e: any) {
    dispatch(setPageSize(parseInt(e.key)))
    // dispatch(fetchAllUsers())
  }

  const handleTableChange = (pagination: any) => {
		// console.log("args:",pagination,sorter)
		setPagination(pagination)
    dispatch(setPageNumber(pagination.current))
    dispatch(fetchAllUsers())
	}



  const routes = [
    {
      path: "index",
      breadcrumbName: "Usuarios",
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.users);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));
    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.forEach((k: any) => {
        data.forEach((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport(data);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (data && selectedRowKeys.length === 0) {
      setSelectedToExport(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const currentPageHandler = (currentPage: any)=>{
    setCurrentPage(currentPage)
    dispatch(setPageNumber(currentPage))
    // dispatch(fetchAllUsers())
  }

const handleExportAll = async ()=>{
    setIsExportingAll(true)

    let dataToExport = await userService.getAllUsers().then( (res)=>{
      let {data} = res
			return data.response
		})

    dataToExport = dataToExport.map( (item: any)=>{
      return {
        name: item.full_name || '',
				email: item.email || '',
				phone: item.phone || '',
				level: item.level || '',
				levelUp: item.level_up || '',
				category: item.category || '',
				suscription: item.subscription_duration || '',
				subscription_start: item.subscription_start || '',
				subscription_end: item.subscription_end || '',
				tariff_recommender: item.tariff_recommender ? 'Si' : 'No'
      }
    })

		const options = { 
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			showLabels: true, 
			showTitle: true,
			title: 'Usuarios',
			useTextFile: false,
			useBom: true,
			//   useKeysAsHeaders: true,
			headers:[
        "Nombre y Apellido", 
        "Email", 
        "Telefono", 
        "Nivel", 
        "Subida nivel", 
        "Categoría", 
        "Suscripción", 
        "Fecha inicio", 
        "Fecha fin", 
        "Recomendador tarifas", 
      ]
		};
		
		const csvExporter = new ExportToCsv(options);
		
		csvExporter.generateCsv(dataToExport);
		setIsExportingAll(false)
	}

	const handleExportOnTable = ()=>{
		
		let readyToExport = selectedToExport.map( (item: any)=>{
      return {
        name: item.full_name || '',
				email: item.email || '',
				phone: item.phone || '',
				level: item.level || '',
				levelUp: item.level_up || '',
				category: item.category || '',
				suscription: item.subscription_duration || '',
				subscription_start: item.subscription_start || '',
				subscription_end: item.subscription_end || '',
				tariff_recommender: item.tariff_recommender ? 'Si' : 'No'
      }
    })

		const options = { 
		  fieldSeparator: ',',
		  quoteStrings: '"',
		  decimalSeparator: '.',
		  showLabels: true, 
		  showTitle: true,
		  title: 'Usuarios',
		  useTextFile: false,
		  useBom: true,
		//   useKeysAsHeaders: true,
		  headers: [
        "Nombre y Apellido", 
        "Email", 
        "Telefono", 
        "Nivel", 
        "Subida nivel", 
        "Categoría", 
        "Suscripción", 
        "Fecha inicio", 
        "Fecha fin", 
        "Recomendador tarifas", 
      ]
		};
	  
	  const csvExporter = new ExportToCsv(options);
	  
	  csvExporter.generateCsv(readyToExport);
	  // setIsExporting(false)
	}

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const ExportOptions = (
    <Menu>
      <Menu.Item onClick={handleExportOnTable}>Exportar seleccionados</Menu.Item>
      <Menu.Item onClick={handleExportAll} 
        style={{display: isExportAll ? 'flex' : '', justifyContent: 'center'}}
          >{isExportAll ? <Spin size='small'/> : 'Exportar todos'}</Menu.Item>
    </Menu>
  )

  return (
    <div>
      <PageHeader title="Usuarios" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container" style={{ display: "flex", justifyContent: 'center' }}>
        <Dropdown overlay={menu}>
          <Button>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
        />

        <div
          style={{
            display: "inline",
            marginLeft: "auto",
          }}
        >
          <Button
            onClick={() => {
              dispatch(fetchAllUsers());
            }}
            style={{
              color: "#199479",
              backgroundColor: "transparent",
              textAlign: "center",
              border: "1px solid #199479",
              borderRadius: "4px",
              display: "inline",
            }}
          >
            Refrescar
          </Button>
          <Dropdown overlay={ExportOptions}>
            <Button
              style={{
                color: "#199479",
                backgroundColor: "transparent",
                textAlign: "center",
                border: "1px solid #199479",
                borderRadius: "4px",
                display: "inline-flex",
                alignItems: 'center',
                marginLeft: "5px",
                height: "100%",
                verticalAlign: "top",
                paddingRight: "8px",
              }}
            >
              <img src={exportIcon} alt="" style={{ margin: "5px" }} />
              Exportar
            </Button>
          </Dropdown>
        </div>
      </div>

      <div className="content_container">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowSelection={rowSelection}
          scroll={{ x: true }}
          onChange={handleTableChange}
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            total: usersTotal,
            onChange: (change) => currentPageHandler(change)
          }}
        />
        
        <Modal
          title={selectedUserName}
          visible={isModalWatchVisible}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={handleCancel}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre de la casa">
              <Input value={home.home_name} readOnly />
            </Form.Item>
            <Form.Item label="Nº de personas que viven en la casa">
              <Input value={home.people} readOnly />
            </Form.Item>
            <Form.Item label="Nº m2 tiene la casa">
              <Input value={home.surface + " m2"} readOnly />
            </Form.Item>
            <Form.Item label="Año aproximado de construcción">
              <Input value={home.year} readOnly />
            </Form.Item>
            <Form.Item label="Tipo de caldera">
              <Input value={home.boiler_type} readOnly />
            </Form.Item>
            <Form.Item label="Hace cuánto no cambias de factura de electricidad">
              <Input value={home.energy_contract} readOnly />
            </Form.Item>
            <Form.Item label="Cuánto pagas cada mes en la factura de electricidad">
              <Input value={home.energy_cost} readOnly />
            </Form.Item>
            <Form.Item label="Orientación de tu casa">
              <Input value={home.orientation} readOnly />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Edit"
          visible={isModalEditVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Editar
            </Button>,
          ]}
        >
          <form>
            <label>Nombre y apellido</label>
            <input
              className={styles.input}
              type="text"
              id="full_name"
              name="full_name"
              value={user.full_name}
              onChange={handleChange}
            />

            <label>Email</label>
            <input
              className={styles.input}
              type="text"
              id="email"
              name="email"
              value={user.email}
              onChange={handleChange}
            />

            <label>Dirección</label>
            <input
              className={styles.input}
              type="text"
              id="address"
              name="address"
              value={user.address}
              onChange={handleChange}
            />

            <label>Ciudad</label>
            <input
              className={styles.input}
              type="text"
              id="city"
              name="city"
              value={user.city}
              onChange={handleChange}
            />

            <label>Código postal</label>
            <input
              className={styles.input}
              type="text"
              id="postal_code"
              name="postal_code"
              value={user.postal_code}
              onChange={handleChange}
            />

            <label>Información adicional para el envío</label>
            <input
              className={styles.input}
              type="text"
              id="delivery_note"
              name="delivery_note"
              value={user.delivery_note}
              onChange={handleChange}
            />
            {editError && <Text type="danger">{editError}</Text>}
            {editOk && <Text type="success">{success}</Text>}
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default Users;
