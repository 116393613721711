import React, { useState } from "react";
import { Button, Modal, Form, Input, message, Upload, Select } from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined, CloudUploadOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";

function PublicationsModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  successMessage?: string;
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
  } = props;

  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [form] = Form.useForm();
  const { addPublicationError } = useSelector(
    (state: any) => state.publications
  );

  const {
    loadingAllClients,
    successAllClients,
    errorAllClients,
    dataAllClients,
  } = useSelector((state: any) => state.clients);

  const openModal = () => setVisible(true);
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields();
    message.success(successMessage || "Exito");
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const { title, semillas, url, company } = values;
        const form = new FormData();

        form.append("title", title);
        form.append("semillas", semillas);
        form.append("url", url);
        form.append("company", company);
        if (selectedImage) form.append("image", selectedImage as any);

        onConfirm(form, onSuccess);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={openModal}
        style={{
          color: "#199479",
          border: "1px solid #199479",
          borderRadius: "4px",
          backgroundColor: "transparent",
        }}
      >
        <PlusOutlined />
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Crear
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Título"
            name="title"
            rules={[{ required: true }]}
            validateStatus={addPublicationError && "error"}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Semillas"
            name="semillas"
            rules={[{ required: true }]}
            validateStatus={addPublicationError && "error"}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Enlace (URL)"
            name="url"
            rules={[{ required: true }]}
            validateStatus={addPublicationError && "error"}
            help={addPublicationError || null}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Empresa"
            name="company"
            validateStatus={addPublicationError && "error"}
            initialValue="0"
          >
            <Select>
              <Select.Option value="0">Todas</Select.Option>
              {dataAllClients.map((company_map: any) => (
                <Select.Option key={company_map.id} value={company_map.id}>
                  {company_map.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Imagen"
            name="image"
            validateStatus={addPublicationError && "error"}
          >
            <Upload.Dragger
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              beforeUpload={() => false}
              multiple={false}
              onChange={({ fileList }) => {
                if (fileList.length > 0)
                  setSelectedImage(fileList[0].originFileObj as any);
                else setSelectedImage(undefined);
              }}
              listType="picture"
            >
              <p className={styles.cloudIcon}>
                <CloudUploadOutlined />
              </p>
              <p className={styles.hintText}>
                Clica o arrastra aquí para subir la foto de portada
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default PublicationsModalForm;
