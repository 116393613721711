import subsConstants from "./constants";

const {
  SET_LOADING_SUBSCRIPTION_CODES,
  SET_SUBSCRIPTION_CODES,
  SET_SUBSCRIPTION_CODES_ERROR,
  SET_LOADING_SUBSCRIPTION_CODE_USERS,
  SET_SUBSCRIPTION_CODE_USERS,
  SET_SUBSCRIPTION_CODE_USERS_ERROR,
  SET_ADDING_SUBSCRIPTION_CODE,
  SET_ADD_SUBSCRIPTION_CODE_ERROR,
  SET_EDITING_SUBSCRIPTION_CODE,
  SET_EDIT_SUBSCRIPTION_CODE_ERROR,
  SET_DELETING_SUBSCRIPTION_CODE,
  SET_DELETE_SUBSCRIPTION_CODE_ERROR,
  SET_LOADING_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_ERROR,
  SET_USERS_DEVICES,
  SET_LOADING_USERS_DEVICES
} = subsConstants;

const initialState = {
  loadingSubscriptionCodes: false,
  subscriptionCodes: [],
  subscriptionCodesError: null,
  loadingSubscriptionCodeUsers: false,
  subscriptionCodeUsers: [],
  subscriptionCodeUsersError: null,
  addingSubscriptionCode: false,
  addSubscriptionCodeError: null,
  editingSubscriptionCode: false,
  editSubscriptionCodeError: null,
  deletingSubscriptionCode: false,
  deleteSubscriptionCodeError: null,
  loadingSubscriptions: false,
  subscriptions: [],
  subscriptionsError: null,
  devicesUsers: [],
  isLoadingDevices: false
};

const subscriptionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_SUBSCRIPTION_CODES: {
      return {
        ...state,
        loadingSubscriptionCodes: action.isLoadingSubscriptionCodes,
      };
    }
    case SET_SUBSCRIPTION_CODES: {
      return {
        ...state,
        subscriptionCodes: action.subscriptionCodes,
      };
    }
    case SET_SUBSCRIPTION_CODES_ERROR: {
      return {
        ...state,
        subscriptionCodesError: action.subscriptionCodesError,
      };
    }
    case SET_LOADING_SUBSCRIPTION_CODE_USERS: {
      return {
        ...state,
        loadingSubscriptionCodeUsers: action.isLoadingSubscriptionCodeUsers,
      };
    }
    case SET_SUBSCRIPTION_CODE_USERS: {
      return {
        ...state,
        subscriptionCodeUsers: action.subscriptionCodeUsers,
      };
    }
    case SET_SUBSCRIPTION_CODE_USERS_ERROR: {
      return {
        ...state,
        subscriptionCodeUsersError: action.subscriptionCodeUsersError,
      };
    }
    case SET_ADDING_SUBSCRIPTION_CODE: {
      return {
        ...state,
        addingSubscriptionCode: action.isAddingSubscriptionCode,
      };
    }
    case SET_ADD_SUBSCRIPTION_CODE_ERROR: {
      return {
        ...state,
        addSubscriptionCodeError: action.addSubscriptionCodeError,
      };
    }
    case SET_EDITING_SUBSCRIPTION_CODE: {
      return {
        ...state,
        editingSubscriptionCode: action.isEditingSubscriptionCode,
      };
    }
    case SET_EDIT_SUBSCRIPTION_CODE_ERROR: {
      return {
        ...state,
        editSubscriptionCodeError: action.editSubscriptionCodeError,
      };
    }
    case SET_DELETING_SUBSCRIPTION_CODE: {
      return {
        ...state,
        deletingSubscriptionCode: action.isDeletingSubscriptionCode,
      };
    }
    case SET_DELETE_SUBSCRIPTION_CODE_ERROR: {
      return {
        ...state,
        deleteSubscriptionCodeError: action.deleteSubscriptionCodeError,
      };
    }
    case SET_LOADING_SUBSCRIPTIONS: {
      return {
        ...state,
        loadingSubscriptions: action.isLoadingSubscriptions,
      };
    }
    case SET_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: action.subscriptions,
      };
    }
    case SET_SUBSCRIPTIONS_ERROR: {
      return {
        ...state,
        subscriptionsError: action.subscriptionsError,
      };
    }

    case SET_USERS_DEVICES: {
      return {
        ...state,
        devicesUsers: action.devicesUsers
      }
    }

    case SET_LOADING_USERS_DEVICES: {
      return {
        ...state,
        isLoadingDevices: action.isLoading
      }
    }

    default: {
      return state;
    }
  }
};

export default subscriptionsReducer;
