import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { Table, Button, Dropdown, Input, Menu, PageHeader } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { getHistory, setSelectedRowKeys } from 'redux/seeds/action'
import exportIcon from "../../../../assets/icon/export.png";

export default function Index() {

  const dispatch = useDispatch()
  const {plansHistory, isLoadingHistory} = useSelector((state:any) => state.seeds)
  const [pageSize, setPageSize] = useState(10)

  const [data, setData] = useState([])
  const [companyFilters, setCompanyFilters] = useState([] as any[]);
  const [typeFilters, setTypeFilters] = useState([] as any[])
  const [nameFilters, setNameFilters] = useState([] as any[])
  const [dateFilters, setDateFilters] = useState([] as any[])

  useEffect( ()=>{
    setData(plansHistory)
  }, [plansHistory])

  const headers = [
    {label: 'Nombre y apellido', key: 'full_name'},
    {label: 'Titulo', key: 'title'},
    {label: 'Tipo', key: 'type'},
    {label: 'Semillas', key: 'seeds'},
    {label: 'Fecha de canje', key: 'date'},
    {label: 'Empresa', key: 'company_name'},
  ]


  const columns = [
    {
      title: "Nombre y apellido",
      dataIndex: "full_name",
      key: "full_name",
      filters: nameFilters,
      onFilter: (value: any, record: any) => {
        return record.full_name === value;
      },
      render: (val: any) => {
        return !val ? "" : val;
      },
    },
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilters,
      onFilter: (value: any, record: any) => {
        return record.type === value;
      },
      render: (val: any) => {
        return !val ? "Todas" : val;
      },
      
    },
    {
      title: "Semillas",
      dataIndex: "seeds",
      key: "seeds",
    },
    {
      title: "Fecha de canje",
      dataIndex: "date",
      key: "date",
      filters: dateFilters,
      onFilter: (value: any, record: any) => {
        return record.type === value;
      },
      render: (val: any) => {
        return !val ? "Todas" : val;
      },
    },
    {
      title: "Empresa",
      dataIndex: "company_name",
      key: "company_name",
      filters: companyFilters,
      onFilter: (value: any, record: any) => {
        return record.company_name === value;
      },
      render: (val: any) => {
        return !val ? "Todas" : val;
      },
    },
  ]

  useEffect(() => {
    dispatch(getHistory())
  }, []);

  useEffect(() => {
    setCompanyFilters(getFiltersCompany("company_name", data));
    setNameFilters(getFiltersCompany("full_name", data));
    setDateFilters(getFiltersCompany("date", data));
    setTypeFilters(getFiltersCompany("type", data));
  }, [data]);

  const onFilter = (p: any, filters: any, s: any, { action }: any) => {
    if (action === "filter" && selectedRowKeys?.length === 0) {
      let exportData = [...data];
      for (let i in filters) {
        exportData = plansHistory.filter(
          (entry: any) =>
            filters?.[i]?.reduce(
              (prev: boolean, filter: string) =>
                prev ||
                (filter !== "null"
                  ? entry[i] === filter
                  : entry[i] === undefined),
              false
            ) ?? true
        );
      }
      setCompanyFilters(getFiltersCompany("company_name", exportData));
      setTypeFilters(getFiltersCompany("type", exportData));
      setNameFilters(getFiltersCompany("full_name", data));
      setDateFilters(getFiltersCompany("date", data));
      setSelectedToExport(exportData);
    }
  };

  const getFiltersCompany = (field: string, data: any[]) => {
    const filters: any[] = [];

    plansHistory.forEach((entry: any) => {
      const existingFilterIndex = filters.findIndex((x) => {
        if (!entry[field] && x.value === null) return true;
        else return x.value === entry[field];
      });

      if (existingFilterIndex >= 0) {
        const quantity =
          parseInt(
            filters[existingFilterIndex].text
              .split(" ")
              [
                filters[existingFilterIndex].text.split(" ").length - 1
              ].replaceAll(/\(*\)*/g, "")
          ) + 1;
        filters[existingFilterIndex].text = `${
          filters[existingFilterIndex].text.split(" ")[0]
        } (${quantity})`;
      } else {

        if (field === "company_name" && !entry[field]) {
          filters.push({ text: `Todas (1)`, value: entry[field] });
        } else {
          filters.push({ text: `${entry[field]} (1)`, value: entry[field] });
        }
      }
    });

    return filters;
  };
 

  const { selectedRowKeys } = useSelector((state: any) => state.seeds);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));
    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.forEach((k: any) => {
        plansHistory.forEach((d: any) => {
          if (d.id === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport(plansHistory);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
        path: "",
        breadcrumbName: "Canjes",
    }];

    const { Search } = Input;
    
    const onSearch = (value: any) => {
      const results: any = [];
      if (value) {
        plansHistory.forEach((plan: any) => {
          const { title } = plan;
          const superString = `${title}`;
          if (superString.includes(value)) {
            results.push(plan);
          }
        });
        return setData(results);
      }
      if (value.trim() === "") {
        return setData(plansHistory);
      }
    };

  return (
    <div>


      <PageHeader
          title="Planes"
          ghost={false}
          breadcrumb={{ routes }}
      />
      <div className="top_content_container">

          <Dropdown overlay={menu}>
          <Button>
            {pageSize}  <DownOutlined />
          </Button>
          </Dropdown>

          <Search
              placeholder="Buscar..."
              onSearch={onSearch}
              style={{ width: 200 }}
          />

          <CSVLink
          style={{
            color: "#199479",
            backgroundColor: "transparent",
            textAlign: "center",
            border: "1px solid #199479",
            borderRadius: "4px",
            display: "inline-flex",
            alignItems: 'center',
            marginLeft: "5px",
            verticalAlign: "top",
            width: "100%",
            height: "100%"
          }}
          data={selectedToExport}
          headers={headers}
          filename={"canjes.csv"}
          separator={","}>
            <img src={exportIcon} alt="" style={{ margin: "5px" }} />
            Exportar
          </CSVLink>
          
      </div>
      
<div className="content_container">
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoadingHistory}
        onChange={onFilter}
        rowKey='id'
        pagination={{ pageSize }}
        rowSelection={rowSelection}
        scroll={{ x: true }}
        />
        </div>
      
    </div>
  )
}
