import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { /*useDispatch,*/ useSelector } from 'react-redux';

function AdminModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  successMessage?: string;
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
  } = props;

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { logUpError } = useSelector((state: any) => state.admins);

  const openModal = () => setVisible(true);
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields();
    message.success(successMessage || 'Exito');
  };

  // const dispatch = useDispatch();

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        //dispatch(newAdmin(values));
        onConfirm(values, onSuccess);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Button type="primary" onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Crear
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Nombre y apellido"
            name="name"
            rules={[{ required: true }]}
            validateStatus={logUpError && "error"}
            help={logUpError || null}
          >
            <Input />
          </Form.Item>
          
          <Form.Item
            label="Email"
            name="email"
            validateStatus={logUpError && "error"}
            rules={[
              { required: true, message: "Por favor introduce tu email" },
              { type: "email", message: "Introduce un email válido" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AdminModalForm;
