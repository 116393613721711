import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Popconfirm,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllAdmins,
  editAdmin,
  newAdmin,
  deleteAdmin,
} from "../../../../redux/admins/actions";
import AdminModalForm from "../../../../components/AdminModalForm";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

function Profiles() {
  const dispatch = useDispatch();

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllAdmins());
  }, [dispatch]);

  const { loading, data, creating } = useSelector((state: any) => state.admins);
  const { user } = useSelector((state: any) => state.auth);
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.super_admin === true) {
        setSuperAdmin(true);
      }
    }
  }, [user]);

  const createItem = (data: any, callback: any) => {
    dispatch(newAdmin(data, callback));
  };

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelectedEdit, setIdSelectedEdit]: any = useState();
  const [form] = Form.useForm();

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const deleteItem = (id: string) => {
    dispatch(
      deleteAdmin(id, () => {
        message.success("Administrador eliminado con éxito");
        dispatch(fetchAllAdmins());
      })
    );
  };

  const [fields, setFields]: any = useState([
    {
      name: ["name"],
      value: "",
    },
    {
      name: ["email"],
      value: "",
    },
  ]);

  useEffect(() => {
    if (idSelectedEdit) {
      const info: any = data.find((e: any) => e.id === idSelectedEdit);
      setFields([
        {
          name: ["name"],
          value: info?.name,
        },
        {
          name: ["email"],
          value: info?.email,
        },
      ]);
    }
  }, [idSelectedEdit, data]);

  const [success, setSuccess] = useState("");
  const { Text } = Typography;

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setSuccess("");
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(
          editAdmin(idSelectedEdit, values, () => {
            message.success("Administrador actualizado satisfactoriamente");
            setIsModalEditVisible(false);
            form.resetFields();
          })
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const routes = [
    {
      path: "",
      breadcrumbName: "Administradores / Perfiles",
    },
  ];

  return (
    <div>
      {superAdmin && (
        <PageHeader
          title="Perfiles"
          ghost={false}
          breadcrumb={{ routes }}
          extra={[
            <AdminModalForm
              openButtonText="Añadir"
              modalTitle="Alta administrador"
              successMessage="Administrador creado correctamente"
              onConfirm={createItem}
              confirmLoading={creating}
            />,
          ]}
        />
      )}

      <div className="content_container">
        {!superAdmin && (
          <Table
            onChange={data}
            dataSource={data}
            loading={loading}
            rowKey="id"
            columns={[
              {
                title: "Administradores",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email",
              },
            ]}
          />
        )}
        {superAdmin && (
          <div>
            <Table
              dataSource={data}
              loading={loading}
              rowKey="id"
              columns={[
                {
                  title: "Administradores",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Email",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Editar",
                  dataIndex: "id",
                  key: "editar",
                  render: (text: any, record: any) => (
                    <>
                      <EditOutlined
                        style={{ fontSize: "20px", color: "#199479" }}
                        onClick={() => showModalEdit(text)}
                      />
                    </>
                  ),
                },
                {
                  title: "Eliminar",
                  dataIndex: "id",
                  key: "eliminar",
                  render: (text: any, record: any) => (
                    <>
                      <Popconfirm
                        title="Confirmar"
                        okText="Si"
                        cancelText="No"
                        onConfirm={() => deleteItem(text)}
                      >
                        <DeleteOutlined
                          style={{ fontSize: "20px", color: "#47464E" }}
                        />
                      </Popconfirm>
                    </>
                  ),
                },
              ]}
            />
            <Modal
              title="Editar administrador"
              visible={isModalEditVisible}
              onCancel={handleCancel}
              onOk={handleOk}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancelar
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                >
                  Guardar
                </Button>,
              ]}
            >
              <Form layout="vertical" form={form} fields={fields}>
                <Form.Item label="Nombre y apellido" name="name">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { type: "email", message: "Introduce un email válido" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Text type="success">{success}</Text>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profiles;
