import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Avatar,
    Button,
    Card,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Col,
    Select,
    Spin,
    Switch,
    Typography,
    DatePicker,
    InputNumber,
    Upload,
} from "antd";
import {UploadOutlined, PlusOutlined} from "@ant-design/icons";

import {createPlan, updatePlan} from '../../../../../redux/seeds/action'
import defaultIcon from "../../../../../assets/defaults_icons/person_default.png";
import Text from 'antd/lib/typography/Text';

const { Option } = Select;
const { TextArea } = Input;

interface Props {
    openButtonText: any,
    modalTitle: any,
    successMessage: any,
    onConfirm: any,
    confirmLoading: any,
    visible: any,
    setVisible: any,

    plan?: any,
    setPlan?: any
}

export default function PlansModalForm({
    openButtonText,
    modalTitle,
    successMessage,
    onConfirm,
    confirmLoading,
    visible,
    setVisible,
    plan,
    setPlan
}: Props) {
    const dispatch = useDispatch()

    const {
        dataAllClients,
      } = useSelector((state: any) => state.clients);

    const [selectedIcon, setSelectedIcon]: any = useState(null);
    const [selectedImagePlan, setSelectedImagePlan]: any = useState(null);
    const [selectedSecondImage, setSelectedSecondImage]: any = useState(null);
    const [selectedInfographics, setSelectedInfographics]: any = useState(null);

    const [preSelectedIcon, setPreSelectedIcon]: any = useState(null);
    const [preSelectedImagePlan, setPreSelectedImagePlan]: any = useState(null);
    const [preSelectedSecondImage, setPreSelectedSecondImage]: any = useState(null);
    const [preSelectedInfographics, setPreSelectedInfographics]: any = useState(null);

    const [id, setId] = useState('')
    const [type, setType] = useState("")

    const [text, setText] = useState("");
    const [textPreview, setTextPreview] = useState(false);
    const [hasLink, setHasLink] = useState(true)

    const openModal = () => setVisible(true)

    const handleCancel = () => {
        setVisible(false)
        resetFields()
    }

    const [form] = Form.useForm();
    
    const handleTextChange = (e: any) => {
        setText(e.target.value);
        if (e.target.value.length <= 130) {
        setTextPreview(false);
        }
    };

    const handleSelect = (e: any) => {
        setType(e)
    }
    
    const togglePreview = () => {
        if (textPreview) {
            setTextPreview(false);
        } else {
            setTextPreview(true);
        }
    };

    const handleSwitchChange = (e: any) => {
        setHasLink(e)
    }

    const handleSubmit = () =>{
        form
            .validateFields()
            .then( (values)=>{
                const {
                    company,
                    link,
                    second_text,
                    seeds,
                    text,
                    title,
                    discount,
                    valid_until,
                    type,
                } = values
                
                let formData = new FormData()
                formData.append(
                    "icon", 
                    selectedIcon.originFileObj
                );
                
                formData.append(
                    "infographics", 
                    selectedInfographics.originFileObj
                );
                
                formData.append(
                    "selectedImagePlan",
                    selectedImagePlan.originFileObj
                );

                if(selectedSecondImage){
                    formData.append(
                        "secondImage", 
                        selectedSecondImage.originFileObj
                        );
                }
                
                formData.append("title", title);
                formData.append("discount", discount);
                formData.append("seeds", seeds);
                if(valid_until) formData.append("valid_until", moment(valid_until).format());
                if(!valid_until) formData.append("valid_until", valid_until);
                formData.append("type", type);
                formData.append("company", company);
                formData.append("link", link);
                formData.append("text", text);
                formData.append("second_text", second_text);

                dispatch(createPlan(formData))
                handleCancel()
            })
    }

    useEffect(() => {
        if (plan) {
            form.setFieldsValue({
                valid_until: plan[0]?.valid_until && plan[0].valid_until === 'Invalid date' ? null : moment(plan[0].valid_until, 'DD/MM/YYYY'),
                title: plan[0]?.title,
                second_text: plan[0]?.second_text === 'undefined' ? '' : plan[0]?.second_text,
                text: plan[0]?.text,
                discount: plan[0]?.discount === 'undefined' ? '' : plan[0]?.discount,
                link: plan[0]?.link === 'undefined' ? '' : plan[0]?.link,
                seeds: plan[0]?.seeds === 'undefined' ? '' : plan[0]?.seeds,
                type: plan[0]?.type  === 'undefined' ? '' : plan[0]?.type,
                company: plan[0]?.company_id ? plan[0]?.company_id : 'Todas',
                icon: plan[0]?.icon,
                imagePlan: plan[0]?.selectedImagePlan,
                infografia: plan[0]?.infographics,
                secondImage: plan[0]?.selectedSecondImage
            });
            
            setId(plan[0].id)

            setHasLink(plan[0].link || plan[0].second_text ? true : false)
            setType(plan[0].type)
            
            setSelectedIcon(plan[0]?.icon);
            setPreSelectedIcon(plan[0]?.icon);

            setSelectedInfographics(plan[0]?.infographics);
            setPreSelectedInfographics(plan[0]?.infographics)

            setSelectedImagePlan(plan[0]?.selectedImagePlan);
            setPreSelectedImagePlan(plan[0]?.selectedImagePlan)

            setSelectedSecondImage(plan[0]?.selectedSecondImage);
            setPreSelectedSecondImage(plan[0]?.selectedSecondImage);
        }
    }, [plan]);

    const resetFields = () =>{
        form.setFieldsValue({
            valid_until: null,
            title: null,
            second_text: null,
            text: null,
            discount: null,
            link: null,
            seeds: null,
            type: null,
            company: null,
            icon:null,
            imagePlan:null,
            infografia:null,
            secondImage:null
        });

        setId('')

        setHasLink(false)
        setType('')
        
        setSelectedIcon(null);
        setPreSelectedIcon(null);

        setSelectedInfographics(null);
        setPreSelectedInfographics(null)

        setSelectedImagePlan(null);
        setPreSelectedImagePlan(null)

        setSelectedSecondImage(null);
        setPreSelectedSecondImage(null);
    }

    const handleEdit = () =>{
        form
        .validateFields()
        .then( (values)=>{
            const {
                company,
                link,
                second_text,
                seeds,
                text,
                title,
                discount,
                valid_until,
                type,
            } = values
            
            let formData = new FormData() 
            console.log(selectedIcon === preSelectedIcon)
            if(selectedIcon === preSelectedIcon){
                formData.append(
                    "icon", 
                    selectedIcon
                );
            } else {
                formData.append(
                    "icon", 
                    selectedIcon.originFileObj
                    );
            }

            if (selectedInfographics === preSelectedInfographics){            
                formData.append(
                    "infographics", 
                    selectedInfographics
                );
            } else { 
                formData.append(
                    "infographics", 
                    selectedInfographics.originFileObj
                );
            }

            if(selectedImagePlan === preSelectedImagePlan){
                formData.append(
                    "selectedImagePlan",
                    selectedImagePlan
                );
            } else {
                formData.append(
                    "selectedImagePlan",
                    selectedImagePlan.originFileObj
                    );
            }

            if(selectedSecondImage) {
                if(selectedSecondImage === preSelectedSecondImage){
                formData.append(
                    "secondImage", 
                    selectedSecondImage
                    );
            } else {
                formData.append(
                    "secondImage", 
                    selectedSecondImage.originFileObj
                    );
            }
            }
            
            formData.append("title", title);
            formData.append("discount", discount);
            formData.append("seeds", seeds);
            if(valid_until) formData.append("valid_until", moment(valid_until).format());
            if(!valid_until) formData.append("valid_until", valid_until);
            formData.append("type", type);
            formData.append("company", company);
            formData.append("link", link);
            formData.append("text", text);
            formData.append("second_text", second_text);

            dispatch(updatePlan(formData, id))
            handleCancel()
        })
    }

    return (
    <>
        {
            openButtonText === 'Añadir' &&
            <Button type="primary" style={{
                    color: "#199479",
                    border: "1px solid #199479",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
            }}
            onClick={openModal}>
                <PlusOutlined />
                {openButtonText}
            </Button>
        }


        <Modal
            title={modalTitle}
            destroyOnClose={true}
            visible={visible}
            onOk={plan ? handleEdit : handleSubmit}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={plan ? handleEdit : handleSubmit}
                    loading={false}
                >
                    {openButtonText}
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form}>
                <Form.Item
                label="Icono"
                name="icon"
                rules={[
                    {
                    required: true, message: 'El campo es obligatorio'
                    }
                ]}
                isListField
                >
                    <Upload
                    type="select"
                    name="icon"
                    onChange={({ fileList }) => setSelectedIcon(fileList[0])}
                    onRemove={() => setSelectedIcon(null)}
                    accept="image/png, image/jpg, image/jpeg, image/bmp"
                    multiple={false}
                    beforeUpload={() => false}
                    // fileList={selectedIcon ? [selectedIcon] : undefined}
                    listType="picture"
                    >
                        <Button icon={<UploadOutlined />}>Subir Icono</Button>
                        <Divider type="vertical" />
                        {preSelectedIcon && (
                            <Avatar
                            src={preSelectedIcon}
                            alt="challenge image"
                            shape="square"
                            size="large"
                            />
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Imagen del plan"
                    name="imagePlan"
                    rules={[
                        {
                        required: true, message: 'El campo es obligatorio'
                        }
                    ]}
                    isListField>
                    <Upload
                        type="select"
                        name="imagePlan"
                        onChange={({ fileList }) => setSelectedImagePlan(fileList[0])}
                        onRemove={() => setSelectedImagePlan(null)}
                        accept="image/png, image/jpg, image/jpeg, image/bmp"
                        multiple={false}
                        beforeUpload={() => false}
                        // fileList={selectedImagePlan ? [selectedImagePlan] : undefined}
                        listType="picture"
                        >
                        <Button icon={<UploadOutlined />}>Subir imagen del Plan</Button>
                        <Divider type="vertical" />
                        {preSelectedImagePlan && (
                            <Avatar
                            src={preSelectedImagePlan}
                            alt="challenge image"
                            shape="square"
                            size="large"
                            />
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Titulo"
                    name="title"
                    rules={[
                    {
                        required: true, message: 'El campo es obligatorio'
                    }
                    ]}>
                        <Input />
                </Form.Item>

                <Form.Item
                    label="Tipo"
                    name="type"
                    rules={[
                    {
                        required: true, message: 'El campo es obligatorio'
                    }
                    ]}>
                        <Select onChange={handleSelect}>
                            <Option value='discount'>Dcto. Xx%</Option>
                            <Option value='seeds'>Semillas</Option>
                            <Option value='others'>Otros</Option>
                        </Select>
                </Form.Item>

                <Form.Item
                label="Infografia"
                name="infografia"
                rules={[
                    {
                    required: true, message: 'El campo es obligatorio'
                    }
                ]}
                isListField
                >
                    <Upload
                        type="select"
                        name="imagePlan"
                        onChange={({ fileList }) => setSelectedInfographics(fileList[0])}
                        onRemove={() => setSelectedInfographics(null)}
                        accept="image/png, image/jpg, image/jpeg, image/bmp"
                        multiple={false}
                        beforeUpload={() => false}
                        // fileList={selectedInfographics ? [selectedInfographics] : undefined}
                        listType="picture"
                        >
                        <Button icon={<UploadOutlined />}>Subir infografia</Button>
                        <Divider type="vertical" />
                        {preSelectedInfographics && (
                           <Avatar
                            src={preSelectedInfographics}
                            alt="challenge image"
                            shape="square"
                            size="large"
                            />
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item 
                    name="text" 
                    label="Descripcion" 
                    rules={[
                            { required: true, message: 'El campo es obligatorio'},
                            { max: 255, message: 'El texto no debe tener mas de 255 caracteres.' },
                        ]}
                    style={{marginBottom: 0}}>
                    <TextArea rows={4} value={text} onChange={handleTextChange} />
                </Form.Item>
                    {text.length <= 130 ? (
                    `${text.length} / 130`
                    ) : (
                    <>
                        <Typography.Text type="warning">
                        En la primera pantalla del reto se mostrarán sólo 127
                        caracteres.{" "}
                        </Typography.Text>
                        <Button onClick={togglePreview} type="link">
                        {textPreview
                            ? "Ocultar vista previa"
                            : "Mostrar vista previa"}
                        </Button>
                    </>
                    )}
                    {textPreview && (
                    <Typography.Paragraph>
                        {text.substring(0, 127).trim().concat("...")}
                    </Typography.Paragraph>
                    )}

                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item
                                label="Semillas"
                                name="seeds"
                                rules={[
                                { required: true, message: 'El campo es obligatorio'},
                                { type: 'number', message: 'Solo se pueden introducir numeros' },
                                ]}
                            >
                                <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        
                        {
                            (type === 'discount' || type === 'others') &&
                                <Col span={12}>
                                    <Form.Item
                                        label="Valido hasta"
                                        name="valid_until"
                                        rules={[
                                        { required: true, message: 'El campo es obligatorio'},
                                        ]}
                                    >
                                        <DatePicker style={{width: '100%'}}
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                        }
                    </Row>

                        {
                            type === 'discount' &&
                                <Form.Item
                                    label="Discount"
                                    name="discount"
                                    rules={[
                                        { required: true, message: 'El campo es obligatorio'},
                                        { type: 'number', message: 'Solo se pueden introducir numeros' },
                                    ]}
                                    >
                                    <InputNumber max={100} style={{width: '100%'}}/>
                                </Form.Item>
                        }

                    <Form.Item
                        label="Empresa"
                        name="company"
                        rules={[
                            {
                                required: true, message: 'El campo es obligatorio'
                            }
                        ]}>
                            <Select>
                                <Option key='0' value="undefined">Todas</Option>
                            {dataAllClients.map((company_map: any) => (
                                <Option key={company_map.id} value={company_map.id}>
                                {company_map.company_name}
                                </Option>
                            ))}
                            </Select>
                    </Form.Item>

                    {/* <Row style={{marginBottom: 10}}>
                        <Col span={2}>
                            <Text>Link</Text>
                        </Col>
                        <Switch checked={hasLink} onChange={handleSwitchChange}/>
                    </Row> */}
                    
                    <Divider type='horizontal'/>
                        <h3>Datos del canje</h3>
                    <Divider type='horizontal'/>

                    {/* {
                        hasLink && */}
                            <>
                                <Form.Item 
                                    name="second_text" 
                                    label="Segundo texto"
                                    rules={[
                                        {
                                            required: true, message: 'El campo es obligatorio',
                                        },
                                        { max: 255, message: 'El texto no debe tener mas de 255 caracteres.' },
                                    ]}
                                    >
                                    <TextArea rows={4} value={text} onChange={handleTextChange} />
                                </Form.Item>

                                <Form.Item
                                    label="Imagen"
                                    name="secondImage"
                                    rules={[
                                        {
                                            required: true, message: 'El campo es obligatorio'
                                        }
                                    ]}
                                    isListField
                                >
                                    <Upload
                                        type="select"
                                        name="secondImage"
                                        onChange={({ fileList }) => setSelectedSecondImage(fileList[0])}
                                        onRemove={() => setSelectedSecondImage(null)}
                                        accept="image/png, image/jpg, image/jpeg, image/bmp"
                                        multiple={false}
                                        beforeUpload={() => false}
                                        // fileList={selectedSecondImage ? [selectedSecondImage] : undefined}
                                        listType="picture"
                                        >
                                        <Button icon={<UploadOutlined />}>Subir imagen</Button>
                                        <Divider type="vertical" />
                                        {preSelectedSecondImage && (
                                            <Avatar
                                            src={preSelectedSecondImage}
                                            alt="challenge image"
                                            shape="square"
                                            size="large"
                                            />
                                        )}
                                    </Upload>
                                </Form.Item>
                                
                                <Form.Item
                                    label='Link'
                                    name='link'
                                    rules={[
                                        {
                                            required: true, message: 'El campo es obligatorio'
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </>
                    {/* } */}
            </Form>
        </Modal>
    </>
    )
}
