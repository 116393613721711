import { combineReducers } from "redux";

import auth from "./auth/reducer";
import admins from "./admins/reducer";
import users from "./users/reducer";
import seeds from './seeds/reducer'
import publications from "./publications/reducer";
import challenges from "./challenges/reducer";
import purchases from "./purchases/reducer";
import optimization from "./optimizacion/reducer";
import subscriptions from "./subscriptions/reducer";
import clients from "./clients";
import languages from "./languages";

// Use ES6 object literal shorthand syntax to define the object shape
const rootReducer = combineReducers({
  auth,
  admins,
  users,
  seeds,
  publications,
  purchases,
  challenges,
  optimization,
  subscriptions,
  clients: clients.reducer,
  languages: languages.reducer,
});

export default rootReducer;
